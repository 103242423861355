import { Button } from "src/components/atoms";
import bannerImageLarge from "src/assets/images/bonus/banner_desktop_welcomeBonus.png";
//import bannerImageMedium from "src/assets/images/bonus/banner_desktop_welcomeBonus_MEDIUM.png";
import bannerImageMobile from "src/assets/images/bonus/banner_desktop_welcomeBonusMOBILE.png";
import { ReactComponent as AsteriskIcon } from "src/assets/icons/shapes/Asterisk.svg";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { useNavigate } from "react-router-dom";

interface WelcomeBonusBannerProps {
  isButtonVisible?: boolean;
}

const WelcomeBonusBanner: React.FC<WelcomeBonusBannerProps> = ({
  isButtonVisible = false,
}) => {
  const navigate = useNavigate();
  return (
    <div className="relative w-full h-[430px] flex items-center justify-end">
      {/* Background Image */}
      <img
        src={bannerImageLarge}
        alt="Welcome Bonus Banner"
        className="absolute inset-0 w-full h-full object-left-top object-cover hidden xl:block border border-transparent rounded-3xl"
      />
      {/* <img
        src={bannerImageMedium}
        alt="Welcome Bonus Banner"
        className="absolute inset-0 w-full h-full object-left-top object-cover hidden md:block xl:hidden border border-transparent rounded-3xl"
      /> */}
      <img
        src={bannerImageMobile}
        alt="Welcome Bonus Banner"
        className="absolute inset-0 w-full h-full object-left-top object-cover xl:hidden border border-transparent rounded-3xl"
      />
      {/* Right Half Content */}
      <div className="relative z-10 w-4/6 md:w-1/2 text-left">
        <h1 className="md:max-w-96 md:text-[70px] text-5xl font-black text-secondary-200 mb-2.5 whitespace-normal leading-[68px]">
          100% DEPOSIT BONUS
        </h1>
        <h2 className="mb-6 md:text-3xl text-[28px] font-extrabold leading-[30px] text-greyscale-100">
          UP TO €500
        </h2>
        <div className="md:hidden flex mb-6 gap-1.5">
          <AsteriskIcon />
          <p className="text-greyscale-100 text-xs leading-[14px] break-words">
            T&C’s and wagering requirements apply
          </p>
        </div>
        <div className="flex gap-6 items-center">
          {isButtonVisible && (
            <Button
              className="flex justify-center items-center px-5 py-3.5 gap-4 bg-secondary-200 text-sm leading-6 text-greyscale-300 border border-secondary-200 rounded hover:bg-secondary-300 hover:border-secondary-300 hover:text-greyscale-100 group transition duration-300"
              key={"sign-up-button"}
              onClick={() => navigate("/bonus/welcome-bonus")}
              type="button"
            >
              <span className="whitespace-nowrap">Sign up now</span>
              <ArrowRightIcon className="text-greyscale-300 group-hover:text-greyscale-100 transition duration-300" />
            </Button>
          )}
          <div className="hidden md:flex gap-1.5">
            <AsteriskIcon />
            <p className="text-greyscale-100 text-xs leading-[14px] break-words">
              T&C’s and wagering requirements apply
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBonusBanner;
