import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { Button } from "src/components/atoms";
import {
  InputDate,
  InputText,
  LoginInstead,
  InputSelect,
} from "src/components/molecules";
export interface PersonalDetailsFormProps {
  values: FormikProps<any>["values"];
  errors: FormikProps<any>["errors"];
  handleBlur: FormikProps<any>["handleBlur"];
  handleChange: FormikProps<any>["handleChange"];
  validateField: FormikProps<any>["validateField"];
  onClickNext: () => void;
  setDate: (date: string) => void;
}

export const PersonalDetailsForm = ({
  values,
  errors,
  handleBlur,
  handleChange,
  validateField,
  onClickNext,
  setDate,
}: PersonalDetailsFormProps) => {
  const { t } = useTranslation();
  const handleOnBlur = (e: React.FocusEvent<any>) => {
    handleBlur(e);
    validateField(e.target.name);
  };
  const handleOnChange = (e: React.ChangeEvent<any>) => {
    handleChange(e);
    validateField(e.target.name);
  };

  return (
    <div>
      <div className="flex flex-col gap-[34px] mb-[54px]">
        <div className="flex flex-col gap-6">
          <span className="text-lg font-semibold text-primary-400">
            {t("text_personal-details")}
          </span>
          <InputText
            name="firstName"
            label={t("text_first-name")}
            type="text"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
          <InputText
            name="lastName"
            label={t("text_last-name")}
            type="text"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
          <InputSelect
            name="gender"
            label={t("text_gender")}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          >
            <option value="male">{t("option_male")}</option>
            <option value="female">{t("option_female")}</option>
            <option value="other">{t("option_other")}</option>
          </InputSelect>
        </div>
        <div className="flex flex-col gap-6">
          <span className="text-lg font-semibold text-primary-400">
            {t("text_date-of-birth")}
          </span>
          <InputDate setValue={setDate} />
        </div>
      </div>
      <Button
        isDisabled={
          !values?.firstName ||
          !values?.lastName ||
          !values?.gender ||
          !values?.birthDate ||
          errors?.firstName !== undefined ||
          errors?.lastName !== undefined ||
          errors?.gender !== undefined ||
          errors?.birthDate !== undefined
        }
        type="button"
        className="flex rounded justify-between w-full py-3 px-6 bg-primary-200 disabled:bg-primary-100 text-greyscale-100 hover:text-greyscale-300 hover:bg-tertiary-100 transition duration-300 mb-6"
        onClick={() => onClickNext()}
      >
        <div className="w-6" />
        {t("button_next")}
        <ArrowRightIcon />
      </Button>
      <LoginInstead />
    </div>
  );
};

export default PersonalDetailsForm;
