import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { ReactComponent as ErrorIcon } from "src/assets/icons/validation/Clear Circle.svg";
import { ReactComponent as SuccessIcon } from "src/assets/icons/validation/Check Circle.svg";
import { ReactComponent as InfoIcon } from "src/assets/icons/help&support/Info.svg";
import { useTranslation } from "react-i18next";

interface Error {
  description: string;
}

const useNotificationsService = () => {
  const { t } = useTranslation();

  // Add an error notification to the notification center
  const addErrorNotification = (error: Error | string) => {
    Store.addNotification({
      message: (
        <div className="flex items-center gap-4 text-greyscale-100 w-full">
          <div>
            <ErrorIcon className="w-8 h-8" />
          </div>
          <div className="flex flex-col w-full">
            <span className="rnc__notification-title">{t("text_error")}</span>
            <span className="rnc__notification-message">
              {typeof error === "string" ? error : error.description}
            </span>
          </div>
        </div>
      ),
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: [
        "transition-opacity duration-1000 ease-in-out opacity-100 rnc__notification-item--danger",
      ],
      animationOut: [
        "transition-opacity duration-1000 ease-in-out opacity-0 rnc__notification-item--danger",
      ],
      dismiss: {
        duration: 3000,
      },
    });
  };

  //Add a success notification to the notification center
  const addSuccessNotification = (message: string) => {
    Store.addNotification({
      message: (
        <div className="flex items-center gap-4 text-greyscale-100">
          <div>
            <SuccessIcon className="w-8 h-8" />
          </div>
          <div className="flex flex-col w-full">
            <span className="rnc__notification-title">{t("text_success")}</span>
            <span className="rnc__notification-message">{message}</span>
          </div>
        </div>
      ),
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: [
        "transition-opacity duration-1000 ease-in-out opacity-100 rnc__notification-item--success",
      ],
      animationOut: [
        "transition-opacity duration-1000 ease-in-out opacity-0 rnc__notification-item--success",
      ],
      dismiss: {
        duration: 3000,
      },
    });
  };

  //Add an info notification to the notification center
  const addInfoNotification = (message: string) => {
    Store.addNotification({
      message: (
        <div className="flex items-center gap-4 text-greyscale-100">
          <div>
            <InfoIcon className="w-8 h-8" />
          </div>
          <div className="flex flex-col w-full">
            <span className="rnc__notification-title">{t("text_info")}</span>
            <span className="rnc__notification-message">{message}</span>
          </div>
        </div>
      ),
      type: "info",
      insert: "top",
      container: "top-center",
      animationIn: [
        "transition-opacity duration-1000 ease-in-out opacity-100 rnc__notification-item--success",
      ],
      animationOut: [
        "transition-opacity duration-1000 ease-in-out opacity-0 rnc__notification-item--success",
      ],
      dismiss: {
        duration: 3000,
      },
    });
  };

  //Add a continuous info notification to the notification center
  const addContinuousInfoNotification = (id: string, message: string) => {
    Store.addNotification({
      id: id,
      message: (
        <div className="flex items-center gap-4 text-greyscale-100">
          <div>
            <InfoIcon className="w-8 h-8" />
          </div>
          <div className="flex flex-col w-full">
            <span className="rnc__notification-title">{t("text_info")}</span>
            <span className="rnc__notification-message">{message}</span>
          </div>
        </div>
      ),
      type: "info",
      insert: "top",
      container: "top-right",
      animationIn: [
        "transition-opacity duration-1000 ease-in-out opacity-100 rnc__notification-item--success",
      ],
      animationOut: [
        "transition-opacity duration-1000 ease-in-out opacity-0 rnc__notification-item--success",
      ],
    });
  };

  return {
    addErrorNotification,
    addSuccessNotification,
    addInfoNotification,
    addContinuousInfoNotification,
  };
};

export default useNotificationsService;
