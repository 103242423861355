import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import { useEffect, useState } from "react";

// Custom hook to get screen width
const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenSize;
};

interface PrivateRouteProps {
  minWidth?: number;
  maxWidth?: number;
  fallbackPath?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  minWidth = 0,
  maxWidth = Infinity,
  fallbackPath = "/login",
}) => {
  const { token } = useAuth();
  const screenSize = useScreenSize();

  const isAuthenticated = !!token;
  const isWithinSize = screenSize >= minWidth && screenSize <= maxWidth;

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!isWithinSize) {
    return <Navigate to={fallbackPath} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
