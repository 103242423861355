import React from "react";
import { InputPassword } from "src/components/molecules";
import useNotificationsService from "src/services/notificationsService";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import usePlayerService from "src/services/playerService";
import { useAuth } from "src/context/AuthContext";

// base form for changing or resetting a password
const AlterPasswordForm = ({
  doAlter,
  afterAlter,
}: {
  doAlter: (currentPassword: string, newPassword: string) => Promise<void>;
  afterAlter: () => void;
}) => {
  const { t } = useTranslation();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationsService();
  const { login } = usePlayerService();
  const { playerData } = useAuth();

  const { resetToken } = useParams<{ resetToken: string }>();

  let validationSchema = Yup.object({
    currentPassword: resetToken
      ? Yup.mixed()
      : Yup.string().required(t("error_cur-pwd-required")),
    newPassword: Yup.string()
      .required(t("error_new-pwd-required"))
      .min(6, t("error_min_6-chars")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], t("error_pwd-match"))
      .required(t("error_confirm-pwd-required")),
  });

  const onSubmit = async (
    values: { currentPassword: string; newPassword: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      /**
       * NOTE: This is a workaround to avoid the error regarding the current password
       * being incorrect when the user is resetting the password.
       * This is not the best solution, but it works for now.
       * A better solution will be dealt on the backend in the future.
       */
      try {
        if (!playerData) {
          throw new Error(t("error_player-not-exists"));
        }
        await login(playerData.email, values.currentPassword);
      } catch (error: any) {
        console.error(error);
        addErrorNotification(t("error_invalid-pwd"));
        return;
      }
      /* ------------------------------------------------------------------------ */
      await doAlter(values.currentPassword, values.newPassword);
      addSuccessNotification(t("success_pwd-changed"));
      afterAlter();
    } catch (error: any) {
      addErrorNotification(t("error_pwd-change"));
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col gap-5">
            {resetToken ? (
              <></>
            ) : (
              <InputPassword
                name="currentPassword"
                label={t("text_current-pwd")}
              />
            )}
            <InputPassword name="newPassword" label={t("text_new-pwd")} />
            <InputPassword
              name="confirmPassword"
              label={t("text_confirm-pwd")}
            />
            <button
              type="submit"
              disabled={isSubmitting}
              className="flex rounded justify-center w-full py-3 px-6 mb-6 bg-primary-200 disabled:bg-primary-100 text-greyscale-100 hover:text-greyscale-300 hover:bg-tertiary-100 mt-2 transition duration-300"
            >
              {isSubmitting ? t("button_submitting") : t("text_change-pwd")}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AlterPasswordForm;
