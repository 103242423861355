import { useTranslation } from "react-i18next";
import { MoonLoader } from "react-spinners";
import { currencies } from "src/utils/currencyFunctions";
import { ReactComponent as WalletIcon } from "src/assets/icons/account/wallet.svg";
import { ReactComponent as CheckIcon } from "src/assets/icons/validation/Check Box.svg";
import { useEffect, useRef, useState } from "react";
import { Currency } from "src/types";

interface BalanceProps {
  balance?: string | number;
  selectedCurrency?: string;
  isLoading?: boolean;
  onSelectCurrency?: (currency: string) => void;
  isHeader?: boolean;
}

const Balance = ({
  balance = 0,
  selectedCurrency = "EUR",
  isLoading = false,
  onSelectCurrency,
  isHeader = false,
}: BalanceProps) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [currencyInfo, setCurrencyInfo] = useState<Currency>(
    currencies.find((currency) => currency.shortName === selectedCurrency) ||
      currencies[2]
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const newCurrencyInfo =
      currencies.find((currency) => currency.shortName === selectedCurrency) ||
      currencies[2];
    setCurrencyInfo(newCurrencyInfo);
  }, [selectedCurrency]);

  return (
    <div
      className={`relative pl-3.5 flex lg:justify-normal justify-between items-center lg:gap-6 gap-0 border rounded ${
        isHeader ? "border-none" : "border-primary-400"
      }`}
      ref={dropdownRef}
    >
      {!isHeader && (
        <div className="py-2.5 ">
          <p className="text-primary-200 leading-[22px] pl-1 pr-6 border-r border-r-primary-400">
            {t("text_balance")}
          </p>
        </div>
      )}
      {isLoading ? (
        <MoonLoader
          size={16}
          color={isHeader ? "white" : "#65236c"}
          loading={isLoading}
          speedMultiplier={0.7}
        />
      ) : (
        <p
          className={`flex leading-[22px] font-bold py-2.5 ${
            isHeader ? "text-greyscale-100" : "text-primary-200"
          }`}
        >
          {currencyInfo.symbol}
          {typeof balance === "number"
            ? balance.toFixed(currencyInfo.decimalPlaces)
            : parseFloat(balance).toFixed(currencyInfo.decimalPlaces)}
        </p>
      )}
      {!isHeader && (
        <button
          className="bg-primary-200 text-primary-100 py-2.5 px-3.5 hover:text-greyscale-100 disabled:text-primary-100 transition duration-300"
          onClick={() => toggleDropdown()}
          disabled={true}
        >
          <WalletIcon />
        </button>
      )}
      {isDropdownOpen && (
        <ul className="w-full bg-primary-400 absolute top-12 left-0 px-6 py-6 rounded text-greyscale-200 leading-[22px] z-20">
          {currencies.map((currency) => (
            <li
              key={currency.shortName}
              onClick={() =>
                onSelectCurrency && onSelectCurrency(currency.shortName)
              }
              className="flex justify-between gap-6 pb-6 last:pb-0 cursor-pointer hover:text-greyscale-100 transition duration-300"
            >
              <div
                className={`flex gap-6 ${
                  selectedCurrency === currency.shortName &&
                  "text-greyscale-100"
                }`}
              >
                {currency.svgSymbol}
                {currency.name}
              </div>
              {selectedCurrency === currency.shortName && (
                <CheckIcon className="text-greyscale-100" />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Balance;
