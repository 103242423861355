export const BrotherBets3Logo = () => {
  return (
    <>
      <svg
        width="53"
        height="28"
        viewBox="0 0 53 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_860_3246)">
          <path
            d="M34.3236 27.9932C30.3426 27.9932 27.3385 26.2333 26.048 23.2754C24.7575 26.2333 21.7533 27.9932 17.7723 27.9932H0.595779C0.265259 27.9932 0 27.7259 0 27.3975V0.602127C0 0.273711 0.267364 0.00634766 0.595779 0.00634766H17.7723C21.7533 0.00634766 24.7575 1.76632 26.048 4.72416C27.3385 1.76842 30.3426 0.00634766 34.3236 0.00634766H51.5002C51.8307 0.00634766 52.0959 0.273711 52.0959 0.602127V27.3954C52.0959 27.7259 51.8286 27.9911 51.5002 27.9911H34.3236V27.9932ZM35.8962 16.5387C34.1973 16.5387 33.1426 17.4987 33.1426 19.0439C33.1426 20.3723 34.172 21.3007 35.6478 21.3007H44.0834V16.5387H35.8983H35.8962ZM16.4502 21.3007C17.9239 21.3007 18.9555 20.3723 18.9555 19.0439C18.9555 17.4987 17.9007 16.5387 16.2018 16.5387H8.0167V21.3007H16.4523H16.4502ZM23.5322 13.9998C24.5175 14.5303 25.4438 15.4019 26.0501 16.6187C26.6564 15.4019 27.5806 14.5303 28.5658 13.9998C27.5806 13.4693 26.6543 12.5956 26.0501 11.3788C25.4438 12.5956 24.5196 13.4693 23.5322 13.9998ZM35.6478 6.69886C34.1741 6.69886 33.1426 7.62727 33.1426 8.95567C33.1426 10.5009 34.1973 11.4609 35.8962 11.4609H44.0813V6.69886H35.6457H35.6478ZM16.2018 11.4609C17.9007 11.4609 18.9555 10.5009 18.9555 8.95567C18.9555 7.62727 17.926 6.69886 16.4502 6.69886H8.0146V11.4609H16.1997H16.2018Z"
            fill="url(#paint0_linear_860_3246)"
          />
          <path
            d="M34.3237 0.602051C29.7343 0.602051 26.6185 3.07359 26.048 6.89457C25.4775 3.07359 22.3618 0.602051 17.7724 0.602051H0.595825V13.9976V27.3953H17.7724C22.3618 27.3953 25.4775 24.9237 26.048 21.1027C26.6185 24.9237 29.7343 27.3953 34.3237 27.3953H51.5002V13.9997V0.602051H34.3237ZM16.4503 21.8964H7.41887V15.9407H16.2019C18.2292 15.9407 19.5513 17.1807 19.5513 19.0417C19.5513 20.7785 18.185 21.8943 16.4503 21.8943V21.8964ZM16.1998 12.0587H7.41676V6.10301H16.4482C18.1829 6.10301 19.5492 7.21878 19.5492 8.95559C19.5492 10.8166 18.225 12.0566 16.1998 12.0566V12.0587ZM26.0543 18.4733L26.0438 18.4838C25.5007 15.5955 23.2481 14.1808 21.6228 13.9997C23.2481 13.8187 25.4986 12.4061 26.0438 9.51979L26.0564 9.53242C26.6059 12.4082 28.8522 13.8166 30.4732 13.9976C28.8501 14.1787 26.6017 15.5892 26.0543 18.4712V18.4733ZM44.6793 21.8964H35.6479C33.9131 21.8964 32.5469 20.7806 32.5469 19.0438C32.5469 17.1828 33.871 15.9428 35.8963 15.9428H44.6793V21.8985V21.8964ZM44.6793 12.0587H35.8963C33.8689 12.0587 32.5469 10.8187 32.5469 8.9577C32.5469 7.22088 33.9131 6.10511 35.6479 6.10511H44.6793V12.0608V12.0587Z"
            fill="url(#paint1_linear_860_3246)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_860_3246"
            x1="26.0943"
            y1="29.0964"
            x2="26.0017"
            y2="-1.05258"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_860_3246"
            x1="26.048"
            y1="-1.22108"
            x2="26.048"
            y2="30.0647"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9C156" />
            <stop offset="0.18" stopColor="#FFFFAA" />
            <stop offset="0.65" stopColor="#E1AB39" />
            <stop offset="0.91" stopColor="#633110" />
            <stop offset="0.99" stopColor="#E9C156" />
          </linearGradient>
          <clipPath id="clip0_860_3246">
            <rect
              width="52.0959"
              height="27.9869"
              fill="white"
              transform="translate(0 0.00634766)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default BrotherBets3Logo;
