import { useEffect, useState } from "react";
import { useTranslation, getI18n } from "react-i18next";
import { FAQQuestion } from "src/components/molecules";
import { faq as faqEN, faqDE, faqES, faqNO } from "src/utils/faq";

export const FAQ = () => {
  const { t } = useTranslation();
  const [selectedTopic, setSelectedTopic] = useState<number>(0);
  const [faq, setFaq] = useState(faqEN);

  useEffect(() => {
    switch (getI18n().language) {
      case "de":
        setFaq(faqDE);
        break;
      case "es":
        setFaq(faqES);
        break;
      case "no":
        setFaq(faqNO);
        break;
      default:
        setFaq(faqEN);
    }
  }, [getI18n().language]);

  /*   const ContextMenu = () => <div></div>;

  const Content = () => <div></div>; */

  return (
    <div className="flex flex-col gap-[34px]">
      <span className="text-[44px] font-bold text-[#B9FFB7]">
        {t("faq_header")}
      </span>
      <div className="flex justify-between gap-32">
        <div className="md:flex flex-col gap-11 hidden">
          <span className="font-semibold text-white text-nowrap">
            {t("faq_subheader")}
          </span>
          <div className="flex flex-col gap-[14px] cursor-pointer select-none">
            {faq.map((item, index) => (
              <div
                key={index}
                className={`${
                  selectedTopic === item.id
                    ? "font-bold text-[#B9FFB7] underline"
                    : "text-white"
                }  text-nowrap`}
                onClick={() => setSelectedTopic(item.id)}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full gap-[14px]">
          {faq
            .find((item) => item.id === selectedTopic)
            ?.content.map((item, index) => (
              <FAQQuestion
                key={`${index}${selectedTopic}`}
                question={item.question}
                answer={item.answer}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
