import { MainContainer } from "src/components/organisms";
import { Button } from "src/components/atoms";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ReactComponent as AddIcon } from "src/assets/icons/validation/Add.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Remove.svg";
import {
  MarkdownContainer,
  WelcomeBonusBanner,
} from "src/components/molecules";
import { motion } from "framer-motion";
import { useAuth } from "src/context/AuthContext";
import { useNavigate } from "react-router-dom";
import useNotificationsService from "src/services/notificationsService";
import useCustomBonusService from "src/services/bonusService";

export default function WelcomeBonusPage() {
  const { t } = useTranslation();
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const toggleTerms = () => setIsTermsOpen(!isTermsOpen);
  const { token, playerData } = useAuth();
  const navigate = useNavigate();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationsService();
  const { check, claim } = useCustomBonusService();

  const claimBonus = async (bonusCode: string) => {
    try {
      const isClaimable = await check(playerData!.id, bonusCode, token!);
      if (isClaimable) {
        try {
          await claim(playerData!.id, bonusCode, token!);
          addSuccessNotification(t("text_bonus-claim-success"));
          navigate("/");
          return true;
        } catch (error: any) {
          addErrorNotification(t("error_bonus-claim"));
          console.log("Failed to load bonus info: ", error);
          return false;
        }
      } else {
        addErrorNotification(t("text_claim-not-allowed"));
        return false;
      }
    } catch (error: any) {
      addErrorNotification(t("error_bonus-check"));
      console.log("Failed to load bonus info: ", error);
      return false;
    }
  };

  return (
    <MainContainer>
      <div className="w-full h-full flex flex-col items-center bg-custom-gradient">
        {/* <WelcomeBonusBanner /> */}
        <div className="flex flex-col items-center text-center mx-6 md:mx-auto">
          <h1 className="mt-[40px] md:text-[64px] text-4xl font-bold leading-[70px] text-greyscale-100">
            {t("text_welcome")}
          </h1>
          <h2 className="md:w-[480px] mt-[30px] md:text-[28px] text-xl leading-9 text-greyscale-100">
            {t("text_welcome-bonus2")}
          </h2>
        </div>
        <div className="md:mt-[74px] mt-[50px] flex md:w-[489px] p-[34px] flex-col items-center text-center gap-[24px] rounded-[10px] border border-3 border-secondary-200 mx-6 md:mx-auto">
          <p className="text-[40px] leading-[48px] text-greyscale-100">
            {t("text_claim-click")}
          </p>
          <Button
            className="flex justify-center items-center px-5 py-3.5 gap-4 bg-secondary-200 text-sm leading-6 text-greyscale-300 border border-secondary-200 rounded hover:bg-secondary-300 hover:border-secondary-300 hover:text-greyscale-100 group transition duration-300"
            key={"sign-up-button"}
            onClick={() =>
              token ? claimBonus("WELCOME_BONUS_FTD") : navigate("/login")
            }
            type="button"
          >
            <span className="whitespace-nowrap">{t("text_welcome-bonus")}</span>
          </Button>
        </div>
        <div>
          <h3 className="md:w-[900px] mt-[34px] text-greyscale-100 leading-9 font-bold text-xl text-center mx-6">
            {t("text_important-bonus-note")}
          </h3>
        </div>
        <div className="w-full max-w-lg mx-6 md:mx-auto p-4 mt-[94px] mb-[130px]">
          <button
            onClick={toggleTerms}
            className="flex justify-between items-center w-full text-left"
          >
            <span className="text-greyscale-100 text-[28px] leading-9">
              {t("button_terms-conditions")}
            </span>
            <div className="border border-secondary-100 text-secondary-100 p-1 rounded-md">
              {isTermsOpen ? <CloseIcon /> : <AddIcon />}
            </div>
          </button>
          {isTermsOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-10 text-greyscale-100 text-sm"
            >
              <MarkdownContainer
                mdNamespace="bonus-terms"
                forceScroll={false}
              />
            </motion.div>
          )}
        </div>
      </div>
    </MainContainer>
  );
}
