import React from "react";
import { Footer, Header } from "src/components/organisms";
import { GameResponse } from "src/types";

export interface MainContainerProps {
  onSearchResultItemClick?: (game: GameResponse) => void;
  children?: React.ReactNode;
}

export const MainContainer = (props: MainContainerProps) => {
  return (
    <div className="font-main w-full relative">
      <Header onSearchResultItemClick={props.onSearchResultItemClick} />
      <>{props.children}</>
      <Footer />
    </div>
  );
};

export default MainContainer;
