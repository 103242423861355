import { ReactComponent as EuroIcon } from "src/assets/icons/currency/Euro.svg";
import { ReactComponent as DollarIcon } from "src/assets/icons/currency/USDollar.svg";
import { ReactComponent as PoundIcon } from "src/assets/icons/currency/Pound.svg";
import { ReactComponent as BitcoinIcon } from "src/assets/icons/currency/Bitcoin.svg";
import { Currency, EUNation } from "src/types";

const EU_NATIONS: EUNation[] = [
  "Austria",
  "Belgium",
  "Croatia",
  "Cyprus",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Portugal",
  "Slovakia",
  "Slovenia",
  "Spain",
];

/**
 * Checks if a country is in the EU
 * @param country - country to be checked
 * @returns boolean - true if country is in the EU, false otherwise
 */
export const isEuCountry = (country: string): boolean => {
  return EU_NATIONS.includes(country as EUNation);
};

export const currencies: Currency[] = [
  {
    name: "Pounds",
    shortName: "GBP",
    symbol: "£",
    svgSymbol: <PoundIcon />,
    decimalPlaces: 2,
  },
  {
    name: "Euros",
    shortName: "EUR",
    symbol: "€",
    svgSymbol: <EuroIcon />,
    decimalPlaces: 2,
  },
  {
    name: "US Dollars",
    shortName: "USD",
    symbol: "$",
    svgSymbol: <DollarIcon />,
    decimalPlaces: 2,
  },
  {
    name: "Bitcoin",
    shortName: "BTC",
    symbol: "₿",
    svgSymbol: <BitcoinIcon />,
    decimalPlaces: 8,
  },
];
