import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Clear.svg";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { ReactComponent as ArrowLeftIcon } from "src/assets/icons/arrows/Arrow Left.svg";
import { ReactComponent as UserIcon } from "src/assets/icons/user/roundal.svg";
import { ReactComponent as ControllerIcon } from "src/assets/icons/nav/Controller.svg";
//import { ReactComponent as LiveIcon } from "src/assets/icons/nav/Live.svg";
import { ReactComponent as DiceIcon } from "src/assets/icons/nav/Dice.svg";
import { ReactComponent as ChipIcon } from "src/assets/icons/nav/Chip.svg";
import { ReactComponent as SlotsIcon } from "src/assets/icons/nav/Slots.svg";
//import { ReactComponent as PromotionIcon } from "src/assets/icons/nav/Promos.svg";
import { ReactComponent as LogoutIcon } from "src/assets/icons/security/Logout.svg";
import { ReactComponent as SquareUserIcon } from "src/assets/icons/account/user.svg";
import { ReactComponent as WalletIcon } from "src/assets/icons/account/wallet.svg";
import { ReactComponent as AddPaymentCardIcon } from "src/assets/icons/money&payment/Add Payment Card.svg";
import { ReactComponent as GiftIcon } from "src/assets/icons/shopping/Gift.svg";
import { ReactComponent as MoneyDollarIcon } from "src/assets/icons/money&payment/Money Dollar.svg";
import { ReactComponent as DepositIcon } from "src/assets/icons/money&payment/Add Payment Card.svg";
import { ReactComponent as WithdrawIcon } from "src/assets/icons/money&payment/Favourite Payment Card.svg";
import { ReactComponent as HotGamesIcon } from "src/assets/icons/nav/hotGames.svg";
import { ReactComponent as NewGamesIcon } from "src/assets/icons/nav/newGames.svg";
import { ReactComponent as PokerGamesIcon } from "src/assets/icons/nav/pokerGames.svg";
import { ReactComponent as CasinoGamesIcon } from "src/assets/icons/nav/casinoGames.svg";
import { ReactComponent as SlotsColouredIcon } from "src/assets/icons/nav/slotsGames.svg";
//import { ReactComponent as LiveNowIcon } from "src/assets/icons/nav/liveNOW.svg";
import { ReactComponent as FavouritesIcon } from "src/assets/icons/nav/favourites.svg";
import { ReactComponent as AmountIcon } from "src/assets/icons/money&payment/Wallet.svg";
import { ReactComponent as BonusIcon } from "src/assets/icons/nav/Promos.svg";
import { useTranslation } from "react-i18next";
import { BrotherBets3Logo as BrotherBetsLogo3 } from "src/assets/svg";
import { Button, NavLink } from "src/components/atoms";
import {
  Balance,
  GameModal,
  NavBar,
  Select,
  WalletCard,
} from "src/components/molecules";
import {
  ChangePasswordModal,
  EditProfileForm,
  TransactionModal,
  WalletTables,
} from "src/components/organisms";
import { useAuth } from "src/context/AuthContext";
import usePlayerSessionService from "src/services/playerSessionService";
import { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { GameResponse } from "src/types";
import useNotificationsService from "src/services/notificationsService";
import useTransactionService from "src/services/transactionService";
import ChangePasswordButton from "src/components/atoms/button/ChangePasswordButton.component";
import ChangePasswordForm from "src/components/organisms/form/ChangePasswordForm.component";
import GamesSearchField from "src/components/molecules/search/GamesSearchField.component";

export interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

interface PreferredWallet {
  currency_code: string;
  amount: number;
  bonus: number;
}

type SubMenuState =
  | "main"
  | "browseGames"
  | "myAccount"
  | "customerProfile"
  | "customerProfile.changePwd"
  | "wallet"
  | "searchGames";

interface Transaction {
  dateTime: Date;
  method: string;
  value: number;
  status: string;
  currency: string;
}

export const MobileMenu = ({ isOpen, onClose }: MobileMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menuState, setMenuState] = useState<SubMenuState>("main");
  const [preferredWallet, setPreferredWallet] = useState<PreferredWallet>({
    currency_code: "",
    amount: 0,
    bonus: 0,
  });
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isTransactionModalOpen, setTransactionModalOpen] =
    useState<boolean>(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<
    "deposit" | "withdraw"
  >("deposit");
  const [selectedGame, setSelectedGame] = useState<GameResponse | null>(null);
  const [isWalletLoading, setWalletLoading] = useState<boolean>(true);
  const { token, playerData, logout } = useAuth();
  const { logout: serverLogout } = usePlayerSessionService();
  const { addErrorNotification } = useNotificationsService();
  const { getBalances, getTransactions } = useTransactionService();

  const handleTransactionModal = (type: "deposit" | "withdraw") => {
    setTransactionType(type);
    // Add begin checkout event
    ReactGA.event("begin_checkout", {
      currency: "",
      value: 0,
      items: [
        {
          item_id: type,
          item_name: playerData?.id || "",
        },
      ],
    });
    setTransactionModalOpen(true);
  };

  const switchToSubMenu = (option: SubMenuState) => {
    if (
      !Cookies.get("token") &&
      (option === "myAccount" ||
        option === "wallet" ||
        option.startsWith("customerProfile"))
    ) {
      setMenuState("main");
      onClose();
      navigate("/");
    } else {
      setMenuState(option);
    }
  };

  const switchToMainMenu = () => {
    setMenuState("main");
  };

  const handleLogout = async () => {
    try {
      // Call server to logout
      if (playerData?.id) {
        await serverLogout(playerData?.id);
      }
      logout();
      setMenuState("main");
      onClose();
      navigate("/");
    } catch (error) {
      addErrorNotification(t("error_logout"));
      console.error("Error logging out: ", error);
    }
  };

  useEffect(() => {
    const setWalletValues = async (): Promise<void> => {
      if (playerData?.id) {
        try {
          const wallets = await getBalances(playerData.id);
          const preferredWallet = wallets.find(
            (wallet) => wallet.currency_id === playerData.currency_id
          );
          if (!preferredWallet) {
            addErrorNotification(t("error_find-preferred-wallet"));
            return;
          }
          setPreferredWallet({
            currency_code: preferredWallet.alphabetic_code,
            amount: preferredWallet.amount,
            bonus: preferredWallet.bonus,
          });
        } catch (error) {
          addErrorNotification(t("error_get-wallet"));
          console.error("Error getting wallets: ", error);
        } finally {
          setWalletLoading(false);
        }
        return;
      }
    };
    if (menuState === "wallet" && !isTransactionModalOpen) {
      setWalletValues();
    }
  }, [menuState, isTransactionModalOpen]);

  useEffect(() => {
    const getUserTransactions = async () => {
      if (!playerData?.id) return;
      try {
        const result = await getTransactions(playerData.id);
        setTransactions(
          result.map((transaction) => ({
            dateTime: new Date(transaction.transaction_date),
            method: transaction.type,
            value: transaction.amount,
            status: transaction.status,
            currency: transaction.currency,
          }))
        );
      } catch (error) {
        addErrorNotification(t("error_get-transactions"));
        console.error("Error getting transactions: ", error);
      }
    };
    if (menuState === "wallet") {
      getUserTransactions();
    }
  }, [menuState]);

  // workaround to scroll change password field into view
  // before we can refactor this whole thing
  const pwdRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);
  useEffect(() => {
    if (menuState.startsWith("customerProfile.changePwd")) {
      pwdRef.current.scrollIntoView();
    }
  }, [menuState, pwdRef]);

  return (
    <div
      className={`fixed flex flex-col inset-0 bg-opacity-75 z-50 w-screen overflow-y-scroll ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <TransactionModal
        isOpen={isTransactionModalOpen}
        transactionType={transactionType}
        balance={preferredWallet.amount.toString()}
        onClose={() => setTransactionModalOpen(false)}
        currencyPrefix={preferredWallet.currency_code === "EUR" ? "€" : "$"}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setChangePasswordModalOpen(false)}
      />
      <GameModal
        selectedGame={selectedGame}
        onClose={() => setSelectedGame(null)}
      />
      {/** Menu header */}
      <section className="flex flex-col gap-6 relative bg-greyscale-400 py-4 px-6 md:px-60 lg:px-80 xl:px-96">
        <div className="flex">
          <Link
            to={"/"}
            className="mx-auto self-center"
            onClick={() => {
              onClose();
              setTimeout(switchToMainMenu, 300);
            }}
          >
            <BrotherBetsLogo3 />
          </Link>
          <Button
            onClick={() => {
              onClose();
              setTimeout(switchToMainMenu, 300);
            }}
            className="flex justify-center items-center p-2 gap-4 absolute right-1 top-1"
          >
            <CloseIcon className="text-greyscale-100 w-[34px] h-[34px]" />
          </Button>
        </div>
        {token ? (
          <div className="flex justify-between items-center">
            <div className="flex gap-3.5 items-center">
              <span className="rounded-full min-w-[50px] min-h-[50px] border-2 border-greyscale-100 bg-primary-400 text-lg flex justify-center items-center text-greyscale-100 font-semibold">
                {playerData?.first_name?.charAt(0).toUpperCase()}
                {playerData?.last_name?.charAt(0).toUpperCase()}
              </span>
              <div className="flex flex-col gap-2 text-greyscale-100">
                <span className="text-xl leading-6 font-semibold">
                  {playerData?.first_name} {playerData?.last_name}
                </span>
                {playerData?.nickname && (
                  <span className="text-sm leading-6">
                    {t("text_player-id")}:{" "}
                    <span className="font-semibold">
                      {playerData?.nickname}
                    </span>
                  </span>
                )}
              </div>
            </div>
            <Select className="text-sm justify-self-end" />
          </div>
        ) : (
          <div className="flex gap-6 justify-between items-center">
            <Select className="text-sm" />
            <div className="flex gap-4">
              <Button
                className="flex justify-center items-center px-6 py-2 bg-transparent text-sm text-white border border-white rounded hover:text-greyscale-400 hover:bg-greyscale-100 transition duration-300"
                key={"login-button"}
                onClick={() => navigate("/login")}
                type="button"
              >
                {t("button_header_login")}
              </Button>
              <Button
                className="flex justify-center items-center px-6 py-2 gap-4 bg-secondary-200 text-sm text-greyscale-300 border border-secondary-200 rounded hover:bg-secondary-300 hover:border-secondary-300 hover:text-greyscale-100 group transition duration-300"
                key={"sign-up-button"}
                onClick={() => navigate("/register")}
                type="button"
              >
                {t("button_header_signup")}
                <ArrowRightIcon className="text-greyscale-300 group-hover:text-greyscale-100 transition duration-300" />
              </Button>
            </div>
          </div>
        )}
        <div className="flex">
          <GamesSearchField
            onSearchResultItemClick={setSelectedGame}
            isMobile
          />
        </div>
        {menuState === "browseGames" && (
          <Button
            type="button"
            onClick={() => setMenuState("main")}
            className="flex items-center justify-between w-full"
          >
            <ArrowLeftIcon className="text-greyscale-100" />
            <span className="text-greyscale-100 text-xl leading-6 font-semibold flex-grow text-center">
              {t("button_browse-games")}
            </span>
          </Button>
        )}
        {menuState === "myAccount" && (
          <Button
            type="button"
            onClick={() => setMenuState("main")}
            className="flex items-center justify-between w-full"
          >
            <ArrowLeftIcon className="text-greyscale-100" />
            <span className="text-greyscale-100 text-xl leading-6 font-semibold flex-grow text-center">
              {t("link_header_my-account")}
            </span>
          </Button>
        )}
        {menuState.startsWith("customerProfile") && (
          <Button
            type="button"
            onClick={() => setMenuState("myAccount")}
            className="flex items-center justify-between w-full"
          >
            <ArrowLeftIcon className="text-greyscale-100" />
            <span className="text-greyscale-100 text-xl leading-6 font-semibold flex-grow text-center">
              {t("button_customer-profile")}
            </span>
          </Button>
        )}
        {menuState === "wallet" && (
          <Button
            type="button"
            onClick={() => setMenuState("myAccount")}
            className="flex items-center justify-between w-full"
          >
            <ArrowLeftIcon className="text-greyscale-100" />
            <span className="text-greyscale-100 text-xl leading-6 font-semibold flex-grow text-center">
              {t("button_wallet")}
            </span>
          </Button>
        )}
      </section>
      {/** Menu content */}
      <section
        className={`flex flex-col justify-between bg-greyscale-100 pt-3.5 pb-[34px] items-center ${
          menuState.startsWith("customerProfile") || menuState === "wallet"
            ? "px-3.5 h-fit"
            : "px-10 h-full"
        }`}
      >
        <div className="relative w-full max-w-2xl lg:max-w-fit flex flex-col gap-5 h-full justify-between">
          <NavBar
            className={`${
              menuState === "main"
                ? "animate-slide-in-left"
                : "animate-slide-in-right"
            } transition-transform duration-300 ease-in-out ${
              menuState === "main" ? "translate-x-0" : "-translate-x-full"
            }`}
            orientation="vertical"
          >
            {menuState === "main" && (
              <>
                {token ? (
                  <Button
                    className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5"
                    type="button"
                    onClick={() => switchToSubMenu("myAccount")}
                  >
                    <div className="flex gap-2">
                      <UserIcon className="text-primary-400" />
                      {t("link_header_my-account")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </Button>
                ) : (
                  <Button
                    className="flex justify-between items-center gap-2 group text-primary-400/40 px-2.5 py-3.5"
                    type="button"
                    onClick={() => switchToSubMenu("myAccount")}
                    isDisabled={true}
                  >
                    <div className="flex gap-2">
                      <UserIcon className="text-primary-400/40" />
                      {t("link_header_my-account")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-400/30" />
                  </Button>
                )}
                <Button
                  className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5"
                  type="button"
                  onClick={() => switchToSubMenu("browseGames")}
                >
                  <div className="flex gap-2">
                    <ControllerIcon className="text-primary-400" />
                    {t("button_browse-games")}
                  </div>
                  <ArrowRightIcon className="text-greyscale-300" />
                </Button>
                {/* <Link
                  to={"/games/live"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2">
                      <LiveIcon className="text-primary-400" />
                      {t("link_live-now")}!
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link> */}
                <Link
                  to={"/games/casino"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2">
                      <DiceIcon className="text-primary-400" />
                      {t("link_casino-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/poker"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2">
                      <ChipIcon className="text-primary-400" />
                      {t("link_poker-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/slots"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2">
                      <SlotsIcon className="text-primary-400" />
                      {t("button_header_slots")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
              </>
            )}
            {menuState === "browseGames" && (
              <>
                <Link
                  to={"/games/hot"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <HotGamesIcon />
                      {t("link_hot-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/new"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <NewGamesIcon />
                      {t("link_new-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/poker"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <PokerGamesIcon />
                      {t("link_poker-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/casino"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <CasinoGamesIcon />
                      {t("link_casino-games")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                <Link
                  to={"/games/slots"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <SlotsColouredIcon />
                      {t("button_header_slots")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
                {/* <Link
                  to={"/games/live"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <LiveNowIcon />
                      {t("link_live-now")}!
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link> */}
                <Link
                  to={playerData && token ? "/games/favourites" : "/login"}
                  onClick={() => {
                    onClose();
                    setTimeout(switchToMainMenu, 300);
                  }}
                >
                  <NavLink className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5">
                    <div className="flex gap-2 items-center">
                      <FavouritesIcon />
                      {t("link_favourites")}
                    </div>
                    <ArrowRightIcon className="text-greyscale-300" />
                  </NavLink>
                </Link>
              </>
            )}
            {menuState === "myAccount" && (
              <>
                <Button
                  className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5"
                  type="button"
                  onClick={() => switchToSubMenu("customerProfile")}
                >
                  <div className="flex gap-2">
                    <SquareUserIcon className="text-primary-400" />
                    {t("button_customer-profile")}
                  </div>
                  <ArrowRightIcon className="text-greyscale-300" />
                </Button>
                <Button
                  className="flex justify-between items-center gap-2 group text-primary-400 px-2.5 py-3.5"
                  type="button"
                  onClick={() => switchToSubMenu("wallet")}
                >
                  <div className="flex gap-2">
                    <WalletIcon className="text-primary-400" />
                    {t("button_wallet")}
                  </div>
                  <ArrowRightIcon className="text-greyscale-300" />
                </Button>
              </>
            )}
            {menuState.startsWith("customerProfile") && (
              <>
                {/* TODO fix routes and get this screen into its own page */}
                <EditProfileForm />
                <div ref={pwdRef}></div>
                <div className="py-6 flex flex-col items-center">
                  {menuState === "customerProfile" && (
                    <ChangePasswordButton
                      onClick={() => setMenuState("customerProfile.changePwd")}
                    />
                  )}
                  {menuState.startsWith("customerProfile.changePwd") && (
                    <ChangePasswordForm
                      afterChange={() => setMenuState("customerProfile")}
                    />
                  )}
                </div>
                {}
              </>
            )}
            {menuState === "wallet" && (
              <>
                <div className="w-full flex flex-col gap-10 items-center">
                  <div className="w-full flex flex-col items-center gap-6">
                    <div className="w-full flex flex-col gap-3.5">
                      <div className="flex flex-col gap-3.5 pt-4">
                        <Balance
                          selectedCurrency={preferredWallet.currency_code}
                          balance={
                            preferredWallet.amount + preferredWallet.bonus
                          }
                          isLoading={isWalletLoading}
                        />
                        <div className="flex w-full gap-4">
                          <Button
                            className="w-full flex justify-center flex-grow items-center py-2.5 xl:pl-5 pr-2.5 pl-2.5 gap-4 rounded text-primary-400 bg-secondary-200 hover:text-greyscale-100 hover:bg-secondary-300 transition duration-300 text-sm"
                            onClick={() => handleTransactionModal("withdraw")}
                          >
                            <span>{t("text_withdraw")}</span>
                            <WithdrawIcon />
                          </Button>
                          <Button
                            className="w-full flex justify-center  flex-grow items-center py-2.5 xl:pl-5 pr-2.5 pl-2.5 gap-4 rounded text-greyscale-100 bg-primary-400 hover:text-primary-400 hover:bg-primary-100 transition duration-300 text-sm"
                            onClick={() => handleTransactionModal("deposit")}
                          >
                            <span>{t("text_deposit")}</span>
                            <DepositIcon />
                          </Button>
                        </div>
                      </div>
                      <h2 className="text-lg leading-6 font-bold text-primary-400 self-center pt-4">
                        {t("text_wallet-overview")}
                      </h2>
                      <div className="flex gap-3.5 justify-center">
                        <WalletCard
                          icon={<AmountIcon className="w-11 h-11" />}
                          selectedCurrency={preferredWallet.currency_code}
                          balance={preferredWallet.amount}
                          title={t("text_amount")}
                          isLoading={isWalletLoading}
                        />
                        <WalletCard
                          icon={<BonusIcon className="w-11 h-11" />}
                          selectedCurrency={preferredWallet.currency_code}
                          balance={preferredWallet.bonus}
                          title={t("text_bonus")}
                          isLoading={isWalletLoading}
                        />
                      </div>
                      <div className="flex gap-3.5 justify-center">
                        <WalletCard
                          icon={<AddPaymentCardIcon className="w-11 h-11" />}
                          balance={transactions
                            .filter(
                              (transaction) => transaction.method === "deposit"
                            )
                            .reduce((sum, curr) => sum + curr.value, 0)}
                          selectedCurrency={preferredWallet.currency_code}
                          title={t("text_deposits")}
                          isLoading={isWalletLoading}
                        />
                        <WalletCard
                          icon={<MoneyDollarIcon className="w-11 h-11" />}
                          balance={transactions
                            .filter(
                              (transaction) => transaction.method === "withdraw"
                            )
                            .reduce(
                              (sum, curr) => sum + Math.abs(curr.value),
                              0
                            )}
                          selectedCurrency={preferredWallet.currency_code}
                          title={t("text_withdrawals")}
                          isLoading={isWalletLoading}
                        />
                      </div>
                      <div className="flex gap-3.5 justify-center">
                        <WalletCard
                          icon={<GiftIcon className="w-11 h-11" />}
                          balance={transactions
                            .filter(
                              (transaction) => transaction.method === "win"
                            )
                            .reduce((sum, curr) => sum + curr.value, 0)}
                          selectedCurrency={preferredWallet.currency_code}
                          title={t("text_winnings")}
                          isLoading={isWalletLoading}
                        />
                        <WalletCard
                          icon={<ControllerIcon className="w-11 h-11" />}
                          balance={transactions
                            .filter(
                              (transaction) => transaction.method === "wager"
                            )
                            .reduce((sum, curr) => sum + curr.value, 0)}
                          selectedCurrency={preferredWallet.currency_code}
                          title={t("text_total-bets")}
                          isLoading={isWalletLoading}
                        />
                      </div>
                    </div>

                    <h2 className="text-lg leading-6 font-bold text-primary-400 pt-8">
                      {t("text_transaction-history")}
                    </h2>
                    <div className="flex flex-col gap-3.5">
                      <WalletTables />
                    </div>
                  </div>
                </div>
              </>
            )}
          </NavBar>
          {/* Log out section */}
          {!menuState.startsWith("customerProfile") && (
            <div className="flex flex-col gap-4">
              {/* <Link
                to={"/under-construction"}
                className={`flex w-full gap-2 justify-center items-center bg-primary-400 text-tertiary-400 border rounded-md py-1.5 px-5 ${
                  menuState === "wallet" && "hidden"
                }`}
              >
                <PromotionIcon className="text-tertiary-400" />
                {t("button_promotions")}
              </Link> */}
              {token && (
                <Button
                  className="flex w-full gap-2 justify-center items-center text-greyscale-200 underline"
                  onClick={handleLogout}
                >
                  {t("button_header_logout")}
                  <LogoutIcon className="text-greyscale-200" />
                </Button>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default MobileMenu;
