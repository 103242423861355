import { SVGProps } from "react";

const BrotherBetsMobileLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <g clipPath="url(#a)">
            <path
                fill="url(#b)"
                d="M34.324 27.987c-3.981 0-6.985-1.76-8.276-4.718-1.29 2.958-4.295 4.718-8.276 4.718H.596A.595.595 0 0 1 0 27.39V.596C0 .267.267 0 .596 0h17.176c3.981 0 6.986 1.76 8.276 4.718C27.338 1.762 30.343 0 34.324 0H51.5c.33 0 .596.267.596.596v26.793c0 .33-.267.596-.596.596H34.324v.002Zm1.572-11.455c-1.699 0-2.753.96-2.753 2.506 0 1.328 1.029 2.256 2.505 2.256h8.435v-4.762H35.896ZM16.45 21.294c1.474 0 2.506-.928 2.506-2.256 0-1.546-1.055-2.506-2.754-2.506H8.017v4.762h8.435-.002Zm7.082-7.3a6.003 6.003 0 0 1 2.518 2.618 6 6 0 0 1 2.516-2.619 5.992 5.992 0 0 1-2.516-2.62 5.996 5.996 0 0 1-2.518 2.62Zm12.116-7.301c-1.474 0-2.505.928-2.505 2.256 0 1.546 1.054 2.505 2.753 2.505h8.185V6.693h-8.435.002Zm-19.446 4.761c1.699 0 2.753-.96 2.753-2.505 0-1.328-1.029-2.256-2.505-2.256H8.015v4.761H16.202Z"
            />
            <path
                fill="url(#c)"
                d="M34.324.596c-4.59 0-7.706 2.471-8.276 6.292-.57-3.82-3.686-6.292-8.276-6.292H.596v26.793h17.176c4.59 0 7.705-2.472 8.276-6.293.57 3.821 3.686 6.293 8.276 6.293H51.5V.596H34.324ZM16.45 21.89H7.42v-5.956h8.783c2.027 0 3.35 1.24 3.35 3.101 0 1.737-1.367 2.853-3.102 2.853v.002Zm-.25-9.838H7.417V6.097h9.031c1.735 0 3.101 1.115 3.101 2.852 0 1.861-1.324 3.101-3.35 3.101v.002Zm9.854 6.415-.01.01c-.544-2.888-2.796-4.303-4.421-4.484 1.625-.18 3.875-1.593 4.42-4.48l.013.013c.55 2.876 2.796 4.284 4.417 4.465-1.623.181-3.871 1.592-4.419 4.474v.002ZM44.68 21.89h-9.031c-1.735 0-3.101-1.116-3.101-2.852 0-1.861 1.324-3.101 3.35-3.101h8.782v5.955-.002Zm0-9.838h-8.783c-2.027 0-3.35-1.24-3.35-3.1 0-1.737 1.367-2.853 3.102-2.853h9.031v5.956-.003Z"
            />
        </g>
        <defs>
            <linearGradient
                id="b"
                x1={26.094}
                x2={26.002}
                y1={29.09}
                y2={-1.059}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E9C156" />
                <stop offset={0.18} stopColor="#FFA" />
                <stop offset={0.65} stopColor="#E1AB39" />
                <stop offset={0.91} stopColor="#633110" />
                <stop offset={0.99} stopColor="#E9C156" />
            </linearGradient>
            <linearGradient
                id="c"
                x1={26.048}
                x2={26.048}
                y1={-1.227}
                y2={30.058}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E9C156" />
                <stop offset={0.18} stopColor="#FFA" />
                <stop offset={0.65} stopColor="#E1AB39" />
                <stop offset={0.91} stopColor="#633110" />
                <stop offset={0.99} stopColor="#E9C156" />
            </linearGradient>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h52.096v27.987H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default BrotherBetsMobileLogo;
