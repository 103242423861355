import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FAQ,
  GamesFilter,
  GameList,
  MainContainer,
  GamesGridSlider,
} from "src/components/organisms";
import { ReactComponent as SkeletonGrid } from "src/assets/icons/loaders/All Slot Games.svg";
import { ReactComponent as SkeletonSlider } from "src/assets/icons/loaders/Most Popular.svg";
import { ReactComponent as BrokenHeartIcon } from "src/assets/icons/emoji/Broken Heart.svg";
import GameModal from "src/components/molecules/gameModal/GameModal.component";
import GamesSidebar from "src/components/molecules/gamesSidebar/GamesSidebar.component";
import ReactGA from "react-ga4";
import useGamesService from "src/services/gamesService";
import useNotificationsService from "src/services/notificationsService";
import { GameResponse } from "src/types";
import { GameType } from "src/types/GameType";
import { useAuth } from "src/context/AuthContext";
import { useScroll } from "src/context/ScrollContext";
import Favourifier from "src/components/organisms/favourifier/Favourifier.component";

export default function AllGamesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { playerData, token } = useAuth();
  const { getGame, getByType, getPagedGames } = useGamesService();
  const { addErrorNotification } = useNotificationsService();
  const { setSkipNext } = useScroll();

  const pageSize = 15;
  const [visibleItemsCount, setVisibleItemsCount] = useState(pageSize);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayGames, setDisplayGames] = useState<GameResponse[]>([]);
  const [selectedGame, setSelectedGame] = useState<GameResponse | null>(null);
  const [selectedFilterCode, setSelectedFilterCode] = useState<string>("none");
  const [titleKey, setTitleKey] = useState("");
  const [oldGameTypeId, setOldGameTypeId] = useState<string | undefined>("");
  const params = useParams<{
    gameTypeId?: string;
    gameId?: string;
    page?: string;
  }>();

  const handleSelectGame = (game: GameResponse) => {
    setSelectedGame(game);
    setSkipNext(true);
    navigate(`/all-games/${params.gameTypeId}/${game.game_id}`);
  };

  const resetGames = async (filter?: string) => {
    try {
      setSelectedFilterCode(filter || "none");
      setIsLoading(true);
      const mobile = window.innerWidth < 1024;
      const games = await getByType(
        params.gameTypeId!,
        mobile,
        filter,
        playerData?.id,
        token || ""
      );
      setDisplayGames(games);
      if (params.gameId) {
        const openGame = await getGame("blue_ocean", params.gameId);
        if (openGame) setSelectedGame(openGame);
      }
    } catch (error: any) {
      addErrorNotification(t("error_fetching-games"));
      console.log("Failed to fetch games: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreTriggerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (params.gameTypeId === oldGameTypeId) {
      // only the gameId changed, so don't redraw the list
      setSkipNext(true);
      return;
    }
    if (
      !GameType[params.gameTypeId!] ||
      (params.gameTypeId === "favourites" && !playerData)
    ) {
      navigate("/games/casino/" + (params.gameId || ""));
      return;
    }
    setOldGameTypeId(params.gameTypeId);
    setTitleKey(GameType[params.gameTypeId!].title);
    (async () => await resetGames())();
  }, [params]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isLoading) {
        setVisibleItemsCount((prevCount) => prevCount + 5);
      }
    });
    if (loadMoreTriggerRef.current) {
      observer.observe(loadMoreTriggerRef.current);
    }
    return () => {
      if (loadMoreTriggerRef.current) {
        observer.unobserve(loadMoreTriggerRef.current);
      }
    };
  }, [isLoading]);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Games Page",
  });

  return (
    <MainContainer onSearchResultItemClick={handleSelectGame}>
      <Favourifier onLoad={setDisplayGames} games={displayGames} />
      <div className="flex 2xl:px-52 xl:px-[84px] px-4 bg-custom-gradient w-full">
        <div className="NavBar flex gap-10 md:py-12 py-6 w-full">
          <div className="hidden lg:block">
            <GamesSidebar isLoading={isLoading} />
          </div>
          <div className="w-full flex flex-col gap-16">
            <div className="flex flex-col gap-4">
              <h1 className="text-[34px] text-greyscale-100 font-bold">
                <p className="capitalize">{t(titleKey)}</p>
              </h1>
              <GamesFilter
                isLoading={isLoading}
                selectedFilterCode={selectedFilterCode}
                show={GameType[params.gameTypeId!].showFilter}
                onFilter={resetGames}
              />
            </div>
            <GameModal
              selectedGame={selectedGame}
              onClose={() => {
                setSelectedGame(null);
                navigate("..", { relative: "path" });
              }}
            />
            {isLoading ? (
              <>
                <div className="lg:flex hidden">
                  <SkeletonGrid />
                </div>
                <div className="flex lg:hidden">
                  <SkeletonSlider />
                </div>
              </>
            ) : !displayGames?.length ? (
              <div className="lg:flex flex-col items-center gap-4 hidden">
                <BrokenHeartIcon className="w-24 h-24 text-greyscale-100" />
                <h2 className="text-2xl text-greyscale-100 text-center">
                  {t("text_system-maintenance")}
                </h2>
              </div>
            ) : (
              <>
                <GameList
                  title={t("text_all-games-in", { type: t(titleKey) })}
                  rows={3}
                  items={displayGames.slice(0, visibleItemsCount)}
                  onSelectGame={handleSelectGame}
                  className="lg:block hidden flex flex-col gap-4"
                  gameType={params.gameTypeId}
                />
                <GamesGridSlider
                  sliderHeaderContent={t("text_all-games-in", {
                    type: t(titleKey),
                  })}
                  contentList={displayGames.slice(0, visibleItemsCount)}
                  onSelectGame={handleSelectGame}
                  className="lg:hidden block flex flex-col gap-10 lg:hidden md:w-[calc(100vw-54px)] w-[calc(100vw-32px)]"
                />
              </>
            )}
            <div ref={loadMoreTriggerRef} style={{ height: "1px" }} />
            <div className="md:px-[173px]">
              <FAQ />
            </div>
            <section className="flex flex-col md:px-64 md:py-20 text-sm items-center text-center text-greyscale-100 gap-8">
              <b>{t("text_gambling-aware-2")}</b>
              <p>{t("text_copyright")}</p>
            </section>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}
