import React, { useEffect, useState, useCallback } from "react";
import { ReactComponent as UserSquareIcon } from "src/assets/icons/account/user.svg";
import { ReactComponent as WalletIcon } from "src/assets/icons/account/wallet.svg";
import { ReactComponent as BonusIcon } from "src/assets/icons/account/people.svg";
import { MainContainer, TransactionModal } from "src/components/organisms";
import { ReactComponent as EditIcon } from "src/assets/icons/edit/Edit.svg";
import { ReactComponent as DepositIcon } from "src/assets/icons/money&payment/Add Payment Card.svg";
import { ReactComponent as WithdrawIcon } from "src/assets/icons/money&payment/Favourite Payment Card.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Clear.svg";
import { useAuth } from "src/context/AuthContext";
import ReactGA from "react-ga4";
import useTransactionService from "src/services/transactionService";
import { Button } from "src/components/atoms";
import { GameResponse } from "src/types";
import GameModal from "src/components/molecules/gameModal/GameModal.component";
import useNotificationsService from "src/services/notificationsService";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Balance } from "src/components/molecules";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PurpleButton from "src/components/atoms/button/PurpleButton.component";
import GreenButton from "src/components/atoms/button/GreenButton.component";

export default function AccountPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getBalances /* , getCurrencies */ } = useTransactionService();
  const { addErrorNotification, addInfoNotification } =
    useNotificationsService();
  const { playerData } = useAuth();
  const [amount, setAmount] = useState<number>(0);
  const [walletAmount, setWalletAmount] = useState<number>(0);
  const [walletPrefix, setWalletPrefix] = useState<string>("$");
  const [currency, setCurrency] = useState<string>("USD");
  const [selectedGame, setSelectedGame] = useState<GameResponse | null>(null);
  const [isWalletLoading, setWalletLoading] = useState<boolean>(true);

  const isEmailVerified = playerData?.email_verified || false;

  const HeaderSection: React.FC = () => {
    const { t } = useTranslation();
    return (
      <div className="rounded-t-10 w-full bg-greyscale-100 flex px-16 py-[22px] justify-between text-primary-400">
        <div className="flex gap-7 items-center">
          <span className="rounded-full min-w-[76px] min-h-[76px] border-2 border-greyscale-100 bg-primary-400 text-lg flex justify-center items-center text-greyscale-100">
            {playerData?.first_name?.charAt(0).toUpperCase()}
            {playerData?.last_name?.charAt(0).toUpperCase()}
          </span>
          <div>
            <div className="flex items-center justify-between">
              <div className="text-2xl font-semibold">
                {playerData?.first_name} {playerData?.last_name}
              </div>
            </div>
            <div className="flex items-center gap-[34px]">
              <span className="font-light text-base">
                {t("text_player-id")}:{" "}
                <span className="font-semibold">{playerData?.nickname}</span>
              </span>
              {location.pathname === "/account/edit" ? (
                <Button
                  className="flex items-center gap-2.5  py-1 px-3.5 rounded-sm text-greyscale-100 bg-primary-400 hover:text-primary-400 hover:bg-primary-100 transition duration-300 text-[10px] leading-[17px]"
                  onClick={() => navigate("/account")}
                >
                  <span className="hidden 2xl:block">
                    {t("button_close-edit")}
                  </span>
                  <CloseIcon className="w-3.5" />
                </Button>
              ) : (
                <Button
                  className="flex items-center gap-2.5  py-1 px-3.5 rounded-sm text-greyscale-100 bg-primary-400 hover:text-primary-400 hover:bg-primary-100 transition duration-300 text-[10px] leading-[17px]"
                  onClick={() => navigate("/account/edit")}
                >
                  <span className="hidden 2xl:block">
                    {t("button_edit-profile")}
                  </span>
                  <EditIcon className="w-3.5" />
                </Button>
              )}
            </div>
          </div>
          <div></div>
        </div>
        <div className="flex items-center gap-5 flex-wrap">
          <Balance
            selectedCurrency={currency}
            balance={amount}
            isLoading={isWalletLoading}
          />
          {/* prettier-ignore */}
          <div className="flex flex-row gap-5">
          <PurpleButton onClick={() => handleTransactionModal("deposit")}>
            <span className="hidden xl:block">{t("text_deposit")}</span>
            <DepositIcon />
          </PurpleButton>
          <GreenButton onClick={() => handleTransactionModal("withdraw")}>
            <span className="hidden xl:block">{t("text_withdraw")}</span>
            <WithdrawIcon />
          </GreenButton>
          </div>
        </div>
      </div>
    );
  };

  const [isTransactionModalOpen, setTransactionModalOpen] =
    useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<
    "deposit" | "withdraw"
  >("deposit");

  const fetchBalances = useCallback(async () => {
    if (!playerData?.id) return; // Ensure playerData.id is available
    try {
      const balances = await getBalances(playerData.id);
      // NOTE: Only Euros are supported for now
      // const currencies = await getCurrencies();
      const walletAmount =
        balances.find((item) => item.currency_id === playerData.currency_id)
          ?.amount || 0;
      const walletBonus =
        balances.find((item) => item.currency_id === playerData.currency_id)
          ?.bonus || 0;
      const balance = walletAmount + walletBonus;
      setAmount(balance);
      setWalletAmount(walletAmount);
      /* const currency = currencies.find(
        (item) => item.id === playerData.currency_id
      )?.alphabetic_code; */
      const currency = "EUR";
      setCurrency(currency || "USD");
      setWalletPrefix(currency === "EUR" ? "€" : "$");
    } catch (error) {
      addErrorNotification(t("error_get-balances"));
      console.error("Error getting balances: ", error);
    } finally {
      setWalletLoading(false);
    }
  }, [
    getBalances,
    /* getCurrencies,  */ playerData?.currency_id,
    playerData?.id,
  ]);

  useEffect(() => {
    fetchBalances();
  }, [fetchBalances]);

  const sections = [
    {
      titleKey: "text_account-profile",
      icon: <UserSquareIcon />,
      location: "/account",
      alternateLocation: "/account/edit",
    },
    {
      titleKey: "text_wallet",
      icon: <WalletIcon />,
      location: "/account/wallet",
    },
  ];

  const handleTransactionModal = (type: "deposit" | "withdraw") => {
    if (!isEmailVerified) {
      return addInfoNotification(t("info_email-not-verified-transactions"));
    }
    setTransactionType(type);
    // Add begin checkout event
    ReactGA.event("begin_checkout", {
      currency: "",
      value: 0,
      items: [
        {
          item_id: type,
          item_name: playerData?.id || "",
        },
      ],
    });
    setTransactionModalOpen(true);
  };

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Account Page",
  });

  return (
    <MainContainer onSearchResultItemClick={setSelectedGame}>
      <TransactionModal
        isOpen={isTransactionModalOpen}
        transactionType={transactionType}
        balance={walletAmount.toString()}
        onClose={() => setTransactionModalOpen(false)}
        currencyPrefix={walletPrefix}
      />
      <GameModal
        selectedGame={selectedGame}
        onClose={() => setSelectedGame(null)}
      />
      <div className="flex 2xl:px-52 xl:px-[84px] px-4 bg-custom-gradient">
        <div className="flex gap-10 md:py-12 py-6 w-full">
          <div className="flex flex-col gap-3.5 select-none">
            {sections.map((item, index) => {
              const selected =
                item.location === location.pathname ||
                item.alternateLocation === location.pathname;
              return (
                <div
                  key={`menu_${index}`}
                  className={`${
                    selected
                      ? "bg-greyscale-100 font-bold text-primary-200"
                      : "bg-none text-greyscale-100 border border-greyscale-100"
                  }
                                    flex gap-6 xl:pl-6 px-3.5 py-3.5 xl:w-56
                                    rounded cursor-pointer
                                    `}
                  onClick={() => navigate(item.location)}
                >
                  <div className="h-6 w-6">{item.icon}</div>
                  <span className="hidden xl:block">{t(item.titleKey)}</span>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col flex-grow gap-4 min-w-0 xl:max-w-4xl 2xl:max-w-full">
            <HeaderSection />
            {/* vvv profile/wallet/bonus page goes here: */}
            <Outlet />
          </div>
        </div>
      </div>
    </MainContainer>
  );
}
