import AlterPasswordForm from "src/components/organisms/form/AlterPasswordForm.component";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/context/AuthContext";
import React from "react";
import usePlayerService from "src/services/playerService";

const ChangePasswordForm = ({ afterChange }: { afterChange: () => void }) => {
  const { t } = useTranslation();
  const { playerData } = useAuth();
  const { changePassword } = usePlayerService();

  const doChange = async (currentPassword: string, newPassword: string) => {
    return changePassword({
      // allow it to break on the API call because requesting a password change
      // with empty player data should never be a normal flow state
      id: playerData?.id || "",
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
  };

  return (
    <div className={` flex flex-col gap-8`}>
      <h1 className="text-[40px] leading-[48px] text-primary-400 mx-auto">
        {t("text_change-pwd")}
      </h1>
      <AlterPasswordForm doAlter={doChange} afterAlter={afterChange} />
    </div>
  );
};

export default ChangePasswordForm;
