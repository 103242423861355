import { Splide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

export interface BannerSliderProps {
  sliderId?: string;
  className?: string;
  children?: React.ReactNode;
}

export const BannerSlider = ({
  sliderId = "banner-splide-slider",
  className = "",
  children,
}: BannerSliderProps) => {
  return children ? (
    <>
      <Splide
        id={sliderId}
        hasTrack={false}
        tag="section"
        options={{
          arrows: false,
          autoplay: true,
          interval: 5000,
          rewind: true,
          type: "fade",
        }}
        className={className}
      >
        <SplideTrack>{children}</SplideTrack>
      </Splide>
    </>
  ) : (
    <p>No items</p>
  );
};

export default BannerSlider;
