import { SVGProps } from "react"
const BurgerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      stroke="#2F0249"
      strokeLinecap="round"
      strokeWidth={2}
      d="M6 8h14.5M6 17h23"
    />
  </svg>
)
export default BurgerIcon
