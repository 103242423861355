import { useField, FieldHookConfig } from "formik";
import React, { useState } from "react";
import { ReactComponent as ChevronUpIcon } from "../../../../assets/icons/arrows/Chevron Up.svg";
import { ReactComponent as ChevronDownIcon } from "../../../../assets/icons/arrows/Chevron Down.svg";

export interface InputSelectProps {
  label?: string;
  children?: React.ReactNode;
}

const InputSelect: React.FC<
  InputSelectProps & FieldHookConfig<string> & JSX.IntrinsicElements["select"]
> = ({ label = "", children, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
    setIsOpen(false);
    field.onBlur(e);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    helpers.setValue(event.target.value);
  };
  return (
    <div className="relative w-full bg-none flex flex-col gap-1">
      {label && (
        <label
          htmlFor={field.name}
          className="absolute left-4 -top-1.5 text-[10px] bg-greyscale-100 px-1 z-10"
        >
          {label}
        </label>
      )}
      <select
        {...field}
        value={field.value || ""}
        onChange={handleSelectChange}
        onFocus={toggleDropdown}
        onBlur={handleBlur}
        className={`w-full h-14 appearance-none border bg-transparent border-primary-400 rounded px-4 text-sm ${
          meta.touched && meta.error && "border-warning-100 text-warning-100"
        }`}
      >
        {children}
      </select>
      {isOpen ? (
        <ChevronUpIcon
          className={`absolute top-4 right-4 pointer-events-none ${
            meta.touched && meta.error && "text-warning-100"
          }`}
        />
      ) : (
        <ChevronDownIcon
          className={`absolute top-4 right-4 pointer-events-none ${
            meta.touched && meta.error && "text-warning-100"
          }`}
        />
      )}
      {meta.touched && meta.error && (
        <span className="pl-5 text-[10px] text-warning-100">{meta.error}</span>
      )}
    </div>
  );
};

export default InputSelect;
