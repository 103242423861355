import { useRemarkSync } from "react-remark";
import { Options as RehypeReactOptions } from "rehype-react";
import { createElement, ReactElement } from "react";

// component that renders markdown text as react components
export default function Markdown(props: {
  content: string;
  options?: MarkdownOptions;
}) {
  const mdOptions = {
    components: {
      // make anchored links out of headers. only works when the tag content
      // is just text, no formatting no nothing
      h1: (props: any) => toAnchoredTag("h1", props),
      h2: (props: any) => toAnchoredTag("h2", props),
      h3: (props: any) => toAnchoredTag("h3", props),
    },
  };
  return useRemarkSync(props.content, {
    rehypeReactOptions: { ...mdOptions, ...props.options },
  });
}

const toAnchoredTag = (
  Tag: keyof JSX.IntrinsicElements,
  content: any
): ReactElement<{}> => {
  if (typeof content.children[0] !== "string") {
    // this is a DOM object and not text, too tough to extract text out of its
    // arbitrary children so no anchors for anyone
    return <Tag>{content.children}</Tag>;
  }
  return (
    <Tag id={toAnchorId(content.children[0])}>
      <a href={`#${toAnchorId(content.children[0])}`}>{content.children[0]}</a>
    </Tag>
  );
};

const toAnchorId = (title: string) =>
  // anchor will be the Snake_case title, stripped of everything that isn't basic letters
  title.replaceAll(/[\s]/g, "_").replaceAll(/[^0-9a-zA-Z_]/g, "");

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
// alias for this weird type, copied straight from react-remark index.ts
export type MarkdownOptions = PartialBy<
  RehypeReactOptions<typeof createElement>,
  "createElement"
>;
