import { useTranslation } from "react-i18next";
import { WalletTables } from "src/components/organisms";
import { ReactComponent as ControllerIcon } from "src/assets/icons/nav/Controller.svg";
import { ReactComponent as AddPaymentCardIcon } from "src/assets/icons/money&payment/Add Payment Card.svg";
import { ReactComponent as GiftIcon } from "src/assets/icons/shopping/Gift.svg";
import { ReactComponent as MoneyDollarIcon } from "src/assets/icons/money&payment/Money Dollar.svg";
import { ReactComponent as WalletIcon } from "src/assets/icons/account/wallet.svg";
import { ReactComponent as AmountIcon } from "src/assets/icons/money&payment/Wallet.svg";
import { ReactComponent as BonusIcon } from "src/assets/icons/nav/Promos.svg";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useAuth } from "src/context/AuthContext";
import useTransactionService from "src/services/transactionService";
import useNotificationsService from "src/services/notificationsService";
import { WalletCard } from "src/components/molecules";

interface PreferredWallet {
  currency_code: string;
  amount: number;
  bonus: number;
}

interface Transaction {
  dateTime: Date;
  method: string;
  value: number;
  status: string;
  currency: string;
}

export default function WalletPage() {
  const { t } = useTranslation();
  const [preferredWallet, setPreferredWallet] = useState<PreferredWallet>({
    currency_code: "",
    amount: 0,
    bonus: 0,
  });
  const { playerData } = useAuth();
  const { getBalances, getTransactions } = useTransactionService();
  const { addErrorNotification } = useNotificationsService();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isWalletLoading, setIsWalletLoading] = useState<boolean>(true);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Wallet Page",
  });

  useEffect(() => {
    (async (): Promise<void> => {
      if (playerData?.id) {
        try {
          const wallets = await getBalances(playerData.id);
          const preferredWallet = wallets.find(
            (wallet) => wallet.currency_id === playerData.currency_id
          );
          if (!preferredWallet) {
            addErrorNotification("Error fidding preferred wallet");
            return;
          }
          setPreferredWallet({
            currency_code: preferredWallet.alphabetic_code,
            amount: preferredWallet.amount,
            bonus: preferredWallet.bonus,
          });
        } catch (error) {
          addErrorNotification("Error getting wallets");
          console.error("Error getting wallets: ", error);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!playerData?.id) return;
      try {
        const result = await getTransactions(playerData.id);
        setTransactions(
          result.map((transaction) => ({
            dateTime: new Date(transaction.transaction_date),
            method: transaction.type,
            value: transaction.amount,
            status: transaction.status,
            currency: transaction.currency,
          }))
        );
      } catch (error) {
        addErrorNotification("Error getting transactions");
        console.error("Error getting transactions: ", error);
      } finally {
        setIsWalletLoading(false);
      }
    })();
  }, []);

  return (
    <div className="flex flex-col rounded-b border">
      <div className="w-full flex justify-center items-center bg-secondary-200 py-5 gap-2.5">
        <WalletIcon className="text-primary-400" />
        <span className="text-xl leading-[26px] text-primary-400 font-bold">
          Wallet
        </span>
      </div>
      <div className="w-full bg-greyscale-100 py-16 px-14 flex lg:flex-row flex-col gap-20">
        <div className="flex flex-col gap-3.5">
          <div className="flex flex-wrap gap-3.5 max-w-[21rem]">
            <WalletCard
              icon={<AmountIcon className="w-11 h-11" />}
              selectedCurrency={preferredWallet.currency_code}
              balance={preferredWallet.amount}
              title={t("text_amount")}
              isLoading={isWalletLoading}
            />
            <WalletCard
              icon={<BonusIcon className="w-11 h-11" />}
              selectedCurrency={preferredWallet.currency_code}
              balance={preferredWallet.bonus}
              title={t("text_bonus")}
              isLoading={isWalletLoading}
            />
            <WalletCard
              icon={<AddPaymentCardIcon className="w-11 h-11" />}
              selectedCurrency={preferredWallet.currency_code}
              balance={transactions
                .filter((transaction) => transaction.method === "deposit")
                .reduce((sum, curr) => sum + curr.value, 0)}
              title={t("text_deposits")}
              isLoading={isWalletLoading}
            />
            <WalletCard
              icon={<MoneyDollarIcon className="w-11 h-11" />}
              selectedCurrency={preferredWallet.currency_code}
              balance={transactions
                .filter((transaction) => transaction.method === "withdraw")
                .reduce((sum, curr) => sum + Math.abs(curr.value), 0)}
              title={t("text_withdrawals")}
              isLoading={isWalletLoading}
            />
            <WalletCard
              icon={<GiftIcon className="w-11 h-11" />}
              selectedCurrency={preferredWallet.currency_code}
              balance={transactions
                .filter((transaction) => transaction.method === "win")
                .reduce((sum, curr) => sum + curr.value, 0)}
              title={t("text_winnings")}
              isLoading={isWalletLoading}
            />
            <WalletCard
              icon={<ControllerIcon className="w-11 h-11" />}
              selectedCurrency={preferredWallet.currency_code}
              balance={transactions
                .filter((transaction) => transaction.method === "wager")
                .reduce((sum, curr) => sum + curr.value, 0)}
              title={t("text_total-bets")}
              isLoading={isWalletLoading}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3.5 flex-grow">
          <WalletTables />
        </div>
      </div>
    </div>
  );
}
