import React, { useState } from "react";
import { ReactComponent as CashbullsIcon } from "src/assets/icons/logos/cashbulls.svg";

/* interface ProviderProps {
  id: string;
  name: string;
} */

interface PaymentProviderSelectorProps {
  onSelect: (value: string) => void;
  //providers: ProviderProps[];
}

const PaymentProviderSelector: React.FC<PaymentProviderSelectorProps> = ({
  onSelect,
  //providers,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    onSelect(value);
  };

  return (
    <div className="flex flex-col gap-2.5">
      <label className="border border-primary-200 rounded-[10px] px-6 py-3.5 flex gap-3.5 items-center">
        <div className="grid place-items-center">
          <input
            type="radio"
            id="cashbulls"
            name="single-select"
            /* value={
              providers.find((provider) => provider.name === "cashbulls")?.id
            }
            checked={
              selectedValue ===
              providers.find((provider) => provider.name === "cashbulls")?.id
            } */
            value={"cashbulls"}
            checked={selectedValue === "cashbulls"}
            onChange={handleChange}
            className="peer col-start-1 row-start-1 appearance-none shrink-0 w-6 h-6 border-2 border-primary-300 rounded-full"
          />
          <div className="col-start-1 row-start-1 w-3 h-3 rounded-full peer-checked:bg-primary-300" />
        </div>
        <div className="group bg-[#4D4D4D] p-2 rounded-xl mx-auto relative">
          <CashbullsIcon className="w-10 h-10" />
          <span className="opacity-0 group-hover:opacity-100 transition-opacity absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-greyscale-400 text-greyscale-100 text-sm p-1 rounded-md whitespace-nowrap">
            Cash Bulls
          </span>
        </div>
      </label>
    </div>
  );
};

export default PaymentProviderSelector;
