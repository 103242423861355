import { useAxiosInterceptors } from "./apiConfig";

const useCustomBonusService = () => {
  const { axiosCustomBonusInstance } = useAxiosInterceptors();

  const check = async (
    playerId: string,
    bonusCode: string,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axiosCustomBonusInstance.get(
        `/custom-bonus/player/${playerId}/check?bonusCode=${bonusCode}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return !!response.data.claimable;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const claim = async (
    playerId: string,
    bonusCode: string,
    token: string
  ): Promise<any> => {
    try {
      const response = await axiosCustomBonusInstance.post(
        `/custom-bonus/player/${playerId}/claim?bonusCode=${bonusCode}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return { check, claim };
};

export default useCustomBonusService;
