import { useTranslation } from "react-i18next";
import { ReactComponent as HeartIcon } from "src/assets/icons/emoji/Heart/Filled.svg";
import { Button } from "src/components/atoms";
import {
  SingleSelect,
  SingleSelectDropdownOptionProps,
} from "src/components/molecules";
import { ReactComponent as SkeletonFilter } from "src/assets/icons/loaders/filter_quickFilter.svg";
import { useAuth } from "src/context/AuthContext";

type GamesFilterOption = SingleSelectDropdownOptionProps;

/* const providerOptions: MultipleSelectDropdownOptionProps[] = [
  { value: "playtech", label: "Playtech" },
  {
    value: "evolution",
    label: "Evolution",
    secondaryLabel: "Exclusive games available",
  },
  { value: "pragmatic_play", label: "Pragmatic Play" },
  { value: "auth_gaming", label: "Authentic Gaming" },
  {
    value: "amusnet",
    label: "Amusnet Interactive Ltd",
    secondaryLabel: "Exclusive games available",
  },
  { value: "hacksaw", label: "Hacksaw Gaming" },
]; */

export interface GamesFilterProps {
  isLoading?: boolean;
  show?: boolean;
  onFilter: (filterCode: string) => void;
  selectedFilterCode: string;
}

export const GamesFilter = (props: GamesFilterProps) => {
  const { t } = useTranslation();
  const { playerData, token } = useAuth();

  const gameFilters: GamesFilterOption[] = [
    { value: "none", label: t("option_no-filter") },
    { value: "popular", label: t("option_most-popular") },
    /* { value: "biggest_winners", label: t("option_biggest-winners") }, */
    {
      value: "recent",
      label: t("option_recently-played"),
      disabled: !playerData || !token,
    },
    {
      value: "favourites",
      label: t("option_my-fav"),
      disabled: !playerData || !token,
    },
  ];
  /* const [selectedProviderOptions, setSelectedProviderOptions] = useState<
    MultipleSelectDropdownOptionProps[]
  >([]); */

  /* const handleProviderChange = (
    selected: MultipleSelectDropdownOptionProps[]
  ) => {
    alert(
      "The filter service is still under development. Thank for your comprehension."
    );
    setSelectedProviderOptions(selected);
  }; */

  const handleGameFilterChange = (option: GamesFilterOption) => {
    props.onFilter(option.value);
  };

  const getFilter = (filterCode: string) =>
    gameFilters.filter((gf) => gf.value === filterCode)[0];

  if (props.isLoading) {
    return <SkeletonFilter />;
  } else if (!props.show) {
    return <></>;
  }

  return (
    <div className="flex gap-8 md:justify-normal justify-between">
      {/* <MultipleSelect
        options={providerOptions}
        selectedOptions={selectedProviderOptions}
        onChange={handleProviderChange}
      />
      <div className="w-[0.5px] h-[auto] bg-greyscale-100 md:block hidden" /> */}
      <div className="xl:flex hidden items-center gap-3.5">
        {gameFilters.map((filter) => (
          <Button
            key={filter.value}
            className={`flex items-center gap-2.5 px-6 py-3 border rounded-md text-sm ${
              props.selectedFilterCode === filter.value
                ? "bg-[#FFD600] text-primary-400 border-[#FFD600]"
                : "text-greyscale-100"
            }`}
            isDisabled={filter.disabled}
            onClick={() => handleGameFilterChange(filter)}
          >
            {filter.label}
            {filter.value === "favourites" && (
              <HeartIcon
                className={
                  props.selectedFilterCode === filter.value
                    ? "text-primary-400"
                    : "text-greyscale-100"
                }
              />
            )}
          </Button>
        ))}
      </div>
      <div className="flex xl:hidden items-center gap-3.5">
        <SingleSelect
          options={gameFilters}
          selectedOption={getFilter(props.selectedFilterCode)}
          onChange={handleGameFilterChange}
        />
      </div>
    </div>
  );
};

export default GamesFilter;
