import { LoginContainer } from "src/components/organisms";
import ReactGA from "react-ga4";
import AlterPasswordForm from "src/components/organisms/form/AlterPasswordForm.component";
import usePlayerService from "src/services/playerService";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

export default function UnderConstructionPage() {
  const { resetPassword } = usePlayerService();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { email, resetToken } = useParams<{
    email: string;
    resetToken: string;
  }>();

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Reset Password Page",
  });

  const doReset = async (currentPassword: string, newPassword: string) => {
    return resetPassword({
      email: email!,
      resetToken: resetToken!,
      newPassword: newPassword,
    });
  };

  const toLogin = () => navigate("/login");

  return (
    <LoginContainer>
      <div className="h-full flex justify-center pt-32 pb-52 text-primary-400 px-6">
        <div className="flex flex-col gap-8">
          <h1 className="text-[40px] leading-[48px] text-primary-400 mx-auto">
            {t("text_reset-pwd")}
          </h1>
          <AlterPasswordForm doAlter={doReset} afterAlter={toLogin} />
        </div>
      </div>
    </LoginContainer>
  );
}
