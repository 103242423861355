import { useTranslation } from "react-i18next";
import { ReactComponent as RightArrowIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { Button } from "src/components/atoms";
import {
  AccountProfileStats,
  ChangePasswordModal,
  EditProfileForm,
  GamesSlider,
} from "src/components/organisms";
import ReactGA from "react-ga4";
import ChangePasswordButton from "src/components/atoms/button/ChangePasswordButton.component";
import { ReactComponent as SquareUserIcon } from "src/assets/icons/account/user.svg";
import { useEffect, useState } from "react";
import { GameResponse } from "src/types";
import useGamesService from "src/services/gamesService";
import { useAuth } from "src/context/AuthContext";
import useNotificationsService from "src/services/notificationsService";
import { PacmanLoader } from "react-spinners";
import Favourifier from "src/components/organisms/favourifier/Favourifier.component";
import GameModal from "../../components/molecules/gameModal/GameModal.component";

export interface ProfilePageProps {
  isEditing: boolean;
}

export default function ProfilePage({ isEditing }: ProfilePageProps) {
  const { t } = useTranslation();
  const { playerData, token } = useAuth();
  const { getLastPlayed } = useGamesService();
  const { addErrorNotification } = useNotificationsService();

  const [displayGames, setDisplayGames] = useState<GameResponse[]>([]);
  const [isSliderLoading, setIsSliderLoading] = useState<boolean>(true);
  const [selectedGame, setSelectedGame] = useState<GameResponse | null>(null);

  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setIsSliderLoading(true);
        const games = await getLastPlayed(playerData!.id, token!);
        setDisplayGames(games.filter((game) => game !== null));
      } catch (error: any) {
        addErrorNotification(t("error_fetching-games"));
        console.log("Failed to fetch games: ", error);
      } finally {
        setIsSliderLoading(false);
      }
    })();
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Profile Page",
  });

  return (
    <div className="flex flex-col gap-2.5 w-full">
      <div className="w-full bg-greyscale-100 relative">
        {isEditing && (
          <div className="w-full flex items-center bg-secondary-200 py-5 gap-2.5 px-6">
            <div className="flex gap-2 mx-auto">
              <SquareUserIcon className="text-primary-400" />
              <span className="text-xl leading-[26px] text-primary-400 font-bold">
                {t("text_customer-profile")}
              </span>
            </div>
            <div className="absolute right-14">
              <ChangePasswordButton
                onClick={() => setChangePasswordModalOpen(true)}
              />
            </div>
          </div>
        )}
        <ChangePasswordModal
          isOpen={isChangePasswordModalOpen}
          onClose={() => setChangePasswordModalOpen(false)}
        />
        <div className="py-16 px-14">
          {isEditing ? (
            <EditProfileForm />
          ) : (
            <div className="flex flex-col gap-14">
              <div className="lg:w-[calc(100%-366px)]">
                <AccountProfileStats />
              </div>
              {/* <div className="h-full lg:w-[366px] lg:absolute z-10 right-0 top-0">
                <div className="h-full w-full backdrop-blur-md pt-12 px-11 flex flex-col gap-6">
                  <div className="pl-6 text-primary-400 text-xl font-bold max-[1760px]:hidden">
                    {t("text_my-bonuses")}
                  </div>
                  <div className="rounded-10 bg-primary-400 flex flex-col items-center text-white gap-2.5 p-6">
                    <div className="flex flex-col items-center text-2xl uppercase">
                      <span>{t("text_bet")} £5</span>
                      <span className="font-semibold">{t("text_get")} £5</span>
                    </div>
                    <Button className="bg-secondary-200 rounded flex items-center justify-center py-3.5 px-6 text-black text-sm w-full">
                      {t("button_claim")}
                    </Button>
                  </div>
                  <div className="rounded-10 bg-[#FF8A4A] flex flex-col items-center text-black gap-2.5 p-6">
                    <div className="flex flex-col items-center text-2xl uppercase">
                      <span>{t("text_referral-received")}</span>
                      <span className="font-semibold">
                        {t("text_claim")} £50
                      </span>
                    </div>
                    <Button className="border border-black rounded flex items-center justify-center py-3.5 px-6 text-black text-sm w-full gap-3.5">
                      {t("button_claim")}
                      <RightArrowIcon />
                    </Button>
                  </div>
                </div>
              </div> */}
              <div className="hidden lg:block 2xl:max-w-[calc(100vw-49.5rem)] xl:max-w-[calc(100vw-47rem)] lg:max-w-[calc(100vw-15.5rem)]">
                <Favourifier onLoad={setDisplayGames} games={displayGames} />
                <GameModal
                  selectedGame={selectedGame}
                  onClose={() => setSelectedGame(null)}
                />
                {!isSliderLoading ? (
                  <GamesSlider
                    sliderHeaderContent={
                      <span className="text-primary-400">
                        {t("text_recently-played")}
                      </span>
                    }
                    contentList={displayGames}
                    onSelectGame={setSelectedGame}
                  />
                ) : (
                  <PacmanLoader />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
