export interface NavBarProps {
  className?: string;
  orientation?: "horizontal" | "vertical";
  children?: React.ReactNode;
}

export const NavBar = ({
  className = "",
  orientation = "horizontal",
  children,
}: NavBarProps) => {
  return (
    <nav
      className={`flex ${
        orientation === "horizontal" ? "flex-row" : "flex-col"
      } ${className}`}
    >
      {children}
    </nav>
  );
};

export default NavBar;
