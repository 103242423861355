import React, { ComponentType, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useScroll } from "src/context/ScrollContext";

export interface ScrollProps {
  children?: React.ReactNode;
  // skips the next scroll. use it e.g. before invoking navigate()
  // so it keeps the page position
  skipNext?: boolean;
  // forces a scroll. set it to anything different (e.g. new Date()) to scroll,
  // so this instance skips the rendering cache and actually reruns useEffect.
  // use it e.g. after filling a lazy loaded container
  forceScrollToken?: Date;
}

// actual HOC. you probably don't need to instantiate this; see ScrollContext
// for skipping the scroll on the scroller that is already placed in every page,
// and see ForceScroll for a specialized scroll forcer you can instantiate on
// lazy loaded containers
const withScroll =
  <P extends ScrollProps>(Component: ComponentType<P>) =>
  ({ ...props }: P) => {
    const location = useLocation();
    const { setSkipNext } = useScroll();

    useEffect(() => {
      if (props.skipNext) {
        setSkipNext(false);
        return;
      }
      let element;
      if (location.hash) {
        element = document.getElementById(location.hash.slice(1));
      }
      if (!element) {
        window.scrollTo(0, 0);
      } else {
        element.scrollIntoView();
        // offset the header so it won't hide behind it
        window.scrollBy(0, -100);
      }
    }, [location, location.hash, props.forceScrollToken]);

    return <Component {...props} />;
  };

export default withScroll;
