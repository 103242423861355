import React from "react";
import withScroll, { ScrollProps } from "src/higherOrderComponents/withScroll";

const Scroll = withScroll((props: ScrollProps) => <>{props.children}</>);

// forces a scroll. not a ScrollContext method because that would trigger an
// infinite rerender loop for some reason. instance one of these right after
// whichever asynchronously loaded container is breaking the scroll
const ForceScroll = () => <Scroll forceScrollToken={new Date()} />;
export default ForceScroll;
