import React from "react";
import { Footer, LoginHeader } from "src/components/organisms";

export interface LoginContainerProps {
  lastStep?: boolean;
  children?: React.ReactNode;
}

export const LoginContainer = (props: LoginContainerProps) => (
  <div className="font-main bg-login h-fit w-full flex flex-col relative">
    <LoginHeader lastStep={props.lastStep} />
    <>{props.children}</>
    <Footer />
  </div>
);

export default LoginContainer;
