import { CreatePlayerRequest } from "src/types";
import usePlayerService from "./playerService";
import { useAuth } from "src/context/AuthContext";
import useNotificationsService from "./notificationsService";
import { useTranslation } from "react-i18next";

const useRegisterService = () => {
  const { t } = useTranslation();

  const { registerPlayer, login } = usePlayerService();
  const { setToken, setRefreshToken, setPlayerData } = useAuth();
  const { addErrorNotification } = useNotificationsService();

  const register = async (playerData: CreatePlayerRequest): Promise<void> => {
    try {
      // Register a new player
      const { player_id } = await registerPlayer(playerData);
      localStorage.setItem("player_id", player_id);
      // Login the player
      try {
        const {
          player,
          token,
          refresh_token: refreshToken,
        } = await login(playerData.email, playerData.password);
        setPlayerData(player);
        setToken(token);
        setRefreshToken(refreshToken);
      } catch (error: any) {
        addErrorNotification(t("error_login"));
        console.log("Error logging in player: ", error);
      }
    } catch (error: any) {
      addErrorNotification(t("error_register"));
      console.log("Error registering player: ", error);
    }
  };

  return { register };
};

export default useRegisterService;
