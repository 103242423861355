import { SVGProps } from "react";

const SimpleFlameIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.467 8.2536C15.4681 8.28246 15.469 8.31126 15.4697 8.33999L15.6013 8.24866L15.6018 8.24834L15.6029 8.24754L15.603 8.24749L15.7121 8.17179M15.467 8.2536L16.2569 10.2384M15.467 8.2536C15.4642 8.1927 15.504 8.13907 15.5592 8.12089C15.6144 8.1018 15.6777 8.12271 15.7121 8.17179M15.467 8.2536L16.2858 10.2286M15.7121 8.17179L15.604 8.24749L15.6032 8.24804L15.6028 8.2483L15.6015 8.24923L15.4698 8.34148C15.4871 9.03535 15.3876 9.69249 15.1746 10.2999C15.5224 10.3727 15.8938 10.3578 16.2569 10.2384M15.7121 8.17179C15.728 8.19466 15.7437 8.21758 15.7594 8.24057C15.7597 8.2411 15.7601 8.24163 15.7604 8.24216C16.1441 8.8062 16.4594 9.40678 16.7051 10.0286C16.5744 10.1099 16.4346 10.177 16.2881 10.2278M16.2569 10.2384L16.258 10.238M16.2569 10.2384C16.2619 10.2367 16.2668 10.2351 16.2718 10.2334C16.2772 10.2316 16.2827 10.2297 16.2881 10.2278M16.258 10.238L16.2881 10.2278M16.258 10.238L16.2858 10.2286M16.258 10.238L16.2858 10.2286M16.2881 10.2278L16.2858 10.2286M15.114 13.1807C15.1198 13.3896 15.1156 13.5973 15.1016 13.8028C14.9853 13.6208 14.8604 13.4439 14.7274 13.2727C14.8564 13.2496 14.9857 13.2191 15.114 13.1807ZM10.4232 6.76897C10.6202 7.43869 10.7585 8.20987 10.789 9.0863C10.3571 9.16603 9.94907 9.37306 9.62361 9.69589C9.14771 10.168 8.43312 11.0448 7.98218 12.346C7.42658 12.426 6.91209 12.7145 6.55377 13.1657C6.55431 13.1625 6.55485 13.1593 6.55539 13.156L6.5554 13.156C6.75468 11.9631 7.32482 10.7632 8.255 9.7039C8.87231 9.00108 9.71098 7.96293 10.4232 6.76897Z"
            fill="white"
            stroke="white"
            stroke-width="4.45892"
            stroke-linejoin="round"
        />
    </svg>
);
export default SimpleFlameIcon;
