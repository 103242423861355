import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Clear.svg";
import { ReactComponent as BackIcon } from "src/assets/icons/arrows/Chevron Left.svg";
import { ReactComponent as NextIcon } from "src/assets/icons/arrows/Arrow Right.svg";
import { ReactComponent as CelebrateIcon } from "src/assets/icons/emoji/Celebration.svg";
import {
  DepositSelector,
  PaymentProviderSelector,
} from "src/components/molecules/";
import usePaymentService from "src/services/paymentsService";
import { useAuth } from "src/context/AuthContext";
import { Link } from "react-router-dom";
import useTransactionService from "src/services/transactionService";
import ReactGA from "react-ga4";
import useNotificationsService from "src/services/notificationsService";
import { useTranslation } from "react-i18next";

interface TransactionModalProps {
  isOpen: boolean;
  transactionType: "deposit" | "withdraw";
  balance: string;
  onClose: () => void;
  currencyPrefix?: string;
}

const slideVariants = {
  initial: (direction: number) => ({
    x: direction > 0 ? "100%" : "-100%",
    opacity: 0,
  }),
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    x: direction < 0 ? "100%" : "-100%",
    opacity: 0,
  }),
};

const TransactionModal: React.FC<TransactionModalProps> = ({
  isOpen,
  transactionType,
  balance,
  onClose,
  currencyPrefix = "$",
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState(0);
  const [transactionValue, setTransactionValue] = useState<number | string>(
    "0"
  );
  const [provider, setProvider] = useState("");
  /* const [providers, setProviders] = useState<{ id: string; name: string }[]>([
    { id: "", name: "" },
  ]); */
  const [isTransactionProcessing, setIsTransactionProcessing] =
    useState<boolean>(false);

  const { playerData } = useAuth();
  const { addErrorNotification, addInfoNotification } =
    useNotificationsService();

  const { createDeposit /* , getProviders */ } = usePaymentService();
  const { getBalances } = useTransactionService();

  const handleNext = async () => {
    try {
      //const { result } = await getProviders();
      //setProviders(result);
      setDirection(1);
      if (step === 1 && transactionValue) {
        setStep(step + 1);
      } else if (step === 2 && provider) {
        handleOnClose();
      }
    } catch (error) {
      addErrorNotification(t("error_get-providers"));
      console.error("Error getting providers: ", error);
    }
  };

  const handlePrev = () => {
    setDirection(-1);
    setStep(step - 1);
    if (step - 1 === 1) {
      setProvider("");
    }
  };

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    setStep(1);
    setTransactionValue("0");
    setProvider("");
    onClose();
  };

  const handleSubmit = async () => {
    if (!playerData) return;
    if (transactionType === "deposit") {
      try {
        if (
          Number(transactionValue) <
          Number(process.env.REACT_APP_CASHBULLS_MIN_DEPOSIT)
        ) {
          addErrorNotification(
            t("error_min-deposit", {
              currency: currencyPrefix,
              amount: process.env.REACT_APP_CASHBULLS_MIN_DEPOSIT,
            })
          );
          return;
        }
        const {
          result: { transaction_id, payment_url },
        } = await createDeposit({
          amount: +transactionValue,
          currency: currencyPrefix === "$" ? "USD" : "EUR",
          description: "Deposit",
          player_id: playerData?.id || "",
          provider_id: process.env.REACT_APP_PAYMENT_PROVIDER_CASHBULLS || "",
          promotional_code: "",
        });
        sessionStorage.setItem(
          "currency",
          currencyPrefix === "$" ? "USD" : "EUR"
        );
        sessionStorage.setItem("value", transactionValue.toString());
        sessionStorage.setItem("transaction_id", transaction_id);
        sessionStorage.setItem(
          "items",
          JSON.stringify([
            { item_id: transactionType, item_name: playerData?.id },
          ])
        );
        sessionStorage.setItem("username", playerData?.nickname || "");
        // Add payment info event
        ReactGA.event("add_payment_info", {
          currency: "USD",
          value: Number(transactionValue),
          payment_type: "Cashbulls",
          items: [
            {
              item_id: transactionType,
              item_name: playerData?.id,
            },
          ],
        });
        // Open the external URL in a new window centered on the screen
        const width = 800;
        const height = 900;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        const newWindow = window.open(
          payment_url,
          "_blank",
          `rel=noreferrer,width=${width},height=${height},top=${top},left=${left}`
        );

        if (newWindow) {
          newWindow.onload = () => {
            // Scale the window's content to 90%
            newWindow.document.body.style.transform = "scale(0.9)";

            // Alternatively, if zoom doesn't work in some browsers:
            // newWindow.document.body.style.transformOrigin = "0 0";
          };
        }
      } catch (error: any) {
        sessionStorage.clear();
        addErrorNotification(t("error_create-deposit"));
        console.log("Error creating deposit: ", error);
      }
    } else {
      if (
        Number(transactionValue) <
        Number(process.env.REACT_APP_CASHBULLS_MIN_WITHDRAW)
      ) {
        addErrorNotification(
          t("error_min-withdrawal", {
            currency: currencyPrefix,
            amount: process.env.REACT_APP_CASHBULLS_MIN_WITHDRAW,
          })
        );
        return;
      }
      try {
        if (Number(balance) < Number(transactionValue)) {
          addErrorNotification(t("error_insufficient-balance"));
          return;
        }
        const result = await getBalances(playerData?.id || "");
        // Withdrawal
        // Store data in session storage
        sessionStorage.setItem("amount", transactionValue.toString());
        sessionStorage.setItem(
          "currency",
          currencyPrefix === "$" ? "USD" : "EUR"
        );
        sessionStorage.setItem("playerId", playerData?.id || "");
        sessionStorage.setItem("username", playerData?.nickname || "");
        sessionStorage.setItem(
          "providerId",
          process.env.REACT_APP_PAYMENT_PROVIDER_CASHBULLS || ""
        );
        sessionStorage.setItem(
          "walletId",
          result.find(
            (item) =>
              item.alphabetic_code === (currencyPrefix === "$" ? "USD" : "EUR")
          )?.wallet_id || ""
        );

        // Open the external URL in a new window centered on the screen
        const width = 800;
        const height = 900;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(
          "/withdrawal",
          "_blank",
          `rel=noreferrer,width=${width},height=${height},top=${top},left=${left}`
        );
      } catch (error: any) {
        addErrorNotification(t("error_get-balances"));
        console.error("Error getting balances: ", error);
      }
    }
  };

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "paymentStatus" && event.newValue === "success") {
        localStorage.removeItem("paymentStatus");
        setIsTransactionProcessing(true);
        setStep(3);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const targetRef = useRef<HTMLDivElement>(null);

  const handleScrollToElement = () => {
    targetRef.current?.scrollIntoView({
      behavior: "smooth", // Smooth scrolling animation
      block: "start", // Aligns the element to the top of the viewport
    });
  };

  useEffect(() => {
    if (isOpen) {
      handleScrollToElement();
    }
  }, [isOpen]);

  useEffect(() => {
    if (step === 1) {
      setIsTransactionProcessing(false);
    }
  }, [step]);

  useEffect(() => {
    if (step === 3 && isTransactionProcessing) {
      addInfoNotification(t("info_transaction-processing"));
      setIsTransactionProcessing(false);
    }
  }, [step]);

  return isOpen ? (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50"
      onClick={handleOverlayClick}
      ref={targetRef}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        className="bg-greyscale-100 px-6 pt-10 md:pb-28 pb-10 rounded-[20px] shadow-lg relative overflow-hidden max-w-3xl w-full md:h-[700px] h-screen"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between pb-12 items-center">
          <BackIcon
            className={`text-greyscale-300 cursor-pointer ${
              (step <= 1 || step === 3) && "hidden"
            }`}
            onClick={handlePrev}
          />
          <h1 className="text-[40px] leading-[48px] text-primary-400 mx-auto">
            {transactionType === "deposit" ? "Deposit" : "Withdrawal"}
          </h1>
          <CloseIcon
            className="text-greyscale-300 cursor-pointer"
            onClick={handleOnClose}
          />
        </div>
        <AnimatePresence custom={direction} initial={false}>
          {step === 1 && (
            <motion.div
              key="step1"
              custom={direction}
              variants={slideVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.3 }}
              className="absolute"
            >
              <div className="flex flex-col md:px-48 sm:px-36 gap-[34px] w-11/12">
                <div className="flex flex-col gap-2">
                  <p className="text-sm text-primary-400">
                    {t("text_transaction-modal_predefined-amounts")}{" "}
                    {transactionType === "deposit"
                      ? t("word_deposit")
                      : t("word_withdraw")}
                    .
                  </p>
                  {transactionType === "deposit" && (
                    <p className="text-sm text-primary-400 font-bold">
                      {t("text_transaction-modal_uk_deposit")}
                    </p>
                  )}
                </div>
                <DepositSelector
                  onConfirm={setTransactionValue}
                  currencyPrefix={currencyPrefix}
                />
                <div className="flex flex-col gap-2.5">
                  <h2 className="text-primary-400 text-xl leading-[26px]">
                    {transactionType === "deposit"
                      ? t("text_transaction-modal_deposit-amount")
                      : t("text_transaction-modal_available-balance")}
                  </h2>
                  <span className="text-primary-400 text-[40px] leading-[48px]">
                    {currencyPrefix}
                    {transactionType === "deposit" ? transactionValue : balance}
                  </span>
                </div>
                <div className="flex flex-col gap-2.5">
                  <p className="text-sm text-primary-400">
                    {t("text_transaction-modal_preferred-method")}{" "}
                    {transactionType === "deposit"
                      ? t("text_transaction-modal_payment-method")
                      : t("text_transaction-modal_withdrawal-method")}
                  </p>
                  <button
                    type="button"
                    className="p-2 rounded h-11 bg-primary-200 text-greyscale-100 hover:bg-tertiary-200 hover:text-greyscale-400 transition duration-300 flex items-center disabled:cursor-not-allowed disabled:opacity-50"
                    onClick={handleNext}
                    disabled={transactionValue.toString() === "0"}
                  >
                    <span className="mx-auto">{t("button_next")}</span>
                    <NextIcon />
                  </button>
                </div>
              </div>
            </motion.div>
          )}
          {step === 2 && (
            <motion.div
              key="step2"
              custom={direction}
              variants={slideVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.3 }}
              className="absolute"
            >
              <div className="flex flex-col md:px-48 sm:px-36 gap-[34px] w-11/12">
                <div className="flex flex-col gap-2">
                  <p className="text-sm text-primary-400">
                    {t("text_transaction-modal_choose-method")}
                  </p>
                  {transactionType === "deposit" && (
                    <p className="text-sm text-primary-400 font-bold">
                      {t("text_transaction-modal_uk_deposit")}
                    </p>
                  )}
                </div>
                <PaymentProviderSelector
                  onSelect={setProvider}
                  //providers={providers}
                />
                <div className="flex flex-col gap-2.5">
                  <h2 className="text-primary-400 text-xl leading-[26px]">
                    {transactionType === "deposit"
                      ? t("text_transaction-modal_deposit-to-account")
                      : t("text_transaction-modal_withdraw-from-account")}
                  </h2>
                  <span className="text-primary-400 text-[40px] leading-[48px]">
                    {currencyPrefix}
                    {transactionValue}
                  </span>
                </div>
                <div className="flex flex-col gap-2.5">
                  <p className="text-sm text-primary-400">
                    {t("text_transaction-modal_card-details")}{" "}
                    <strong>
                      {t("text_transaction-modal_security-reasons")}
                    </strong>
                  </p>
                  <button
                    type="submit"
                    className="p-2 rounded h-11 bg-primary-200 text-greyscale-100 hover:bg-tertiary-200 hover:text-greyscale-400 transition duration-300 flex items-center disabled:cursor-not-allowed disabled:opacity-50"
                    onClick={handleSubmit}
                    disabled={!provider}
                  >
                    <span className="mx-auto">{t("button_next")}</span>
                    <NextIcon />
                  </button>
                </div>
              </div>
            </motion.div>
          )}
          {step === 3 && (
            <motion.div
              key="step3"
              custom={direction}
              variants={slideVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.3 }}
              className="absolute"
            >
              <div className="flex flex-col md:px-48 sm:px-36 gap-[54px] justify-center items-center w-11/12">
                <CelebrateIcon
                  className={`mx-auto w-[85px] ${
                    transactionType === "deposit"
                      ? "text-secondary-400"
                      : "text-primary-400"
                  }`}
                />
                <div className="flex flex-col gap-16 justify-center">
                  <div className="flex flex-col gap-6 items-center text-center">
                    <h2 className="text-[40px] leading-[48px] text-secondary-400 self-center">
                      {transactionType === "deposit"
                        ? t("text_transaction-modal_deposit-complete")
                        : t("text_transaction-modal_withdrawal-complete")}
                    </h2>
                    <p className="text-xl leading-[26px] text-primary-400">
                      {transactionType === "deposit"
                        ? t("text_transaction-modal_thank-you-deposit")
                        : t("text_transaction-modal_thank-you-withdrawal")}
                    </p>
                  </div>
                  <Link
                    className={`p-2 rounded h-11 transition duration-300 flex items-center ${
                      transactionType === "deposit"
                        ? "bg-primary-200 text-greyscale-100 hover:bg-tertiary-200 hover:text-greyscale-400"
                        : "bg-secondary-200 text-greyscale-300 hover:bg-secondary-300 hover:text-greyscale-100"
                    }`}
                    to={"/games/favourites"}
                  >
                    <span className="mx-auto">{t("button_browse-games")}</span>
                    <NextIcon />
                  </Link>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  ) : null;
};

export default TransactionModal;
