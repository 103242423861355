import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import router from "./utils/routes";
import ReactGA from "react-ga4";
import "react-notifications-component/dist/theme.css";

const App: React.FC = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_ID as string);

  return (
    <AuthProvider>
      <RouterProvider router={router}></RouterProvider>
    </AuthProvider>
  );
};

export default App;
