import React from "react";
import { Button, ButtonProps } from "src/components/atoms";

export default function PurpleButton(props: ButtonProps) {
  const buttonProps = {
    ...props,
    className:
      props.className +
      " flex items-center py-2.5 xl:pl-5 pr-2.5 pl-2.5 gap-4 rounded " +
      " text-greyscale-100 bg-primary-400 hover:text-primary-400 " +
      " hover:bg-primary-100 transition duration-300 text-sm",
  };

  return <Button {...buttonProps} />;
}
