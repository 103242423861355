import { ReactComponent as LockIcon } from "src/assets/icons/security/Lock 1.svg";
import { useTranslation } from "react-i18next";

export default function ChangePasswordButton({
  onClick,
}: {
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={onClick}
      className="border border-primary-400 bg-transparent text-primary-400 text-sm flex py-2.5 px-6 gap-4 items-center rounded transition"
    >
      <span className="mx-auto">{t("text_change-pwd")}</span>
      <LockIcon className="text-right" />
    </button>
  );
}
