import {
  GetAllPaymentProviderResponse,
  PaymentDepositRequest,
  PaymentDepositResponse,
  PaymentWithdrawRequest,
  PaymentWithdrawResponse,
} from "src/types";
import { useAxiosInterceptors } from "./apiConfig";
import { isAxiosError } from "axios";
import { CustomError } from "src/errors/CustomError";
import useNotificationsService from "./notificationsService";

const usePaymentService = () => {
  const { axiosInstance } = useAxiosInterceptors();
  const { addErrorNotification } = useNotificationsService();

  // Create a new deposit
  const createDeposit = async (
    depositData: PaymentDepositRequest
  ): Promise<PaymentDepositResponse> => {
    try {
      const response = await axiosInstance.post("/deposit", depositData);
      return response.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        addErrorNotification(error.response?.data.error.description);
        throw new CustomError(
          "DepositError",
          error.response?.data.error.description
        );
      } else {
        throw new Error(error);
      }
    }
  };

  // Create a new withdrawal
  const createWithdrawal = async (
    withdrawalData: PaymentWithdrawRequest
  ): Promise<PaymentWithdrawResponse> => {
    try {
      const response = await axiosInstance.post("/withdraw", withdrawalData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Get a list of all integrated providers
  const getProviders = async (): Promise<GetAllPaymentProviderResponse> => {
    try {
      const response = await axiosInstance.get("/providers");
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return { createDeposit, createWithdrawal, getProviders };
};

export default usePaymentService;
