import styles from "./SingleSelect.module.scss";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SlidersIcon } from "src/assets/icons/settings/Sliders.svg";

export interface SingleSelectDropdownOptionProps {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface SingleSelectDropdownProps {
  options: SingleSelectDropdownOptionProps[];
  selectedOption: SingleSelectDropdownOptionProps;
  onChange: (selected: SingleSelectDropdownOptionProps) => void;
}

const SingleSelect: React.FC<SingleSelectDropdownProps> = ({
  options,
  selectedOption,
  onChange,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tempSelectedOption, setTempSelectedOption] =
    useState<SingleSelectDropdownOptionProps>(selectedOption);

  useEffect(() => {
    setTempSelectedOption(selectedOption);
  }, [selectedOption]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionChange = (option: SingleSelectDropdownOptionProps) => {
    setTempSelectedOption(option);
  };

  const handleConfirmSelection = () => {
    if (tempSelectedOption) {
      onChange(tempSelectedOption);
    }
    setIsOpen(false);
  };

  return (
    <div className={`${styles.SingleSelect} relative inline-block w-full`}>
      <button
        type="button"
        className="w-full flex md:gap-12 gap-4 px-6 py-3 border rounded-md text-greyscale-100 text-sm bg-[#FFD600] text-primary-400 border-[#FFD600]"
        onClick={toggleDropdown}
      >
        <p className="block">{selectedOption.label}</p>
        <SlidersIcon className="text-primary-400" />
      </button>
      {isOpen && (
        <div className="absolute w-full mt-1 bg-greyscale-100 border rounded-xl overflow-auto z-10 p-2.5">
          {options.map((option) => (
            <div
              className={
                "inputOption flex items-center text-sm m-1.5 cursor-pointer"
              }
            >
              <input
                type="radio"
                id={option.value}
                name="single-select"
                value={option.value}
                checked={
                  tempSelectedOption?.value === option.value && !option.disabled
                }
                onChange={() => handleOptionChange(option)}
                disabled={option.disabled}
                className="peer col-start-1 row-start-1 appearance-none shrink-0 w-6 h-6 m-4 border-2 border-primary-300 rounded-full cursor-pointer"
              />
              <label className="cursor-pointer" htmlFor={option.value}>
                {option.label}
              </label>
            </div>
          ))}
          <button
            type="submit"
            className="flex justify-center w-full items-center px-5 py-3.5 gap-4 bg-secondary-200 text-sm leading-6 text-greyscale-300 border border-secondary-200 rounded hover:bg-secondary-300 hover:border-secondary-300 hover:text-greyscale-100 group transition duration-300"
            onClick={handleConfirmSelection}
          >
            {t("button_confirm")}
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleSelect;
