export const BrotherBetsLogo = ({ textColor = "white" }) => {
    return (
        <>
            <svg
                width="222"
                height="28"
                viewBox="0 0 222 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_59_4236)">
                    <path
                        d="M34.3236 27.9869C30.3426 27.9869 27.3385 26.2269 26.048 23.2691C24.7575 26.2269 21.7533 27.9869 17.7723 27.9869H0.595779C0.265259 27.9869 0 27.7195 0 27.3911V0.595779C0 0.267364 0.267364 0 0.595779 0H17.7723C21.7533 0 24.7575 1.75997 26.048 4.71781C27.3385 1.76207 30.3426 0 34.3236 0H51.5002C51.8307 0 52.0959 0.267364 52.0959 0.595779V27.389C52.0959 27.7195 51.8286 27.9848 51.5002 27.9848H34.3236V27.9869ZM35.8962 16.5323C34.1973 16.5323 33.1426 17.4923 33.1426 19.0376C33.1426 20.366 34.172 21.2944 35.6478 21.2944H44.0834V16.5323H35.8983H35.8962ZM16.4502 21.2944C17.9239 21.2944 18.9555 20.366 18.9555 19.0376C18.9555 17.4923 17.9007 16.5323 16.2018 16.5323H8.0167V21.2944H16.4523H16.4502ZM23.5322 13.9934C24.5175 14.524 25.4438 15.3955 26.0501 16.6123C26.6564 15.3955 27.5806 14.524 28.5658 13.9934C27.5806 13.4629 26.6543 12.5893 26.0501 11.3724C25.4438 12.5893 24.5196 13.4629 23.5322 13.9934ZM35.6478 6.69252C34.1741 6.69252 33.1426 7.62092 33.1426 8.94932C33.1426 10.4946 34.1973 11.4545 35.8962 11.4545H44.0813V6.69252H35.6457H35.6478ZM16.2018 11.4545C17.9007 11.4545 18.9555 10.4946 18.9555 8.94932C18.9555 7.62092 17.926 6.69252 16.4502 6.69252H8.0146V11.4545H16.1997H16.2018Z"
                        fill="url(#paint0_linear_59_4236)"
                    />
                    <path
                        d="M34.3236 0.595703C29.7342 0.595703 26.6185 3.06724 26.048 6.88823C25.4774 3.06724 22.3617 0.595703 17.7723 0.595703H0.595764V13.9913V27.3889H17.7723C22.3617 27.3889 25.4774 24.9174 26.048 21.0964C26.6185 24.9174 29.7342 27.3889 34.3236 27.3889H51.5001V13.9934V0.595703H34.3236ZM16.4502 21.8901H7.4188V15.9344H16.2018C18.2291 15.9344 19.5512 17.1744 19.5512 19.0354C19.5512 20.7722 18.1849 21.888 16.4502 21.888V21.8901ZM16.1997 12.0523H7.4167V6.09666H16.4481C18.1828 6.09666 19.5491 7.21243 19.5491 8.94924C19.5491 10.8103 18.2249 12.0502 16.1997 12.0502V12.0523ZM26.0543 18.467L26.0437 18.4775C25.5006 15.5891 23.248 14.1744 21.6228 13.9934C23.248 13.8123 25.4985 12.3997 26.0437 9.51344L26.0564 9.52608C26.6058 12.4018 28.8521 13.8102 30.4731 13.9913C28.85 14.1723 26.6016 15.5828 26.0543 18.4649V18.467ZM44.6792 21.8901H35.6478C33.9131 21.8901 32.5468 20.7743 32.5468 19.0375C32.5468 17.1765 33.871 15.9365 35.8962 15.9365H44.6792V21.8922V21.8901ZM44.6792 12.0523H35.8962C33.8689 12.0523 32.5468 10.8124 32.5468 8.95135C32.5468 7.21454 33.9131 6.09877 35.6478 6.09877H44.6792V12.0545V12.0523Z"
                        fill="url(#paint1_linear_59_4236)"
                    />
                </g>
                <g clipPath="url(#clip1_59_4236)">
                    <path
                        d="M144.732 23.3826L145.164 23.4878C144.77 24.5886 143.729 25.3024 142.399 25.3024C140.701 25.3024 139.364 23.9649 139.364 22.2556C139.364 20.5462 140.701 19.2087 142.399 19.2087C143.729 19.2087 144.766 19.9225 145.164 20.997L144.744 21.1285C144.398 20.2043 143.526 19.622 142.403 19.622C140.949 19.622 139.807 20.7754 139.807 22.2556C139.807 23.7358 140.953 24.8891 142.403 24.8891C143.523 24.8891 144.383 24.3068 144.736 23.3826H144.732Z"
                        fill={textColor}
                    />
                    <path
                        d="M149.879 22.4248V25.2048H149.466V24.3595C149.132 24.9156 148.497 25.3025 147.685 25.3025C146.754 25.3025 146.153 24.7728 146.153 24.044C146.153 23.2701 146.742 22.7065 147.903 22.7065L149.462 22.714V22.4398C149.462 21.6396 148.944 21.1812 148.166 21.1812C147.445 21.1812 146.908 21.5682 146.803 22.1655L146.397 22.0603C146.521 21.3127 147.223 20.7905 148.148 20.7905C149.188 20.7905 149.872 21.3804 149.872 22.421L149.879 22.4248ZM149.466 23.3828V23.0672H147.9C147.02 23.0672 146.57 23.4804 146.57 24.0365C146.57 24.5925 147.028 24.9419 147.734 24.9419C148.692 24.9419 149.47 24.2656 149.47 23.3828H149.466Z"
                        fill={textColor}
                    />
                    <path
                        d="M151.423 23.9201C151.484 24.5625 152.085 24.9419 152.885 24.9419C153.606 24.9419 154.098 24.6151 154.098 24.0628C154.098 23.6045 153.745 23.4204 153.279 23.3152L152.224 23.086C151.536 22.9358 151.16 22.6202 151.16 22.0379C151.16 21.2978 151.803 20.7981 152.735 20.7981C153.666 20.7981 154.267 21.2902 154.425 21.959L154.031 22.0642C153.914 21.5194 153.441 21.1588 152.727 21.1588C152.013 21.1588 151.592 21.4931 151.592 22.0116C151.592 22.4624 151.919 22.6277 152.385 22.7329L153.426 22.962C153.989 23.0935 154.527 23.349 154.527 24.0365C154.527 24.8367 153.85 25.3026 152.862 25.3026C151.874 25.3026 151.119 24.8367 151.006 24.0252L151.427 23.9201H151.423Z"
                        fill={textColor}
                    />
                    <path
                        d="M156.469 19.5883C156.469 19.7724 156.326 19.9151 156.134 19.9151C155.943 19.9151 155.792 19.7724 155.792 19.5883C155.792 19.4042 155.931 19.2539 156.134 19.2539C156.337 19.2539 156.469 19.4042 156.469 19.5883ZM155.924 25.2085V20.9032H156.345V25.2085H155.924Z"
                        fill={textColor}
                    />
                    <path
                        d="M162.063 22.6463V25.2085H161.631V22.7064C161.631 21.801 161.086 21.1999 160.275 21.1999C159.245 21.1999 158.558 21.895 158.558 23.0483V25.2047H158.126V20.8994H158.558V21.8161C158.892 21.1736 159.508 20.7942 160.327 20.7942C161.364 20.7942 162.063 21.5418 162.063 22.6426V22.6463Z"
                        fill={textColor}
                    />
                    <path
                        d="M167.77 23.0483C167.77 24.3519 166.781 25.3024 165.508 25.3024C164.234 25.3024 163.246 24.3519 163.246 23.0483C163.246 21.7446 164.231 20.8054 165.508 20.8054C166.785 20.8054 167.77 21.7484 167.77 23.0483ZM167.345 23.0483C167.345 21.955 166.534 21.1811 165.504 21.1811C164.475 21.1811 163.663 21.955 163.663 23.0483C163.663 24.1415 164.475 24.9154 165.504 24.9154C166.534 24.9154 167.345 24.1302 167.345 23.0483Z"
                        fill={textColor}
                    />
                    <path
                        d="M127.782 24.4158C127.304 25.0056 126.583 25.3062 125.809 25.3062C124.551 25.3062 123.705 24.6375 123.705 23.6081C123.705 22.8342 124.235 22.2519 124.892 21.9513C124.513 21.5193 124.363 21.1511 124.363 20.7115C124.363 19.8212 125.039 19.2163 125.982 19.2163C126.872 19.2163 127.515 19.746 127.62 20.6965L127.226 20.7829C127.139 19.9301 126.636 19.5845 125.974 19.5845C125.279 19.5845 124.787 20.0353 124.787 20.7115C124.787 21.3878 125.272 21.8987 126.328 22.7365L127.691 23.856C127.875 23.5142 127.981 23.0972 127.981 22.605V22.2519H128.368V22.605C128.368 23.2023 128.236 23.6982 127.999 24.1002L129.153 25.0432L128.909 25.3438L127.782 24.4196V24.4158ZM127.485 24.1679L126.016 22.9619C125.663 22.6877 125.381 22.4435 125.152 22.2218C124.517 22.5036 124.13 22.9882 124.13 23.6043C124.13 24.4045 124.799 24.9343 125.813 24.9343C126.455 24.9343 127.071 24.6713 127.485 24.1679Z"
                        fill={textColor}
                    />
                    <path
                        d="M69.5051 20.7868L69.0655 20.892C68.9152 20.1068 68.2728 19.5958 67.3411 19.5958C66.462 19.5958 65.8158 20.073 65.8158 20.8281C65.8158 21.4705 66.2554 21.7185 66.864 21.8575L68.1601 22.1655C68.9002 22.3233 69.6028 22.6577 69.6028 23.6195C69.6028 24.6488 68.765 25.2988 67.4989 25.2988C66.2328 25.2988 65.2636 24.6376 65.1321 23.5481L65.6092 23.4429C65.7144 24.3596 66.4996 24.9043 67.5365 24.9043C68.5734 24.9043 69.1632 24.4009 69.1632 23.6345C69.1632 22.9733 68.7838 22.7366 68.0624 22.5713L66.6987 22.2557C65.9135 22.0716 65.3425 21.6847 65.3425 20.8394C65.3425 19.8438 66.169 19.2126 67.3561 19.2126C68.5433 19.2126 69.3097 19.8626 69.5051 20.7868Z"
                        fill={textColor}
                    />
                    <path
                        d="M75.4033 23.0485C75.4033 24.4235 74.4077 25.3026 73.2281 25.3026C72.4278 25.3026 71.7403 24.9156 71.3722 24.2657V27.32H70.9401V20.9033H71.3722V21.8463C71.7403 21.1851 72.4278 20.7981 73.2281 20.7981C74.4077 20.7981 75.4033 21.6885 75.4033 23.0522V23.0485ZM74.9825 23.0485C74.9825 21.9026 74.1635 21.1813 73.1792 21.1813C72.255 21.1813 71.3759 21.9026 71.3759 23.0485C71.3759 24.1943 72.255 24.9156 73.1792 24.9156C74.1635 24.9156 74.9825 24.2018 74.9825 23.0485Z"
                        fill={textColor}
                    />
                    <path
                        d="M80.7944 23.0483C80.7944 24.3519 79.8101 25.3024 78.5328 25.3024C77.2554 25.3024 76.2711 24.3519 76.2711 23.0483C76.2711 21.7446 77.2554 20.8054 78.5328 20.8054C79.8101 20.8054 80.7944 21.7484 80.7944 23.0483ZM80.3736 23.0483C80.3736 21.955 79.5621 21.1811 78.5328 21.1811C77.5034 21.1811 76.6919 21.955 76.6919 23.0483C76.6919 24.1415 77.5034 24.9154 78.5328 24.9154C79.5621 24.9154 80.3736 24.1302 80.3736 23.0483Z"
                        fill={textColor}
                    />
                    <path
                        d="M84.6451 20.8918L84.6 21.2975C84.5099 21.2787 84.4235 21.2787 84.3446 21.2787C83.1574 21.2787 82.515 22.263 82.515 23.4089V25.2047H82.0829V20.8993H82.515V22.1052C82.8569 21.3914 83.4918 20.8655 84.3446 20.8655C84.4423 20.8655 84.5212 20.8655 84.6451 20.8918Z"
                        fill={textColor}
                    />
                    <path
                        d="M88.0451 24.9606C87.7371 25.1973 87.4553 25.3025 87.1022 25.3025C86.3357 25.3025 85.8173 24.8179 85.8173 23.9914V21.2714H84.8743V20.9032H85.8173V19.3103H86.2493V20.9032H87.861V21.2714H86.2493V23.9651C86.2493 24.5474 86.6025 24.8968 87.1284 24.8968C87.4027 24.8968 87.6582 24.7916 87.8761 24.6075L88.0451 24.9606Z"
                        fill={textColor}
                    />
                    <path
                        d="M89.0595 23.9201C89.1196 24.5625 89.7207 24.9419 90.5209 24.9419C91.2422 24.9419 91.7344 24.6151 91.7344 24.0628C91.7344 23.6045 91.3812 23.4204 90.9154 23.3152L89.8597 23.086C89.1722 22.9358 88.7965 22.6202 88.7965 22.0379C88.7965 21.2978 89.4389 20.7981 90.3706 20.7981C91.3023 20.7981 91.9034 21.2902 92.0612 21.959L91.663 22.0642C91.5503 21.5194 91.0732 21.1588 90.3594 21.1588C89.6455 21.1588 89.2248 21.4931 89.2248 22.0116C89.2248 22.4624 89.5516 22.6277 90.0175 22.7329L91.0581 22.962C91.6217 23.0935 92.1589 23.349 92.1589 24.0365C92.1589 24.8367 91.4827 25.3026 90.4946 25.3026C89.5065 25.3026 88.7514 24.8367 88.6349 24.0252L89.0595 23.9201Z"
                        fill={textColor}
                    />
                    <path
                        d="M97.9369 23.0484C97.9369 24.4234 96.9413 25.3026 95.7617 25.3026C94.9614 25.3026 94.2739 24.9156 93.902 24.2657V25.2086H93.47V18.4275H93.902V21.8425C94.2702 21.1813 94.9577 20.7943 95.7617 20.7943C96.9413 20.7943 97.9369 21.6847 97.9369 23.0484ZM97.5124 23.0484C97.5124 21.9026 96.6934 21.1813 95.7091 21.1813C94.7849 21.1813 93.9058 21.9026 93.9058 23.0484C93.9058 24.1943 94.7849 24.9156 95.7091 24.9156C96.6971 24.9156 97.5124 24.2018 97.5124 23.0484Z"
                        fill={textColor}
                    />
                    <path
                        d="M103.328 23.0483C103.328 24.3519 102.34 25.3024 101.066 25.3024C99.7928 25.3024 98.8047 24.3519 98.8047 23.0483C98.8047 21.7446 99.789 20.8054 101.066 20.8054C102.344 20.8054 103.328 21.7484 103.328 23.0483ZM102.903 23.0483C102.903 21.955 102.096 21.1811 101.063 21.1811C100.029 21.1811 99.2218 21.955 99.2218 23.0483C99.2218 24.1415 100.033 24.9154 101.063 24.9154C102.092 24.9154 102.903 24.1302 102.903 23.0483Z"
                        fill={textColor}
                    />
                    <path
                        d="M108.719 23.0483C108.719 24.3519 107.735 25.3024 106.458 25.3024C105.18 25.3024 104.196 24.3519 104.196 23.0483C104.196 21.7446 105.18 20.8054 106.458 20.8054C107.735 20.8054 108.719 21.7484 108.719 23.0483ZM108.295 23.0483C108.295 21.955 107.483 21.1811 106.454 21.1811C105.424 21.1811 104.613 21.955 104.613 23.0483C104.613 24.1415 105.424 24.9154 106.454 24.9154C107.483 24.9154 108.295 24.1302 108.295 23.0483Z"
                        fill={textColor}
                    />
                    <path
                        d="M113.667 25.2086L111.623 22.9808L110.425 24.2131V25.2086H109.993V18.4275H110.425V23.6232L113.137 20.9033H113.667L111.916 22.6915L114.215 25.2086H113.671H113.667Z"
                        fill={textColor}
                    />
                    <path
                        d="M73.4535 7.22813C75.0389 7.41973 76.305 8.64447 76.305 10.5868C76.305 12.8259 74.7609 14.4338 71.9094 14.4338H65.1283V0.27417H71.6389C74.3026 0.27417 75.8429 1.77316 75.8429 3.99347C75.8429 5.78925 74.682 6.9952 73.4535 7.16426V7.22813ZM66.7963 6.61576H71.6126C73.198 6.61576 74.2537 5.6014 74.2537 4.07987C74.2537 2.66354 73.1755 1.71305 71.4849 1.71305H66.7963V6.61576ZM71.7629 12.9949C73.5362 12.9949 74.6144 11.958 74.6144 10.4365C74.6144 8.91496 73.431 7.96447 71.7817 7.96447H66.7963V12.9949H71.7629Z"
                        fill={textColor}
                    />
                    <path
                        d="M88.9319 14.4338L83.522 8.37022H80.9223V14.4338H79.2542V0.27417H85.8062C88.575 0.27417 90.4346 1.88211 90.4346 4.30904C90.4346 6.73598 88.575 8.36646 85.8062 8.36646H85.5507L91.0245 14.43H88.9319V14.4338ZM85.8024 6.84493C87.5757 6.84493 88.7628 5.83057 88.7628 4.30904C88.7628 2.78751 87.5794 1.77316 85.8024 1.77316H80.9223V6.84493H85.8024Z"
                        fill={textColor}
                    />
                    <path
                        d="M107.153 7.35219C107.153 11.4735 103.94 14.7044 99.8417 14.7044C95.743 14.7044 92.5083 11.4697 92.5083 7.35219C92.5083 3.23466 95.7204 0 99.8417 0C103.963 0 107.153 3.25345 107.153 7.35219ZM105.462 7.35219C105.462 4.09874 102.99 1.56286 99.8417 1.56286C96.6935 1.56286 94.1989 4.09874 94.1989 7.35219C94.1989 10.6056 96.6709 13.119 99.8417 13.119C103.012 13.119 105.462 10.6244 105.462 7.35219Z"
                        fill={textColor}
                    />
                    <path
                        d="M115.17 1.77316V14.43H113.479V1.77316H108.216V0.27417H120.41V1.77316H115.17Z"
                        fill={textColor}
                    />
                    <path
                        d="M134.638 14.4338H132.97V8.05088H124.475V14.4338H122.807V0.27417H124.475V6.55189H132.97V0.27417H134.638V14.4338Z"
                        fill={textColor}
                    />
                    <path
                        d="M148.7 14.4338H138.368V0.27417H148.681V1.77316H140.04V6.57068H148.069V8.06967H140.04V12.9085H148.703V14.43L148.7 14.4338Z"
                        fill={textColor}
                    />
                    <path
                        d="M161.511 14.4338L156.101 8.37022H153.501V14.4338H151.833V0.27417H158.385C161.154 0.27417 163.013 1.88211 163.013 4.30904C163.013 6.73598 161.154 8.36646 158.385 8.36646H158.13L163.603 14.43H161.511V14.4338ZM158.385 6.84493C160.158 6.84493 161.345 5.83057 161.345 4.30904C161.345 2.78751 160.162 1.77316 158.385 1.77316H153.505V6.84493H158.385Z"
                        fill={textColor}
                    />
                    <path
                        d="M179.652 7.22813C181.238 7.41973 182.504 8.64447 182.504 10.5868C182.504 12.8259 180.96 14.4338 178.108 14.4338H171.327V0.27417H177.834C180.498 0.27417 182.038 1.77316 182.038 3.99347C182.038 5.78925 180.877 6.9952 179.649 7.16426V7.22813H179.652ZM172.999 6.61576H177.815C179.401 6.61576 180.456 5.6014 180.456 4.07987C180.456 2.66354 179.378 1.71305 177.688 1.71305H172.995V6.61576H172.999ZM177.962 12.9949C179.735 12.9949 180.813 11.958 180.813 10.4365C180.813 8.91496 179.63 7.96447 177.981 7.96447H172.995V12.9949H177.962Z"
                        fill={textColor}
                    />
                    <path
                        d="M195.784 14.4338H185.453V0.27417H195.766V1.77316H187.125V6.57068H195.153V8.06967H187.125V12.9085H195.788V14.43L195.784 14.4338Z"
                        fill={textColor}
                    />
                    <path
                        d="M204.538 1.77316V14.43H202.847V1.77316H197.584V0.27417H209.779V1.77316H204.538Z"
                        fill={textColor}
                    />
                    <path
                        d="M221.767 3.8883L220.099 4.26774C219.783 2.55837 218.367 1.45761 216.421 1.45761C214.475 1.45761 213.209 2.49075 213.209 4.01603C213.209 5.2821 214.013 5.85314 215.343 6.12739L218.427 6.82241C220.392 7.24694 222 8.23875 222 10.5004C222 13.0776 219.907 14.7043 216.736 14.7043C213.735 14.7043 211.327 13.1602 210.988 10.4778L212.743 10.1172C213.058 12.1046 214.689 13.2241 216.883 13.2241C219.077 13.2241 220.328 12.1459 220.328 10.583C220.328 9.25311 219.61 8.72339 218.025 8.36273L214.835 7.66395C213.017 7.26197 211.432 6.31148 211.432 4.04985C211.432 1.61915 213.438 -0.00756836 216.462 -0.00756836C219.295 -0.00756836 221.278 1.55529 221.767 3.88079V3.8883Z"
                        fill={textColor}
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_59_4236"
                        x1="26.0943"
                        y1="29.09"
                        x2="26.0017"
                        y2="-1.05893"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E9C156" />
                        <stop offset="0.18" stopColor="#FFFFAA" />
                        <stop offset="0.65" stopColor="#E1AB39" />
                        <stop offset="0.91" stopColor="#633110" />
                        <stop offset="0.99" stopColor="#E9C156" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_59_4236"
                        x1="26.048"
                        y1="-1.22742"
                        x2="26.048"
                        y2="30.0584"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E9C156" />
                        <stop offset="0.18" stopColor="#FFFFAA" />
                        <stop offset="0.65" stopColor="#E1AB39" />
                        <stop offset="0.91" stopColor="#633110" />
                        <stop offset="0.99" stopColor="#E9C156" />
                    </linearGradient>
                    <clipPath id="clip0_59_4236">
                        <rect
                            width="52.0959"
                            height="27.9869"
                            fill={textColor}
                        />
                    </clipPath>
                    <clipPath id="clip1_59_4236">
                        <rect
                            width="156.872"
                            height="27.3199"
                            fill={textColor}
                            transform="translate(65.1282)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default BrotherBetsLogo;
