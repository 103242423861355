import { useEffect, useRef, useState } from "react";
import jsonCountries from "src/utils/countries.json";
import { ReactComponent as CaretDownIcon } from "src/assets/icons/arrows/Chevron Up.svg";
import { useTranslation } from "react-i18next";

const countriesList = jsonCountries;

type CountryJsonObject = (typeof jsonCountries)[number];

type Currency = {
  code: string;
  name: string;
  symbol: string;
};

export interface ICountry {
  name: string;
  code: string;
  code2: string;
  callingCodes: Array<string>;
  capital: string;
  region: string;
  citizen: string;
  flagUrl: string;
  currencies: Array<Currency>;
  timezones: Array<string>;
  latlng: Array<number>;
}

interface Props {
  defaultCountry: string;
  onSelect: (country: ICountry) => void;
}

function CountrySelector({ defaultCountry, onSelect }: Props) {
  const { t } = useTranslation();
  const [countries, setCountries] = useState(countriesList);
  const [currentCountry, setCurrentCountry] = useState<CountryJsonObject>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const setDefaultCountry = (d: string) => {
    const thisCountry = countries.filter(
      (country) => country.alpha2Code.toLowerCase() === d.toLowerCase()
    );
    setCurrentCountry(thisCountry[0]);
  };

  const toggleDropDown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCountryClick = (country: CountryJsonObject) => {
    const result: ICountry = {
      name: country?.name,
      code: country?.alpha2Code,
      code2: country?.alpha3Code,
      capital: country?.capital || "",
      region: country?.region,
      citizen: country?.demonym,
      flagUrl: country?.flag,
      callingCodes: country?.callingCodes || [],
      currencies: country?.currencies || [],
      timezones: country?.timezones || [],
      latlng: country?.latlng || [],
    };
    setCurrentCountry(country);

    onSelect(result);

    /* Hide the dropdown menu on selecting a country */
    toggleDropDown();
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.toLowerCase().trim();
    if (input === "") {
      setCountries(countriesList);
      return;
    }
    const filteredCountries = countries.filter((i) => {
      return i.name.toLowerCase().startsWith(input);
    });
    setCountries(filteredCountries);
  };

  const handleClickOutSide = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current?.contains(e.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  // Always reset the countries list when the dropdown is closed
  useEffect(() => {
    if (!isDropdownOpen) {
      setCountries(countriesList);
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    setDefaultCountry(defaultCountry);
    document.addEventListener("mousedown", handleClickOutSide);
  }, [defaultCountry]);

  return (
    <div
      className="relative w-full bg-none flex flex-col gap-1"
      ref={dropdownRef}
    >
      <label
        htmlFor="country"
        className="absolute left-4 -top-1.5 text-[10px] bg-greyscale-100 px-1 z-10"
      >
        {t("text_country")}
      </label>
      <div
        className="w-full h-14 appearance-none border bg-transparent border-primary-400 rounded px-4 text-sm text-primary-400 flex items-center justify-between gap-2"
        onClick={toggleDropDown}
      >
        {/* <img
          className="w-6 h-4 rounded-sm"
          src={currentCountry?.flag}
          alt={currentCountry?.name}
        /> */}
        <span className="truncate">{currentCountry?.name}</span>
        <CaretDownIcon className={`${isDropdownOpen && "rotate-180"}`} />
      </div>

      {isDropdownOpen && (
        <div className="absolute mt-16 bg-greyscale-100 z-10 rounded-[10px] shadow-2xl text-sm text-primary-400 lg:w-60 mx-auto">
          <div className="pt-2.5 relative text-center p-2">
            <input
              onChange={(e) => handleSearchInput(e)}
              className="outline-none border rounded-sm border-primary-400 p-2 w-full bg-greyscale-200/10 text-primary-400"
              type="text"
              placeholder={t("placeholder_country")}
              autoFocus={true}
            />
          </div>

          <div className="p-2.5 lg:max-h-52 max-h-32 overflow-y-scroll">
            {countries.map((c) => {
              return (
                <div
                  key={c.name}
                  onClick={() => handleCountryClick(c)}
                  className="flex items-center gap-2 p-2 hover:bg-greyscale-200/10 cursor-pointer transition duration-300"
                >
                  <img className="w-6 h-4 rounded-sm" src={c.flag} alt="" />
                  <span className="text-sm text-primary-400"> {c.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default CountrySelector;
