import { ReactComponent as SkeletonNavBar } from "src/assets/icons/loaders/tabs_filterGames.svg";
import { NavBar } from "src/components/molecules";
import { Link, useParams } from "react-router-dom";
import { NavLink } from "src/components/atoms";
import { ReactComponent as HotGamesIcon } from "src/assets/icons/nav/hotGames.svg";
import { ReactComponent as NewGamesIcon } from "src/assets/icons/nav/newGames.svg";
import { ReactComponent as CasinoGamesIcon } from "src/assets/icons/nav/casinoGames.svg";
import { ReactComponent as PokerGamesIcon } from "src/assets/icons/nav/pokerGames.svg";
import { ReactComponent as SlotsColouredIcon } from "src/assets/icons/nav/slotsGames.svg";
import { ReactComponent as FavouritesIcon } from "src/assets/icons/nav/favourites.svg";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/context/AuthContext";

export default function GamesSidebar({ isLoading }: { isLoading?: boolean }) {
  const { t } = useTranslation();
  const { playerData, token } = useAuth();
  const params = useParams<{ gameTypeId?: string; gameId?: string }>();

  if (isLoading) {
    return <SkeletonNavBar />;
  }

  // if none is selected, all buttons are highlighted
  const item = params.gameTypeId;

  return (
    <NavBar
      orientation="vertical"
      className="justify-center items-center text-primary-400 gap-3.5 text-base sticky top-32 left-0 shadow-md"
    >
      <Link to={"/games/hot"}>
        <NavLink
          className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            !item || item === "hot" ? "opacity-100" : "opacity-30"
          }`}
        >
          <HotGamesIcon />
          {t("link_hot-games")}
        </NavLink>
      </Link>
      <Link to={"/games/new"}>
        <NavLink
          className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            !item || item === "new" ? "opacity-100" : "opacity-30"
          }`}
        >
          <NewGamesIcon />
          {t("link_new-games")}
        </NavLink>
      </Link>
      <Link to={"/games/casino"}>
        <NavLink
          className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            !item || item === "casino" ? "opacity-100" : "opacity-30"
          }`}
        >
          <CasinoGamesIcon />
          {t("link_casino-games")}
        </NavLink>
      </Link>
      <Link to={"/games/poker"}>
        <NavLink
          className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            !item || item === "poker" ? "opacity-100" : "opacity-30"
          }`}
        >
          <PokerGamesIcon />
          {t("link_poker-games")}
        </NavLink>
      </Link>
      <Link to={"/games/slots"}>
        <NavLink
          className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            !item || item === "slots" ? "opacity-100" : "opacity-30"
          }`}
        >
          <SlotsColouredIcon />
          {t("button_header_slots")}
        </NavLink>
      </Link>
      {/* <Link to={"/games/live"}>
        <NavLink className="flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px]">
        <LiveNowIcon />
        {t("link_live-now")}!
        </NavLink>
      </Link> */}
      <Link to={playerData && token ? "/games/favourites" : "/login"}>
        <NavLink
          className={`flex gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-[220px] ${
            !item || item === "favourites" ? "opacity-100" : "opacity-30"
          }`}
        >
          <FavouritesIcon />
          {t("link_favourites")}
        </NavLink>
      </Link>
    </NavBar>
  );
}
