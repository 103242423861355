import { useEffect, useState } from "react";
import { PeopleIcon, SimpleFlameIcon } from "src/assets/svg";
import { ReactComponent as DiceIcon } from "src/assets/icons/nav/Dice.svg";
import { ReactComponent as ChipIcon } from "src/assets/icons/nav/Chip.svg";
import { ReactComponent as ControllerIcon } from "src/assets/icons/nav/Controller.svg";
import { ReactComponent as SlotsIcon } from "src/assets/icons/nav/Slots.svg";
import { useTranslation } from "react-i18next";
import { GameResponse } from "src/types";
import { FavouriteButton } from "src/components/molecules";

export interface GameCardProps {
  className?: string;
  game: GameResponse;
  isHot?: boolean;
  playersOnline?: number;
  onClickFavourite?: () => void;
  onSelectGame?: () => void;
  description?: string;
}

const GameCard = ({
  game,
  className = "",
  isHot = false,
  playersOnline = 0,
  onSelectGame,
}: GameCardProps) => {
  const { t } = useTranslation();
  const [imageError, setImageError] = useState(false);

  // onError never resets, so clear this flag when react decides to update
  // a component instead of doing a complete re-instancing on section change
  useEffect(() => setImageError(false), [game.image]);

  return (
    <div
      className={`${className} pt-[14px] w-[232px] relative`}
      id={game.id}
      onClick={onSelectGame}
    >
      <div
        className={`rounded-2xl bg-[#36073B] ${
          isHot &&
          "[border:double_1px_transparent] [background-image:linear-gradient(#36073B,#36073B),linear-gradient(to_bottom_right,#C543D2,#65236C)] [background-origin:border-box] [background-clip:content-box,border-box]"
        }`}
      >
        <div className="flex flex-col p-[14px] gap-[14px]">
          {isHot && (
            <div className="flex flex-row absolute bg-[#EA2224] rounded-md border border-[#A41A1C] top-0 right-5 px-[10px] py-[6px] gap-[5px]">
              <span className="text-white">{t("text_hot-game")}</span>
              <SimpleFlameIcon />
            </div>
          )}
          {imageError ? (
            <div className="grid grid-cols-2 grid-rows-2 gap-1 place-items-center w-[204px] h-[204px] text-greyscale-100">
              <DiceIcon className="w-7/12 h-full" />
              <ChipIcon className="w-7/12 h-full" />
              <ControllerIcon className="w-7/12 h-full" />
              <SlotsIcon className="w-7/12 h-full" />
            </div>
          ) : (
            <img
              src={game.image}
              alt=""
              className="rounded-10 cursor-pointer"
              onError={() => {
                setImageError(true);
              }}
            />
          )}
          <div className="flex flex-col justify-between min-h-[4.5em]">
            <span className="text-white font-semibold text-base">
              {game.name}
            </span>
            <div className="flex flex-row justify-between self-end">
              {playersOnline ? (
                <div className="flex flex-row gap-[10px] items-center">
                  <PeopleIcon />
                  <span className="text-[#05FF00] text-[10px]">
                    {playersOnline} players online
                  </span>
                </div>
              ) : (
                <></>
              )}
              <FavouriteButton game={game} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
