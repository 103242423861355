import { useNavigate } from "react-router-dom";
import { textThemeEnum } from "../inputFields/inputDepositLimit/InputDepositLimit.component";
import { useTranslation } from "react-i18next";

export interface RegisterInsteadProps {
  theme?: textThemeEnum;
}

const RegisterInstead = ({
  theme = textThemeEnum.black,
}: RegisterInsteadProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={`flex justify-center items-center ${
        theme === textThemeEnum.black
          ? "text-primary-400"
          : "text-greyscale-100"
      }`}
    >
      <span>
        {t("text_dont-have-account")}{" "}
        <span
          onClick={() => navigate("/register")}
          className="cursor-pointer text-[#1ACAE1] hover:text-primary-400 transition duration-300"
        >
          {t("button_register")}
        </span>
      </span>
    </div>
  );
};

export default RegisterInstead;
