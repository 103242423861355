import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/context/AuthContext";
import useNotificationsService from "src/services/notificationsService";
import useTransactionService from "src/services/transactionService";
import { Currency } from "src/types";
import { currencies } from "src/utils/currencyFunctions";

interface PreferredWallet {
  currency_code: string;
  amount: number;
  bonus: number;
}

interface Transaction {
  dateTime: Date;
  method: string;
  value: number;
  status: string;
  currency: string;
}

export const AccountProfileStats = () => {
  const { t } = useTranslation();
  const { playerData } = useAuth();
  const { getBalances, getTransactions } = useTransactionService();
  const { addErrorNotification } = useNotificationsService();
  const [preferredWallet, setPreferredWallet] = useState<PreferredWallet>({
    currency_code: "",
    amount: 0,
    bonus: 0,
  });
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [currencyInfo] = useState<Currency>(
    currencies.find((currency) => currency.shortName === "EUR") || currencies[2]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      if (playerData?.id) {
        try {
          const wallets = await getBalances(playerData.id);
          const preferredWallet = wallets.find(
            (wallet) => wallet.currency_id === playerData.currency_id
          );
          if (!preferredWallet) {
            addErrorNotification("Error fidding preferred wallet");
            return;
          }
          setPreferredWallet({
            currency_code: preferredWallet.alphabetic_code,
            amount: preferredWallet.amount,
            bonus: preferredWallet.bonus,
          });
        } catch (error) {
          addErrorNotification("Error getting wallets");
          console.error("Error getting wallets: ", error);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!playerData?.id) return;
      try {
        const result = await getTransactions(playerData.id);
        setTransactions(
          result.map((transaction) => ({
            dateTime: new Date(transaction.transaction_date),
            method: transaction.type,
            value: transaction.amount,
            status: transaction.status,
            currency: transaction.currency,
          }))
        );
      } catch (error) {
        addErrorNotification("Error getting transactions");
        console.error("Error getting transactions: ", error);
      }
    })();
  }, []);

  return (
    <div className="border-2 rounded-10 border-primary-400 p-6">
      <div className="flex justify-between items-center mb-8">
        <div className="text-xl text-primary-400 font-bold min-w-20">
          {t("text_my-stats")}
        </div>
        {/* <div className="flex gap-2.5">
          {dummyData.map((item, index) => (
            <div
              key={`statsMenu${index}`}
              className={`${
                item.id === selectedOption
                  ? "bg-primary-400 text-greyscale-100"
                  : "bg-greyscale-100 text-primary-400 border border-primary-400"
              } text-xs text-center p-2.5 cursor-pointer select-none rounded-[5px]`}
              onClick={() => setSelectedOption(item.id)}
            >
              {item.label}
            </div>
          ))}
        </div> */}
      </div>
      <div className="flex items-start justify-between">
        <div className="flex flex-col items-center text-center gap-2.5">
          <div className="text-2xl font-bold text-secondary-400 max-[1790px]:text-lg">
            {currencyInfo.symbol}
            {typeof preferredWallet.amount === "number"
              ? preferredWallet.amount.toFixed(currencyInfo.decimalPlaces)
              : parseFloat(preferredWallet.amount).toFixed(
                  currencyInfo.decimalPlaces
                )}
          </div>
          <div className="text-sm text-greyscale-200">
            {t("text_overall-earnings")}
          </div>
        </div>
        <div className="h-12 mx-3 w-[1px] bg-primary-100" />
        <div className="flex flex-col items-center text-center gap-2.5">
          <div className="text-2xl font-bold text-secondary-400 max-[1790px]:text-lg">
            {currencyInfo.symbol}
            {transactions
              .filter((transaction) => transaction.method === "win")
              .reduce((sum, curr) => sum + curr.value, 0)
              .toFixed(currencyInfo.decimalPlaces)}
          </div>
          <div className="text-sm text-greyscale-200">{t("text_winnings")}</div>
        </div>
        <div className="h-12 mx-3 w-[1px] bg-primary-100" />
        <div className="flex flex-col items-center text-center gap-2.5">
          <div className="text-2xl font-bold text-secondary-400 max-[1790px]:text-lg">
            {currencyInfo.symbol}0.00
          </div>
          <div className="text-sm text-greyscale-200">
            {t("text_referral-earnings")}
          </div>
        </div>
        <div className="h-12 mx-3 w-[1px] bg-primary-100" />
        <div className="flex flex-col items-center text-center gap-2.5">
          <div className="text-2xl font-bold text-secondary-400 max-[1790px]:text-lg">
            {currencyInfo.symbol}
            {typeof preferredWallet.bonus === "number"
              ? preferredWallet.bonus.toFixed(currencyInfo.decimalPlaces)
              : parseFloat(preferredWallet.bonus).toFixed(
                  currencyInfo.decimalPlaces
                )}
          </div>
          <div className="text-sm text-greyscale-200">{t("text_bonuses")}</div>
        </div>
      </div>
    </div>
  );
};

export default AccountProfileStats;
