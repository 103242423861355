import { GameResponse } from "src/types";
import { HeartEmptyIcon, HeartIcon } from "src/assets/svg";
import React, { useEffect, useState } from "react";
import { FavouriteRequest } from "src/types/Favourite";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/context/AuthContext";
import useFavouritesService from "src/services/favouritesService";
import useNotificationsService from "src/services/notificationsService";

export interface FavouriteButtonProps {
  game: GameResponse;
  onToggle?: (game: GameResponse) => void;
}

export const FavouriteButton = ({
  game,
  onToggle = () => {},
}: FavouriteButtonProps) => {
  const { t } = useTranslation();
  const { playerData, token } = useAuth();
  const { addFavourite, deleteFavourite } = useFavouritesService();
  const { addErrorNotification, addInfoNotification } =
    useNotificationsService();

  // don't display straight from the prop, it won't refresh as the game
  // reference itself stays the same
  const [isFavourite, setIsFavourite] = useState(game.is_favourite);
  useEffect(() => setIsFavourite(game.is_favourite), [game.is_favourite]);

  const handleToggleFavourite = async (event: any) => {
    event.stopPropagation();
    if (!token) {
      addInfoNotification(t("info_login-to-fave"));
      return;
    }
    const favouriteRequest: FavouriteRequest = {
      player_id: playerData!.id,
      game_id: game!.game_id,
      provider_id: game!.provider_id,
    };
    try {
      if (game!.is_favourite) {
        await deleteFavourite(token!, favouriteRequest);
      } else {
        await addFavourite(token!, favouriteRequest);
      }
      game!.is_favourite = !game!.is_favourite;
      setIsFavourite(game.is_favourite);
    } catch (error: any) {
      addErrorNotification(t("error_fave-game"));
      console.log("Failed to fave game: ", error);
    }
    onToggle(game);
  };

  return (
    <div className="cursor-pointer select-none" onClick={handleToggleFavourite}>
      {isFavourite ? <HeartIcon /> : <HeartEmptyIcon />}
    </div>
  );
};

export default FavouriteButton;
