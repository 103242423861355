import { AffiliateRequest, AffiliateResponse } from "src/types";
import { useAxiosInterceptors } from "./apiConfig";
import { isAxiosError } from "axios";
import { CustomError } from "src/errors/CustomError";
import useNotificationsService from "./notificationsService";
  
const useAffiliatesService = () => {
    const { axiosInstance } = useAxiosInterceptors();
    const { addErrorNotification } = useNotificationsService();

    // Send affiliate information to the backend
    const sendAffiliate = async (
        affiliateData: AffiliateRequest
    ): Promise<AffiliateResponse> => {
        try {
            const response = await axiosInstance.post("/my-affiliates/register", affiliateData);
            return response.data;
        } catch (error: any) {
            if (isAxiosError(error)) {
                addErrorNotification(error.response?.data.error.description);
                throw new CustomError(
                "AffiliateError",
                error.response?.data.error.description
                );
            } else {
                throw new Error(error);
            }
        }
    };

    return { sendAffiliate };
};
  
export default useAffiliatesService;
  