import { SearchField } from "src/components/molecules";
import { GameResponse } from "src/types";
import { useState } from "react";
import useGamesService from "src/services/gamesService";
import { useTranslation } from "react-i18next";
import useNotificationsService from "src/services/notificationsService";

const GamesSearchField = (props: {
  isMobile?: boolean;
  onSearchResultItemClick: (item: GameResponse) => void;
}) => {
  const { t } = useTranslation();
  const { addErrorNotification } = useNotificationsService();
  const { search } = useGamesService();
  const [searchResults, setSearchResults] = useState<GameResponse[]>([]);

  const handleSearch = async (query: string, isMobile: boolean) => {
    if (query.trim() === "") {
      setSearchResults([]);
      return;
    }
    try {
      setSearchResults(await search({ q: query, mobile: isMobile }));
    } catch (error) {
      addErrorNotification(t("error_search"));
      console.error("Error doing search request: ", error);
    }
  };

  return (
    <SearchField
      onSearch={(query) => handleSearch(query, !!props.isMobile)}
      results={searchResults}
      onSearchResultItemClick={props.onSearchResultItemClick}
      isMobile={props.isMobile}
    />
  );
};

export default GamesSearchField;
