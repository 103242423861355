import { Suspense, useEffect, useState } from "react";
import styles from "./MarkdownContainer.module.scss";
import i18n from "i18next";
import { Markdown, MarkdownOptions } from "src/components/atoms";
import { MoonLoader } from "react-spinners";
import ForceScroll from "src/components/atoms/forceScroll/ForceScroll.component";

// container for injecting markdown translation files straight into react
export default function MarkdownContainer(props: {
  mdNamespace: string;
  options?: MarkdownOptions;
  forceScroll?: boolean;
}) {
  const {
    mdNamespace,
    options,
    forceScroll = true, // Default force scroll value
  } = props;

  // do not use useTranslation() for markdown namespaces! The i18nHttpBackend
  // takes too long to load the resource, and so it fails intermittently
  // due to concurrency issues
  const [content, setContent] = useState("");
  useEffect(() => {
    (async () => {
      await i18n.loadNamespaces(mdNamespace);
      setContent(i18n.getResource(i18n.language, mdNamespace, "content"));
    })();
  });

  return (
    <Suspense fallback={<MoonLoader />}>
      <div className={styles.MarkdownContainer}>
        <Markdown content={content} options={options} />
      </div>
      {forceScroll && <ForceScroll />}
    </Suspense>
  );
}
