import { useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";
import { Currency } from "src/types";
import { currencies } from "src/utils/currencyFunctions";

interface WalletCardProps {
  icon?: React.ReactNode;
  selectedCurrency?: string;
  balance?: number;
  title?: string;
  isLoading?: boolean;
}

const WalletCard = ({
  icon,
  selectedCurrency = "EUR",
  balance = 0,
  title,
  isLoading = false,
}: WalletCardProps) => {
  const [currencyInfo, setCurrencyInfo] = useState<Currency>(
    currencies.find((currency) => currency.shortName === selectedCurrency) ||
      currencies[2]
  );

  useEffect(() => {
    const newCurrencyInfo =
      currencies.find((currency) => currency.shortName === selectedCurrency) ||
      currencies[2];
    setCurrencyInfo(newCurrencyInfo);
  }, [selectedCurrency]);

  return (
    <div className="rounded-10 shadow-[0_10px_30px_0_rgba(83,33,118,0.15)] p-2.5 flex flex-col gap-2 items-center w-[158px]">
      {icon}
      {isLoading ? (
        <MoonLoader
          size={16}
          color="#65236c"
          loading={isLoading}
          speedMultiplier={0.7}
        />
      ) : (
        <span className="text-primary-400 text-xl leading-[26px] font-bold">
          {currencyInfo.symbol}
          {balance.toFixed(currencyInfo.decimalPlaces)}
        </span>
      )}
      <span className="text-primary-100 leading-[22px] font-bold">{title}</span>
    </div>
  );
};

export default WalletCard;
