import { useField, FieldHookConfig } from "formik";
import styles from "./InputText.module.scss";

export interface InputTextProps {
  label?: string;
  placeholder?: string;
}

const InputText: React.FC<
  InputTextProps & FieldHookConfig<string> & JSX.IntrinsicElements["input"]
> = ({ label = "", placeholder = "", ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className={`${styles.InputText} relative w-full flex flex-col gap-1`}>
      {label && (
        <label
          htmlFor={field.name}
          className="absolute left-4 -top-1.5 text-[10px] bg-greyscale-100 px-1 z-10"
        >
          {label}
        </label>
      )}
      <input
        {...field}
        {...props}
        placeholder={placeholder || label}
        className={`w-full h-14 border bg-transparent border-primary-400 rounded px-4 text-sm ${
          meta.touched && meta.error
            ? "border-warning-100 text-warning-100"
            : ""
        }`}
      />
      {meta.touched && meta.error && (
        <span className="pl-5 text-[10px] text-warning-100">{meta.error}</span>
      )}
    </div>
  );
};

export default InputText;
