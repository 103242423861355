import { useState } from "react";
import { ReactComponent as AddIcon } from "src/assets/icons/validation/Add.svg";
import { ReactComponent as ClearIcon } from "src/assets/icons/validation/Clear.svg";
import { Button, TCLink } from "src/components/atoms";
import { MarkdownContainer } from "src/components/molecules";
import { MainContainer } from "src/components/organisms";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const menuItems: { id: string; title: string }[] = [
  { id: "terms-and-conditions", title: "button_terms" },
  /* { id: "bonus-terms", title: "button_bonus-terms" }, */
  { id: "privacy", title: "button_privacy" },
  { id: "cookie-policy", title: "button_cookie" },
  { id: "responsible-gaming", title: "button_responsible" },
  { id: "aml-and-kyc", title: "button_aml" },
  /* { id: "affiliates", title: "button_affiliates" }, */
];

export default function TermsAndConditionsPage({ itemId }: { itemId: string }) {
  const { t } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const TCMenuMobile = () => (
    <div className="md:hidden flex justify-between border-b border-secondary-100 pb-3.5 items-center w-full px-9 relative">
      <span className="font-extrabold text-lg text-greyscale-100">
        {t(menuItems.find((item) => item.id === itemId)?.title || "")}
      </span>
      <Button
        className="border border-secondary-100 text-secondary-100 p-1 rounded-md"
        onClick={toggleMenu}
      >
        {isMobileMenuOpen ? <ClearIcon /> : <AddIcon />}
      </Button>
      {isMobileMenuOpen && (
        <div className="absolute top-11 w-full h-fit left-0 overflow-clip border-b border-secondary-100">
          <div className="absolute bg-primary-400 opacity-95 h-screen w-full z-10" />
          <div className="z-20 relative flex flex-col gap-2 px-5 pb-6">
            {menuItems
              .filter((item) => item.id !== itemId)
              .map((item, key) => (
                <TCLink key={key} to={"/" + item.id} onClick={toggleMenu}>
                  {t(item.title)}
                </TCLink>
              ))}
          </div>
        </div>
      )}
    </div>
  );

  const TCMenuDesktop = () => (
    <div className="grow-0 md:w-80 hidden md:block">
      <div className="text-[44px] text-secondary-100 leading-10 mb-9">
        {t("text_all-you-need")}
      </div>
      <div className="flex flex-col gap-3.5">
        {menuItems.map((item, key) => (
          <TCLink to={"/" + item.id} key={key} selected={item.id === itemId}>
            {t(item.title)}
          </TCLink>
        ))}
      </div>
    </div>
  );

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Terms and Conditions Page",
  });

  return (
    <MainContainer>
      <div className="flex flex-col md:flex-row bg-custom-gradient lg:gap-20 gap-10 justify-center lg:px-52 lg:py-40 py-6 px-6 w-full">
        <TCMenuMobile />
        <TCMenuDesktop />
        <div className="grow-1 md:w-full text-greyscale-100 break-words">
          <MarkdownContainer mdNamespace={itemId} />
        </div>
      </div>
    </MainContainer>
  );
}
