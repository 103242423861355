import axios from "axios";
import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// set separate configurations for the spring boot endpoints, to make it
// easier to test and debug the endpoints themselves.
const axiosFavouritesInstance = axios.create({
  // baseURL: "http://localhost:8080/", // local API testing
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});
const axiosCustomBonusInstance = axios.create({
  // baseURL: "http://localhost:8080/", // local API testing
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

const axiosLocationInstance = axios.create({
  baseURL: "https://ipapi.co",
  headers: { "Content-Type": "application/json" },
});

const axiosRefreshInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

const useAxiosInterceptors = () => {
  const { token, refreshToken } = useAuth();

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
    };
  }, [token]);

  useEffect(() => {
    const requestInterceptor = axiosRefreshInstance.interceptors.request.use(
      (config) => {
        if (refreshToken) {
          config.headers.Authorization = `Bearer ${refreshToken}`;
        }
        return config;
      }
    );

    return () => {
      axiosRefreshInstance.interceptors.request.eject(requestInterceptor);
    };
  }, [refreshToken]);

  return {
    axiosInstance,
    axiosCustomBonusInstance,
    axiosFavouritesInstance,
    axiosLocationInstance,
    axiosRefreshInstance,
  };
};

export { useAxiosInterceptors };
