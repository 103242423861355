import { CustomError } from "src/errors/CustomError";
import { useAxiosInterceptors } from "src/services/apiConfig";
import {
  GetBalanceResponse,
  GetCurrencyResponse,
  GetOrderResponse,
  GetTransactionResponse,
  GetWalletResponse,
} from "src/types";

const useTransactionService = () => {
  const { axiosInstance } = useAxiosInterceptors();

  // Get wallet balances by player ID
  const getBalances = async (
    playerId: string
  ): Promise<GetBalanceResponse[]> => {
    try {
      const response = await axiosInstance.get(`/balances/player/${playerId}`);
      return response.data.result;
    } catch (error: any) {
      const { code, description } = error.response.data.error;
      throw new CustomError(code, description);
    }
  };

  // Get a list of all supported currencies
  const getCurrencies = async (): Promise<GetCurrencyResponse[]> => {
    try {
      const response = await axiosInstance.get("/currencies");
      return response.data.result;
    } catch (error: any) {
      const { code, description } = error.response.data.error;
      throw new CustomError(code, description);
    }
  };

  // Return a currency by ID
  const getCurrencyById = async (
    currencyId: string
  ): Promise<GetCurrencyResponse> => {
    try {
      const response = await axiosInstance.get(`/currencies/${currencyId}`);
      return response.data.result;
    } catch (error: any) {
      const { code, description } = error.response.data.error;
      throw new CustomError(code, description);
    }
  };

  // Return list of wallets by player ID
  const getPlayerWallets = async (
    playerId: string
  ): Promise<GetWalletResponse[]> => {
    try {
      const response = await axiosInstance.get(`/wallets/player/${playerId}`);
      return response.data;
    } catch (error: any) {
      const { code, description } = error.response.data.error;
      throw new CustomError(code, description);
    }
  };

  // Return a list of all orders by player ID
  const getOrders = async (playerId: string): Promise<GetOrderResponse[]> => {
    try {
      const response = await axiosInstance.get(`/orders/${playerId}`);
      return response.data.result;
    } catch (error: any) {
      const { code, description } = error.response.data.error;
      throw new CustomError(code, description);
    }
  };

  // Return a list of all transactions by player ID
  const getTransactions = async (
    playerId: string
  ): Promise<GetTransactionResponse[]> => {
    try {
      const response = await axiosInstance.get(
        `/transactions/player/${playerId}`
      );
      return response.data.result;
    } catch (error: any) {
      const { code, description } = error.response.data.error;
      throw new CustomError(code, description);
    }
  };

  return {
    getBalances,
    getCurrencies,
    getCurrencyById,
    getPlayerWallets,
    getOrders,
    getTransactions,
  };
};

export default useTransactionService;
