import { useState } from "react";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/arrows/Chevron Down.svg";
import { ReactComponent as ChevronUpIcon } from "../../../assets/icons/arrows/Chevron Up.svg";

export interface FAQQuestionProps {
  className?: string;
  question?: string | JSX.Element;
  answer?: string | JSX.Element;
}

const FAQQuestion = ({
  className = "",
  question,
  answer,
}: FAQQuestionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleIsOpen = () => setIsOpen(!isOpen);

  return (
    <div
      className={`w-full rounded-md border border-[#B9FFB7] flex flex-col gap-4 px-4 py-[14px] ${className}`}
    >
      <div className="flex flex-row justify-between w-full font-semibold text-sm text-[#B9FFB7]">
        <span>{question}</span>
        <div onClick={handleIsOpen} className="cursor-pointer select-none">
          {isOpen ? (
            <ChevronUpIcon className="text-[#B9FFB7]" />
          ) : (
            <ChevronDownIcon className="text-[#B9FFB7]" />
          )}
        </div>
      </div>
      <div
        className={`grid overflow-hidden font-light text-sm text-white transition-all ease-in-out duration-300 ${
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        } `}
      >
        <div className="overflow-hidden">{answer}</div>
      </div>
    </div>
  );
};

export default FAQQuestion;
