import { MarkdownContainer } from "src/components/molecules";
import { MainContainer } from "src/components/organisms";
import ReactGA from "react-ga4";

export default function MarkdownPage(props: { mdNamespace: string }) {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Markdown Page: " + props.mdNamespace,
  });

  return (
    <MainContainer>
      <div className="flex flex-col bg-custom-gradient  gap-10 justify-center md:px-52 md:py-40 py-6 px-6 w-full text-greyscale-100">
        <MarkdownContainer {...props} />
      </div>
    </MainContainer>
  );
}
