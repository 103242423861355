import {
  GameResponse,
  LaunchGameRequest,
  LaunchGameResponse,
  SearchGameRequest,
} from "src/types";
import { useAxiosInterceptors } from "./apiConfig";
import { GameType } from "src/types/GameType";
import useFavouritesService from "src/services/favouritesService";
import { Page } from "src/types/Page";

const useGamesService = () => {
  const { axiosInstance, axiosFavouritesInstance } = useAxiosInterceptors();
  const { getFavourites } = useFavouritesService();

  // Get details of one game
  const getGame = async (
    providerId: string,
    gameId: string
  ): Promise<GameResponse> => {
    try {
      const response = await axiosFavouritesInstance.get(
        `/games/${providerId}/${gameId}`
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Get a list of all games
  const getPagedGames = async (
    request?: SearchGameRequest
  ): Promise<Page<GameResponse>> => {
    // @ts-ignore
    const gameType = GameType[request?.type]?.type;
    const restRequest = { params: { ...request, type: gameType } };
    try {
      const response = await axiosFavouritesInstance.get(
        "/games/search",
        restRequest
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Starts a game
  const launchGame = async (
    token: string,
    gameData: LaunchGameRequest
  ): Promise<LaunchGameResponse> => {
    try {
      const createPlayRequest = {
        player_id: gameData.player_id,
        game_id: gameData.game_id,
        provider_id: gameData.provider_id,
        mobile: gameData.is_mobile,
      };
      // log the play for telemetry, last played and popular games
      await axiosFavouritesInstance.post("/plays", createPlayRequest, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const response = await axiosInstance.post("/launch_game", gameData);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const search = async (
    request: SearchGameRequest
  ): Promise<GameResponse[]> => {
    // @ts-ignore
    const restRequest = { params: request };
    try {
      const response = await axiosFavouritesInstance.get(
        "/games/search",
        restRequest
      );
      return response.data.content;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getNew = async (mobile: boolean): Promise<GameResponse[]> => {
    const restRequest = { params: { mobile } };
    try {
      const response = await axiosFavouritesInstance.get(
        "/games/new",
        restRequest
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getTop = async (
    mobile: boolean,
    gameTypeId?: string
  ): Promise<GameResponse[]> => {
    // @ts-ignore
    const gameType = GameType[gameTypeId]?.type;
    const restRequest = { params: { mobile, gameType } };
    try {
      const response = await axiosFavouritesInstance.get(
        "/plays/top",
        restRequest
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getLive = async (
    mobile: boolean,
    gameTypeId?: string
  ): Promise<GameResponse[]> => {
    // @ts-ignore
    const gameType = GameType[gameTypeId]?.type;
    const restRequest = { params: { mobile, gameType } };
    try {
      const response = await axiosFavouritesInstance.get(
        "/plays/live",
        restRequest
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getByType = async (
    gameTypeId: string,
    mobile: boolean,
    filter?: string,
    playerId?: string,
    token?: string
  ): Promise<GameResponse[]> => {
    if (gameTypeId === "hot") {
      return getTop(mobile);
    } else if (gameTypeId === "live") {
      return getLive(mobile);
    } else if (gameTypeId === "new") {
      return getNew(mobile);
    } else if (gameTypeId === "favourites" && playerId && token) {
      return getFavourites(playerId, token);
    } else if (filter === "popular") {
      return getTop(mobile, gameTypeId);
    } else if (filter === "recent") {
      return getLastPlayed(playerId!, token!, gameTypeId);
    } else if (filter === "favourites" && playerId && token) {
      return getFavourites(playerId!, token!, gameTypeId);
    } else {
      return getPagedGames({ type: gameTypeId, mobile: mobile }).then(
        (res: Page<GameResponse>) => res.content
      );
    }
  };

  // get the games most recently played by the player
  const getLastPlayed = async (
    playerId: string,
    token: string,
    gameTypeId?: string
  ): Promise<GameResponse[]> => {
    try {
      // @ts-ignore
      const gameType = GameType[gameTypeId]?.type;
      const response = await axiosFavouritesInstance.get(`/plays/${playerId}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { gameType },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    getGame,
    getPagedGames,
    launchGame,
    search,
    getNew,
    getTop,
    getByType,
    getLastPlayed,
  };
};

export default useGamesService;
