import { useEffect, useRef } from "react";
import { useAuth } from "src/context/AuthContext";
import usePlayerSessionService from "src/services/playerSessionService";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function CheckTokens() {
  const { playerData, logout, setToken, setRefreshToken, setPlayerData } =
    useAuth();
  const { refresh } = usePlayerSessionService();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const isCheckingRef = useRef(false);
  const navigate = useNavigate(); // Initialize navigate hook

  useEffect(() => {
    const checkTokens = () => {
      const token = Cookies.get("token");
      const refreshToken = Cookies.get("refreshToken");

      if (!token && !refreshToken && playerData) {
        logout();
        navigate("/login"); // Navigate to the login page
      }

      if (!token && refreshToken && playerData) {
        refresh(playerData.id)
          .then((res) => {
            setToken(res.token);
            setRefreshToken(res.refresh_token);
            setPlayerData(res.player);
          })
          .catch((err) => {
            console.error(err);
            logout();
            navigate("/login"); // Navigate to the login page
          });
      }
    };

    const handleVisibilityChange = () => {
      if (!document.hidden && !isCheckingRef.current) {
        // If the page becomes visible again, immediately check tokens
        checkTokens();
        // Restart the interval
        intervalRef.current = setInterval(checkTokens, 1000);
        isCheckingRef.current = true;
      } else if (document.hidden && intervalRef.current) {
        // Stop checking tokens when the tab is inactive
        clearInterval(intervalRef.current);
        isCheckingRef.current = false;
      }
    };

    // Initial token check
    checkTokens();

    // Start the interval when the component mounts
    intervalRef.current = setInterval(checkTokens, 1000);
    isCheckingRef.current = true;

    // Listen for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    playerData,
    refresh,
    setToken,
    setRefreshToken,
    setPlayerData,
    logout,
    navigate,
  ]);

  return null;
}
