import React, {
  KeyboardEvent,
  ChangeEvent,
  ReactElement,
  FocusEvent,
} from "react";
import { useField, FieldHookConfig } from "formik";

interface iProps {
  maxLength?: number;
  type?: string;
  label?: string;
  autoComplete?: "on" | "off";
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  surfix?: ReactElement;
  disabled?: boolean;
}

const Input: React.FC<
  iProps & FieldHookConfig<string> & JSX.IntrinsicElements["input"]
> = ({
  maxLength = 524,
  type = "text",
  label,
  placeholder = "",
  autoComplete = "on",
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
  surfix,
  disabled = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error;

  return (
    <div className="card-input mb-4">
      {label && <label className="card-input__label block mb-2">{label}</label>}
      <input
        {...field}
        {...props}
        maxLength={maxLength}
        onChange={onChange || field.onChange}
        onKeyDown={onKeyDown}
        type={type}
        placeholder={placeholder}
        className={`card-input__input block w-full p-2 border rounded ${
          isError ? "!border-red-500" : "border-gray-300"
        }`}
        autoComplete={autoComplete}
        onFocus={onFocus}
        onBlur={onBlur || field.onBlur}
        disabled={disabled}
        name={field.name}
      />
      {surfix}
      {isError && (
        <div className="card-input__error text-red-500 text-sm mt-1">
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default Input;
export const MemorizedInput = React.memo(Input);
