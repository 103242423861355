import { useField, FieldHookConfig } from "formik";
import { useState } from "react";
import { ReactComponent as EyeIcon } from "src/assets/icons/edit/Eye.svg";
import { ReactComponent as EyeOffIcon } from "src/assets/icons/edit/Eye Off.svg";

export interface InputPasswordProps {
  label?: string;
  placeholder?: string;
}

const InputPassword: React.FC<
  InputPasswordProps & FieldHookConfig<string> & JSX.IntrinsicElements["input"]
> = ({ label = "", placeholder = "", ...props }) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative w-full bg-none flex flex-col gap-1">
      {label && (
        <span className="absolute left-4 -top-1.5 text-[10px] bg-greyscale-100 px-1 z-10">
          {label}
        </span>
      )}
      <div>
        <input
          type={showPassword ? "text" : "password"}
          {...field}
          {...props}
          className={`w-full flex items-center h-14 border bg-transparent border-primary-400 rounded px-4 text-sm ${
            meta.touched && meta.error && "border-warning-100 text-warning-100"
          }`}
          placeholder={placeholder || label}
        />
        {showPassword ? (
          <EyeOffIcon
            className={`absolute top-4 right-4 ${
              meta.touched && meta.error && "text-warning-100"
            }`}
            onClick={togglePasswordVisibility}
          />
        ) : (
          <EyeIcon
            className={`absolute top-4 right-4 ${
              meta.touched && meta.error && "text-warning-100"
            }`}
            onClick={togglePasswordVisibility}
          />
        )}
      </div>
      {meta.touched && meta.error && (
        <span className="pl-5 text-[10px] text-warning-100">{meta.error}</span>
      )}
    </div>
  );
};

export default InputPassword;
