export interface NavLinkProps {
  children?: React.ReactNode;
  className?: string;
}

export const NavLink = ({ children, className = "" }: NavLinkProps) => {
  return <div className={`flex items-center ${className}`}>{children}</div>;
};

export default NavLink;
