import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FAQ,
  GamesFilter,
  GameList,
  MainContainer,
  GamesGridSlider,
} from "src/components/organisms";
import { ReactComponent as SkeletonGrid } from "src/assets/icons/loaders/All Slot Games.svg";
import { ReactComponent as SkeletonSlider } from "src/assets/icons/loaders/Most Popular.svg";
import { ReactComponent as BrokenHeartIcon } from "src/assets/icons/emoji/Broken Heart.svg";
import GameModal from "src/components/molecules/gameModal/GameModal.component";
import GamesSidebar from "src/components/molecules/gamesSidebar/GamesSidebar.component";
import ReactGA from "react-ga4";
import useGamesService from "src/services/gamesService";
import useNotificationsService from "src/services/notificationsService";
import { GameResponse } from "src/types";
import { GameType } from "src/types/GameType";
import { useAuth } from "src/context/AuthContext";
import { useScroll } from "src/context/ScrollContext";
import Favourifier from "src/components/organisms/favourifier/Favourifier.component";

export default function GamesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { playerData, token } = useAuth();
  const { getGame, getByType } = useGamesService();
  const { addErrorNotification } = useNotificationsService();
  const { setSkipNext } = useScroll();

  const [visibleItemsCount, setVisibleItemsCount] = useState(15);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayGames, setDisplayGames] = useState<GameResponse[]>([]);
  const [selectedGame, setSelectedGame] = useState<GameResponse | null>(null);
  const [selectedFilterCode, setSelectedFilterCode] = useState<string>("none");
  const [titleKey, setTitleKey] = useState("");
  const [oldGameTypeId, setOldGameTypeId] = useState<string | undefined>("");
  const params = useParams<{ gameTypeId?: string; gameId?: string }>();

  const handleSelectGame = (game: GameResponse) => {
    setSelectedGame(game);
    setSkipNext(true);
    navigate(`/games/${params.gameTypeId}/${game.game_id}`);
  };

  const resetGames = async (filter?: string) => {
    try {
      setSelectedFilterCode(filter || "none");
      setIsLoading(true);
      const mobile = window.innerWidth < 1024;
      const games = await getByType(
        params.gameTypeId!,
        mobile,
        filter,
        playerData?.id,
        token || ""
      );
      setDisplayGames(games);
      if (params.gameId) {
        const openGame = await getGame("blue_ocean", params.gameId);
        if (openGame) setSelectedGame(openGame);
      }
    } catch (error: any) {
      addErrorNotification(t("error_fetching-games"));
      console.log("Failed to fetch games: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.gameTypeId === oldGameTypeId) {
      // only the gameId changed, so don't redraw the list
      setSkipNext(true);
      return;
    }
    if (
      !GameType[params.gameTypeId!] ||
      (params.gameTypeId === "favourites" && !playerData)
    ) {
      navigate("/games/casino/" + (params.gameId || ""));
      return;
    }
    setOldGameTypeId(params.gameTypeId);
    setTitleKey(GameType[params.gameTypeId!].title);
    (async () => await resetGames())();
  }, [params]);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Games Page",
  });

  return (
    <MainContainer onSearchResultItemClick={handleSelectGame}>
      <Favourifier onLoad={setDisplayGames} games={displayGames} />
      <div className="flex 2xl:px-52 xl:px-[84px] px-4 bg-custom-gradient w-full">
        <div className="NavBar flex gap-10 md:py-12 py-6 w-full">
          <div className="hidden lg:block">
            <GamesSidebar isLoading={isLoading} />
          </div>
          <div className="w-full flex flex-col gap-16">
            <div className="flex flex-col gap-4">
              <h1 className="text-[34px] text-greyscale-100 font-bold">
                <p className="capitalize">{t(titleKey)}</p>
              </h1>
              <GamesFilter
                isLoading={isLoading}
                selectedFilterCode={selectedFilterCode}
                show={GameType[params.gameTypeId!].showFilter}
                onFilter={resetGames}
              />
            </div>
            <GameModal
              selectedGame={selectedGame}
              onClose={() => {
                setSelectedGame(null);
                navigate("..", { relative: "path" });
              }}
            />
            {isLoading ? (
              <>
                <div className="lg:flex hidden">
                  <SkeletonGrid />
                </div>
                <div className="flex lg:hidden">
                  <SkeletonSlider />
                </div>
              </>
            ) : !displayGames?.length ? (
              <div className="flex flex-col items-center gap-4">
                <BrokenHeartIcon className="w-24 h-24 text-greyscale-100" />
                <h2 className="text-2xl text-greyscale-100 text-center">
                  {params.gameTypeId === "favourites"
                    ? t("text_no-favourites")
                    : t("text_system-maintenance")}
                </h2>
              </div>
            ) : (
              <>
                <div className="flex flex-col gap-4">
                  <GameList
                    title={t("text_games-in", { type: t(titleKey) })}
                    buttonTitle={t("text_see-all-in", { type: t(titleKey) })}
                    rows={3}
                    items={displayGames.slice(0, visibleItemsCount)}
                    onSelectGame={handleSelectGame}
                    className="lg:block hidden"
                    gameType={params.gameTypeId}
                  />
                </div>
                <div className="flex flex-col gap-10 lg:hidden md:w-[calc(100vw-54px)] w-[calc(100vw-32px)]">
                  <GamesGridSlider
                    sliderHeaderContent={t("text_games-in", {
                      type: t(titleKey),
                    })}
                    contentList={displayGames.slice(0, visibleItemsCount)}
                    onSelectGame={handleSelectGame}
                    className="lg:hidden block"
                  />
                </div>
              </>
            )}
            <div className="md:px-[173px]">
              <FAQ />
            </div>
            <section className="flex flex-col md:px-64 md:py-20 text-sm items-center text-center text-greyscale-100 gap-8">
              <b>{t("text_gambling-aware-2")}</b>
              <p>{t("text_copyright")}</p>
            </section>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}
