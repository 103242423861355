// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const i18nMarkdownNamespaces = [
  "about-us",
  "responsible-gaming",
  "affiliates",
  "faqs",
  "contact-us",
  "terms-and-conditions",
  "bonus-terms",
  "cookie-policy",
  "privacy",
  "aml-and-kyc",
  "banking",
  "cashback",
  "free-spins"
];

const toString = (s: string | string[]) => (typeof s === "string" ? s : s[0]);

// use special namespaces for loading markdown files
const loadPath = (lng: string | string[], ns: string | string[]) => {
  lng = toString(lng);
  ns = toString(ns);
  if (i18nMarkdownNamespaces.includes(ns)) {
    return `/locales/${lng}/${ns}.md`;
  }
  return `/locales/${lng}/${ns}.json`;
};

// transform markdown files from md namespace into json that i18n can read
const parse = (
  data: string,
  lng?: string | string[],
  ns?: string | string[],
) => {
  ns = toString(ns || "");
  // FIXME the static server sometimes won't honor the markdown requests
  //  and react-remark can't handle invalid markdown. just drop anything
  //  that looks like html for now
  data = data.trimStart().startsWith("<!DOCTYPE html>") ? "" : data;
  if (i18nMarkdownNamespaces.includes(ns)) {
    return { content: data };
  }
  return JSON.parse(data);
};

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    backend: {
      loadPath: loadPath,
      parse: parse,
    },
    detection: {
      order: ["queryString", "cookie"],
      caches: ["cookie"],
    },
  });

export default i18n;
