import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as ArrowRight } from "src/assets/icons/arrows/Chevron Right.svg";
import { ReactComponent as ThumbsUpIcon } from "src/assets/icons/emoji/Thumbs Up.svg";
import { ReactComponent as EmailIcon } from "src/assets/icons/email/Speed Email.svg";
import { useTranslation } from "react-i18next";

export interface EmailSubmitFieldProps {
  theme?: "dark" | "light";
  buttonStyle?: "sticky" | "floating";
}

export const EmailSubmitField = ({
  theme = "light",
  buttonStyle = "sticky",
}: EmailSubmitFieldProps) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (submitted) {
      timer = setTimeout(() => setSubmitted(false), 3000); // Change back after 3 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [submitted]);
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t("error_invalid-email"))
          .required(t("error_required")),
      })}
      onSubmit={(values, { resetForm }) => {
        console.log(values);
        setSubmitted(true);
        resetForm();
      }}
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col w-full">
          <div
            className={`relative flex items-center items-stretch w-full ${
              buttonStyle === "floating" && theme === "light"
                ? "bg-greyscale-100 rounded-10"
                : ""
            } ${
              buttonStyle === "floating" && theme === "dark"
                ? "bg-transparent rounded-10 border border-greyscale-100"
                : ""
            }`}
          >
            <Field
              id="email"
              name="email"
              type="email"
              placeholder={t("placeholder_email")}
              className={`pl-6 py-4 focus:outline-none w-full ${
                theme === "dark"
                  ? "text-greyscale-100 placeholder-greyscale-100 bg-transparent border-greyscale-100"
                  : "text-primary-400 placeholder-primary-400 border-transparent"
              } ${buttonStyle === "floating" ? "text-base" : "text-xs"} ${
                theme === "dark" && buttonStyle === "floating"
                  ? "rounded-l-10"
                  : "border rounded-l-10"
              }`}
            />
            <button
              type="submit"
              className={`${
                submitted
                  ? "bg-primary-200 text-greyscale-100"
                  : "bg-secondary-200 text-greyscale-300 hover:bg-secondary-300 hover:text-greyscale-100 transition duration-300"
              }
              ${
                buttonStyle === "floating"
                  ? "my-2.5 mr-2.5 px-6 py-3.5 rounded-10 flex items-center justify-center gap-5"
                  : "p-3.5 rounded-r-10"
              }`}
              disabled={isSubmitting}
            >
              {buttonStyle === "floating" && (
                <span className="whitespace-nowrap hidden lg:block">
                  {submitted ? t("button_sent") : t("button_notify-me")}
                </span>
              )}
              <AnimatePresence mode="wait">
                {submitted ? (
                  <motion.div
                    key="thumbs-up"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <ThumbsUpIcon />
                  </motion.div>
                ) : buttonStyle === "floating" ? (
                  <motion.div
                    key="email-icon"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <EmailIcon />
                  </motion.div>
                ) : (
                  <motion.div
                    key="arrow-right"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <ArrowRight />
                  </motion.div>
                )}
              </AnimatePresence>
            </button>
          </div>
          <ErrorMessage
            name="email"
            component="div"
            className="pl-6 text-warning-200 text-xs mt-1"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EmailSubmitField;
