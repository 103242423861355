import { t } from "i18next";
import React, { ChangeEvent, useState } from "react";

interface DepositSelectorProps {
  onConfirm: (value: number) => void;
  currencyPrefix?: string;
}

const DepositSelector: React.FC<DepositSelectorProps> = ({
  onConfirm,
  currencyPrefix = "$",
}) => {
  const [selectedValue, setSelectedValue] = useState<number | "">("");

  const handlePresetClick = (value: number) => {
    setSelectedValue(value);
    onConfirm(value);
  };

  const handleCustomValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setSelectedValue(isNaN(value) || value < 0 ? "" : value);
  };

  const handleConfirm = () => {
    if (typeof selectedValue === "number") {
      onConfirm(selectedValue);
    }
  };

  return (
    <div className="flex flex-col gap-2.5">
      <div className="flex justify-between gap-0 md:justify-normal md:gap-3.5 w-full">
        <button
          className={`p-2 rounded w-[100px] h-11 border border-greyscale-400 text-sm leading-[22px] transition ${
            selectedValue === 50
              ? "bg-primary-400 text-greyscale-100"
              : "text-primary-400"
          }`}
          onClick={() => handlePresetClick(50)}
        >
          {currencyPrefix}50
        </button>
        <button
          className={`p-2 rounded w-[100px] h-11 border border-greyscale-400 text-sm leading-[22px] transition ${
            selectedValue === 125
              ? "bg-primary-400 text-greyscale-100"
              : "text-primary-400"
          }`}
          onClick={() => handlePresetClick(125)}
        >
          {currencyPrefix}125
        </button>
        <button
          className={`p-2 rounded w-[100px] h-11 border border-greyscale-400 text-sm leading-[22px] transition ${
            selectedValue === 500
              ? "bg-primary-400 text-greyscale-100"
              : "text-primary-400"
          }`}
          onClick={() => handlePresetClick(500)}
        >
          {currencyPrefix}500
        </button>
      </div>
      <div className="flex gap-2.5">
        <input
          type="number"
          min={0}
          placeholder={t("placeholder_custom-amount")}
          value={selectedValue === "" ? "" : selectedValue}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.replace(/[^0-9.-]/g, "");
          }}
          onChange={handleCustomValueChange}
          onKeyDown={(e) => {
            if (
              e.key === "e" ||
              e.key === "E" ||
              e.key === "+" ||
              e.key === "-"
            ) {
              e.preventDefault(); // Prevent typing of these keys
            }
          }}
          className="border p-3.5 w-full h-11 rounded text-sm leading-[22px] border border-greyscale-400"
        />
        <button
          type="button"
          className="w-[100px] h-11 bg-primary-200 text-greyscale-100 hover:bg-tertiary-200 hover:text-greyscale-400 px-6 rounded disabled:cursor-not-allowed transition duration-300"
          onClick={handleConfirm}
          disabled={selectedValue === ""}
        >
          {t("button_confirm")}
        </button>
      </div>
    </div>
  );
};

export default DepositSelector;
