import { SVGProps } from "react"
const HeartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#fff"
      strokeLinejoin="round"
      d="M3.688 10.937 10 17.248l6.312-6.312A4.057 4.057 0 0 0 10.574 5.2L10 5.772 9.426 5.2a4.057 4.057 0 1 0-5.738 5.738Z"
    />
  </svg>
)
export default HeartIcon
