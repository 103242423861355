export const faq = [
  {
    id: 0,
    title: "General",
    content: [
      {
        question: <p>How do I register as a new user?</p>,
        answer: (
          <p>
            Registration is free. Click{" "}
            <span className="font-bold">Sign up</span> on the top right of the
            web page and provide the information requested. You will need to set
            maximum betting limits per day, week and month. Please be sure to
            enter realistic and sensible limits.
          </p>
        ),
      },
      {
        question: <p>I haven't received a confirmation email.</p>,
        answer: (
          <p>
            Please double check that the email address you entered is correct
            and that the confirmation email is not in your junk box. If you
            still cannot receive it, we recommend changing to another email
            address. You can change your email address at any time by contacting
            us via live chat on our website.
          </p>
        ),
      },
      {
        question: <p>Can I register more than one account at a time?</p>,
        answer: (
          <p>
            Customers can only open one member account with Brother Bets casino.
            If you open or attempt to open multiple accounts, Brother Bets
            reserves the right to close some or all of the accounts created and
            void all winnings from those accounts. If we decide to keep one
            account open, it will be the first account you register with Brother
            Bets and any remaining deposits will be transferred to that account.
            Brother Bets will debit an administrative fee of 10% or $35,
            whichever is higher, for every registered account, as well as other
            applicable levies in accordance with Brother Bets Terms of Use.
          </p>
        ),
      },
      {
        question: <p>Do you have the necessary licenses to operate?</p>,
        answer: (
          <p>
            This site has a Tobique Gaming license and is operated under strict
            auditing, so you can play with confidence.
          </p>
        ),
      },
      {
        question: <p>What types of games do you offer?</p>,
        answer: (
          <p>Our site offers casino games, live casino, and slot games.</p>
        ),
      },
      {
        question: <p>What should I do if I have an enquiry?</p>,
        answer: (
          <p>
            Please contact us via live chat, which is available 24 hours a day
            in English. If you click on{" "}
            <span className="font-bold">Live Chat</span> at the bottom right of
            the website, an agent will respond. Alternatively, please contact us
            by email at{" "}
            <a className="font-bold" href="mailto: support@brotherbets.com">
              support@brotherbets.com
            </a>
            .
          </p>
        ),
      },
    ],
  },
  {
    id: 1,
    title: "Account",
    content: [
      {
        question: <p>I forgot my password. How can I reset it?</p>,
        answer: (
          <p>
            Login form{" "}
            <span className="font-bold">≪Forgot your password? Click ≫</span>{" "}
            and enter your registered email address. A new password setting link
            will be sent to your registered email address, so please reset your
            password from there.
          </p>
        ),
      },
      {
        question: <p>I have a problem logging in. What should I do?</p>,
        answer: (
          <p>
            Please try entering your correct email address and password again.
            If the problem still persists, please contact our customer support.
          </p>
        ),
      },
      {
        question: (
          <p>
            Can I update my registration information once I have registered?
          </p>
        ),
        answer: (
          <p>
            To update your registration information, please click on the
            <span className="font-bold">Account Icon</span> in the upper right
            corner of the website, select{" "}
            <span className="font-bold">My Account</span>, and make changes in
            your account information. However, only your address and phone
            number can be updated. If you need to change any other information,
            please contact our customer support.
          </p>
        ),
      },
      {
        question: <p>Can I change my username?</p>,
        answer: (
          <p>
            You cannot change your user ID after creating an account, so please
            consider your user ID when registering.
          </p>
        ),
      },
      {
        question: <p>Can I change my registered e-mail address?</p>,
        answer: (
          <p>
            Yes, you can. You can change your e-mail address by contacting us
            through our live chat system.
          </p>
        ),
      },
      {
        question: <p>Is it possible to change the currency display to yen?</p>,
        answer: (
          <p>
            The currencies available are USD, Euros GBP, and major Crypto
            currencies.
          </p>
        ),
      },
      {
        question: <p>Where can I check my balance?</p>,
        answer: (
          <p>Your available balance is displayed in the upper right corner.</p>
        ),
      },
      {
        question: (
          <p>Where can I check the details of my deposits and withdrawals?</p>
        ),
        answer: (
          <p>
            If you want to check the details of your deposits and withdrawals,
            click on <span className="font-bold">Transaction History</span> in{" "}
            <span className="font-bold">My Account</span> to display the details
            of your deposits and withdrawals.
          </p>
        ),
      },
      {
        question: <p>Is my transaction/personal information secure?</p>,
        answer: (
          <p>
            Yes, Brother Bets encrypts all transactions, personal information
            and all other information using Secure Sockets Layer (SSL) 256-bit
            communication. We use only the highest level of data encryption to
            ensure that your information is safe and secure.
          </p>
        ),
      },
      {
        question: <p>Can I remove the self-regulation before the deadline?</p>,
        answer: (
          <p>
            The website will not be able to remove the self-imposed restrictions
            until the deadline you set by yourself has arrived.
          </p>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "Payments",
    content: [
      /* {
        question: <p>What deposit methods do you offer?</p>,
        answer: <p>We offer the following deposit methods;</p>,
      }, */
      {
        question: <p>How do I make a deposit?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                How to make a deposit on your mobile phone:
              </span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Login to your account</li>
              <li>
                Tap the "Account Icon" in the upper right corner of the website
              </li>
              <li>Tap "Deposit"</li>
              <li>Select a deposit method</li>
              <li>Enter the amount you wish to deposit</li>
              <li>Tap "Make Deposit" to complete your deposit</li>
              <li>
                Complete the procedure based on the deposit method and complete
                the deposit
              </li>
            </ol>
            <li className="mt-4">
              <span className="font-bold">How to make a deposit on a PC:</span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Login to your account</li>
              <li>Click "Deposit" in the upper right corner of the website</li>
              <li>Follow steps 4-7 above to complete your deposit</li>
            </ol>
            <li className="mt-4">
              ※ Please note that depending on your payment method, you may be
              redirected to the relevant payment site. Also, depending on the
              financial institution you use, you may be asked to verify your
              identity.
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>Can I make deposits and withdrawals on weekends and holidays?</p>
        ),
        answer: (
          <p>Yes. All deposit and withdrawal methods are available 24/7</p>
        ),
      },
      /* {
        question: (<p>What is the minimum deposit amount?</p>),
        answer: "",
      }, */
      /* {
        question: (<p>Is there a fee for making a deposit?</p>),
        answer: "",
      }, */
      {
        question: <p>Is it safe to use my credit card at Brother Bets?</p>,
        answer: (
          <p>
            Yes. We do our best to protect the personal information provided by
            our customers. The security page of our website is encrypted using
            Secure Sockets Layer (SSL) 256-bit communication. Credit card
            information is encrypted and is not stored in the Brother Bets
            system, but is sent directly to the credit card processing company
            provided by Brother Bets through an encrypted channel. It is stored
            in the form of a <span className="font-bold">"token"</span> to be
            used as a reference for future transactions.
          </p>
        ),
      },
      {
        question: (
          <p>
            My account balance was not updated after I made a deposit, what
            should I do?
          </p>
        ),
        answer: (
          <p>
            Please try to log out manually and then log back in. If it still
            does not update, please contact our customer support team so that we
            can check the status of your deposit.
          </p>
        ),
      },
      {
        question: <p>How do I make a withdrawal?</p>,
        answer: (
          <p>
            You can withdraw money from your account at any time. Please note
            that you can only withdraw money using the same method you used to
            deposit money into your account, unless you are notified otherwise.
          </p>
        ),
      },
      {
        question: <p>What is the minimum withdrawal amount?</p>,
        answer: <p>The minimum withdrawal amount is $50.</p>,
      },
      {
        question: <p>How do I make a withdrawal request?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                While you are logged in, please follow the steps below.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Click on the account icon on the top right corner of the
                  website
                </li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: <p>Will I be charged a fee for withdrawals?</p>,
        answer: (
          <p>
            No. There are no fees charged by Brother Bets. However, please note
            that depending on the payment institution you use, fees may be
            charged when transferring funds.
          </p>
        ),
      },
      {
        question: (
          <p>
            Why can't I submit a withdrawal request after clicking on{" "}
            <span className="font-bold">Withdrawal</span>?
          </p>
        ),
        answer: (
          <p>
            You may not have met the betting conditions relating to deposits and
            bonuses, in which case you will not be able to apply for a
            withdrawal. Also, if there is a withdrawal application that has not
            been completed yet, the next withdrawal cannot be made until the
            uncompleted withdrawal is completed. Although identity verification
            has been completed, if there are any errors in the registered
            information, you will not be able to apply for a withdrawal, so
            please double check that your phone number, email address, and all
            other registered information are entered correctly.
          </p>
        ),
      },
      {
        question: <p>When will my withdrawal be completed?</p>,
        answer: (
          <p>
            If all identity verification documents have been submitted, your
            application will be completed normally within 24 hours after
            receiving your application. Please note that there may be a slight
            delay depending on the withdrawal situation.
          </p>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "Account Verification",
    content: [
      {
        question: <p>Do I need to submit identity verification documents?</p>,
        answer: (
          <p>
            Yes, we ask for your identification documents to ensure that we can
            process your application smoothly. To expedite the process, please
            make sure that your registration information is always up-to-date
            and matches your identification documents. By submitting your
            identification documents, you help us provide a safer playing
            environment for you.
          </p>
        ),
      },
      {
        question: <p>Why do I need to verify my identity?</p>,
        answer: (
          <p>
            Apart from our obligation to fulfill our regulations, the process of
            account verification must be completed in order to enable the
            withdrawal feature on your account. The account verification
            process, or Know Your Customer, is the process by which your
            financial institution, bank, or payment platform verifies your
            identity.
          </p>
        ),
      },
      {
        question: <p>What kind of documents do I need to submit?</p>,
        answer: (
          <ul>
            <li>
              The following documents are usually required to verify your
              identity.
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  <ul>
                    <li className="mt-4">
                      <span className="font-bold">Identification card</span>
                    </li>
                    <li>✓ Driver's license (both sides)</li>
                    <li>✓ Passport (page with photo and page with address)</li>
                    <li>
                      ✓ Basic Resident Registration Card with photo (both sides)
                    </li>
                    <li>(Any one of the above)</li>
                    <li className="mt-4">
                      <span className="font-bold">
                        Required fields are as follows:
                      </span>
                    </li>
                    <li>
                      <ol className="list-disc ml-5">
                        <li>Face photo</li>
                        <li>Name</li>
                        <li>Address</li>
                        <li>Date of birth</li>
                        <li>Expiration date</li>
                        <li>My Number digits (in case of My Number Card</li>
                      </ol>
                    </li>
                  </ul>
                </li>
                <li className="mt-4">
                  <ul>
                    <li>
                      <span className="font-bold">
                        Proof of address (one document verifying the address
                        within 3 months from the date of issue)
                      </span>
                    </li>
                    <li>✓ Certificate of residence</li>
                    <li>
                      ✓ Water/electricity/gas bill/telephone bill with your
                      address and date of issue (if mailed)
                    </li>
                    <li>✓ Credit card statement (sent by mail)</li>
                    <li>
                      (Items other than those listed above will not be
                      considered)
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li className="mt-4">
              ※*Please take a photo of the submitted documents with a camera,
              smartphone, or mobile phone, and make sure to include all four
              corners so that the photo does not fill up the entire photo. When
              taking pictures using <span className="font-bold">flash</span>,
              please make sure that the required items are clearly readable.
              File extensions that can be uploaded are JPEG, BMP and PNG.
            </li>
            <li className="text-warning-200">
              Please upload images no larger than 5MB.
            </li>
          </ul>
        ),
      },
      {
        question: <p>How do I submit my personal identification documents?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                After logging in, please do the following:
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Tap the <span className="font-bold">Personal Center</span> in
                  the upper right corner of the website.
                </li>
                <li>
                  Tap <span className="font-bold">Identity Verification</span>.
                </li>
                <li>
                  Tap <span className="font-bold">Select File</span> and upload
                  your identification documents.
                </li>
                <li>
                  Tap <span className="font-bold">Save</span> to complete.
                </li>
              </ol>
            </li>
            <li className="mt-4">
              <span className="font-bold">How to operate on a PC</span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Click the <span className="font-bold">Personal Center</span>{" "}
                  in the upper right corner of the website.
                </li>
                <li>
                  Select <span className="font-bold">My Account</span>.
                </li>
                <li>
                  Click on{" "}
                  <span className="font-bold">Account Verification</span>.
                </li>
                <li>
                  Click on <span className="font-bold">Select File</span> and
                  upload your personal identification documents.
                </li>
                <li>
                  Click <span className="font-bold">Save</span> to complete.
                </li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>
            Do I need to submit any other documents after completing the
            identity verification?
          </p>
        ),
        answer: (
          <p>
            Please note that depending on the situation, you may be required to
            submit additional identification documents.
          </p>
        ),
      },
    ],
  },
  {
    id: 4,
    title: "Bonus",
    content: [
      {
        question: <p>How do I receive my registration bonus?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Here's how to get your registration bonus.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Log in to your account and complete your account information.
                </li>
                <li>
                  Complete KYC (identity verification) by verifying your account
                  in My Account.
                </li>
                <li>
                  From the bonus menu in My Account, tap "Receive Rewards" to
                  receive your registration bonus.
                </li>
              </ol>
            </li>
            <li>
              (Once your identity has been verified, you will be able to receive
              it.)
            </li>
          </ul>
        ),
      },
      {
        question: <p>How do I receive my deposit bonus?</p>,
        answer: (
          <p>
            nce the deposit is reflected in your account, you can receive the
            first deposit quest under the bonus item of the icon in the upper
            right corner. For quests after the second deposit quest or other
            promotional quests, please apply to chat support after your deposit
            has been reflected, and we will proceed with the presentation
            procedure. The conditions for achieving each quest and how to
            receive them are also listed on each promotion page, so please check
            those as well.
          </p>
        ),
      },
      {
        question: <p>Why can't I get a new bonus?</p>,
        answer: (
          <p>
            ou will not be able to get a new bonus if you have a remaining bonus
            balance. Please make sure that your bonus balance is zero.
          </p>
        ),
      },
      {
        question: <p>Where can I check my bonus achievements?</p>,
        answer: (
          <p>
            Tap the icon in the upper right corner of the top screen, then tap{" "}
            <span className="font-bold">[Bonuses]</span>. In the active bonuses,
            there is an item called <span className="font-bold">Rollover</span>.
            The amount on the left side is the amount you have actually bet, and
            the amount on the right side is the amount you need to withdraw or
            cash out your bets. Each bonus has a different valid bet amount and
            game category, so please check the respective promotion page for
            details.
          </p>
        ),
      },
      {
        question: (
          <p>I accidentally received a deposit bonus. Can I cancel it?</p>
        ),
        answer: (
          <p>
            Please contact our customer support immediately after making a
            deposit and do not play any games. It cannot be withdrawn after you
            have started playing.
          </p>
        ),
      },
      {
        question: <p>The remaining fraction of my bonus has disappeared.</p>,
        answer: (
          <p>
            The bonus balance will be automatically deleted when the amount is
            less than 1USD. The purpose of this setting is to save you the
            trouble of having to play a game just to get your balance down to
            zero when you have a small amount of bonus left. Many other casinos
            have this feature as well.
          </p>
        ),
      },
      /* {
        question: (
          <p>
            Does the percentage that is counted as the wager amount differ
            depending on the game when fulfilling the quest conditions?
          </p>
        ),
        answer: <p>It depends on the game as shown below.</p>,
      }, */
      {
        question: <p>Are there any deadlines for bonuses or quests?</p>,
        answer: (
          <p>
            There is an expiration date for receiving and cashing in (rollover).
            Each bonus has a different expiration date, so please check the
            promotion page for details.
          </p>
        ),
      },
      {
        question: <p>Is there a specific withdrawal limit for bonuses?</p>,
        answer: (
          <p>
            Some bonuses have a specific amount that can be withdrawn. This is
            stated as the maximum withdrawal amount, please check the campaign
            terms and conditions.
          </p>
        ),
      },
      {
        question: <p>Is there a specific maximum bet for the bonus?</p>,
        answer: (
          <p>
            Each bonus has a different betting limit. You can bet over the
            betting limit, but it will not count towards your withdrawal
            requirements. However, if you use your bonus to play with a wager
            amount that is not reflected in the rollover, and your subsequent
            play is determined to be abusive or fraudulent, your account may be
            restricted, including pending withdrawal requests, until we
            investigate and know the outcome of the investigation.
          </p>
        ),
      },
    ],
  },
];

export const faqDE = [
  {
    id: 0,
    title: "Allgemein",
    content: [
      {
        question: <p>Wie registriere ich mich als neuer Benutzer?</p>,
        answer: (
          <p>
            Die Registrierung ist kostenlos. Klicken{" "}
            <span className="font-bold">Melden Sie</span> sich oben rechts auf
            der Webseite an und geben Sie die angeforderten Informationen an.
            Sie müssen maximale Einsatzlimits pro Tag, Woche und Monat
            festlegen. Bitte achten Sie darauf, realistische und sinnvolle
            Limits zu setzen.
          </p>
        ),
      },
      {
        question: <p>Ich habe keine Bestätigungs-E-Mail erhalten.</p>,
        answer: (
          <p>
            Bitte überprüfen Sie, ob die von Ihnen angegebene E-Mail-Adresse
            richtig ist und dass sich die Bestätigungs-E-Mail nicht in Ihrem
            Spam-Ordner befindet. Wenn Sie diese dennoch nicht erhalten,
            empfehlen wir, eine andere E-Mail-Adresse zu verwenden. Sie können
            Ihre E-Mail-Adresse jederzeit ändern, indem Sie uns per Live-Chat
            auf unserer Website kontaktieren.
          </p>
        ),
      },
      {
        question: <p>Kann ich mehr als ein Konto gleichzeitig registrieren?</p>,
        answer: (
          <p>
            Kunden können beim Brother Bets Casino nur ein Mitgliedskonto
            eröffnen. Wenn Sie mehrere Konten eröffnen oder versuchen zu
            eröffnen, behält sich Brother Bets das Recht vor, ein paar oder alle
            erstellten Konten zu schließen und alle Gewinne dieser Konten für
            ungültig zu erklären. Wenn wir uns entscheiden, ein Konto offen zu
            lassen, wird es das erste Konto sein, das Sie bei Brother
            registriert haben Einsätze und alle verbleibenden Einzahlungen
            werden auf dieses Konto übertragen. Brother Bets zieht eine
            Verwaltungsgebühr von 10 % oder 35 $ ab, je nachdem, welcher Betrag
            höher ist, für jedes registrierte Konto sowie andere geltende
            Abgaben in Übereinstimmung mit den Nutzungsbedingungen von Brother
            Bets.
          </p>
        ),
      },
      {
        question: <p>Habt ihr die erforderlichen Lizenzen für den Betrieb?</p>,
        answer: (
          <p>
            Diese Website verfügt über eine Tobique Gaming-Lizenz und wird unter
            strenger Überwachung betrieben, damit Sie mit Vertrauen in uns
            spielen können.
          </p>
        ),
      },
      {
        question: <p>Welche Arten von Spielen bietet ihr an?</p>,
        answer: (
          <p>Our site offers casino games, live casino, and slot games.</p>
        ),
      },
      {
        question: <p>Was soll ich tun, wenn ich eine Frage habe?</p>,
        answer: (
          <p>
            Bitte kontaktieren Sie uns per Live-Chat, der rund um die Uhr
            verfügbar ist auf Deutsch. Wenn Sie auf{" "}
            <span className="font-bold">Live-Chat</span> klicken, unten rechts
            auf der Website, wird ein Mitarbeiter antworten. Alternativ
            kontaktieren Sie uns bitte per E-Mail über{" "}
            <a className="font-bold" href="mailto: support@brotherbets.com">
              support@brotherbets.com
            </a>
            .
          </p>
        ),
      },
    ],
  },
  {
    id: 1,
    title: "Konto",
    content: [
      {
        question: (
          <p>Ich habe mein Passwort vergessen. Wie kann ich es zurücksetzen?</p>
        ),
        answer: (
          <p>
            Anmeldeformular{" "}
            <span className="font-bold">
              ≪Passwort vergessen? Klicken Sie auf ≫
            </span>{" "}
            und tragen Sie Ihre registrierte E-Mail-Adresse ein. Ein Link zur
            neuen Passworteinstellung wird an Ihre registrierte E-Mail-Adresse
            geschickt, also setzen Sie bitte Ihr Passwort von dort aus zurück.
          </p>
        ),
      },
      {
        question: <p>Ich habe ein Problem beim Einloggen. Was soll ich tun?</p>,
        answer: (
          <p>
            Bitte versuchen Sie erneut, Ihre richtige E-Mail-Adresse und Ihr
            Passwort einzugeben. Wenn das Problem weiterhin besteht, wenden Sie
            sich bitte an unseren Kundendienst.
          </p>
        ),
      },
      {
        question: (
          <p>
            Kann ich meine Registrierungsdaten aktualisieren, nachdem ich mich
            registriert habe?
          </p>
        ),
        answer: (
          <p>
            Um Ihre Registrierungsinformationen zu aktualisieren, klicken Sie
            bitte auf das
            <span className="font-bold">Kontosymbol</span> in der rechten oberen
            Ecke der Website, wählen Sie{" "}
            <span className="font-bold">Mein Konto</span> und nehmen Sie
            Änderungen Ihrer Kontoinformationen vor. Es können jedoch nur Ihre
            Adresse und Ihre Telefonnummer aktualisiert werden. Wenn Sie andere
            Informationen ändern müssen, wenden Sie sich bitte an unseren
            Kundendienst.
          </p>
        ),
      },
      {
        question: <p>Kann ich meinen Benutzernamen ändern?</p>,
        answer: (
          <p>
            Sie können Ihre Benutzer-ID nach der Erstellung eines Kontos nicht
            mehr ändern. Bitte berücksichtigen Sie dies bei der Registrierung
            Ihrer Benutzer-ID.
          </p>
        ),
      },
      {
        question: <p>Kann ich meine registrierte E-Mail-Adresse ändern?</p>,
        answer: (
          <p>
            Ja, das können Sie. Sie können Ihre E-Mail-Adresse ändern, indem Sie
            uns über unser Live-Chat-System kontaktieren.
          </p>
        ),
      },
      {
        question: <p>Ist es möglich, die Währungsanzeige auf Yen zu ändern?</p>,
        answer: (
          <p>
            Die verfügbaren Währungen sind USD, Euro, GBP und die bekanntesten
            Krypto- Währungen.
          </p>
        ),
      },
      {
        question: <p>Wo kann ich mein Guthaben einsehen?</p>,
        answer: (
          <p>Your available balance is displayed in the upper right corner.</p>
        ),
      },
      {
        question: (
          <p>Where can I check the details of my deposits and withdrawals?</p>
        ),
        answer: (
          <p>
            Wenn Sie Informationen zu Ihren Ein- und Auszahlungen einsehen
            möchten, klicken Sie auf{" "}
            <span className="font-bold">Transaktionsverlauf</span> in{" "}
            <span className="font-bold">Mein Konto</span>, um die Details zu
            Ihren Ein- und Auszahlungen anzeigen zu lassen.
          </p>
        ),
      },
      {
        question: (
          <p>Sind meine Transaktionen/personenbezogenen Daten sicher?</p>
        ),
        answer: (
          <p>
            Ja, Brother Bets verschlüsselt alle Transaktionen, personenbezogene
            Daten und alle anderen Informationen über die Secure Sockets Layer
            (SSL) 256-Bit Kommunikation. Wir verwenden nur das höchste Level der
            Datenverschlüsselung, um sicherzustellen, dass Ihre Informationen
            sicher sind.
          </p>
        ),
      },
      {
        question: (
          <p>Kann ich die Selbstregulierung vor Ablauf der Frist entfernen?</p>
        ),
        answer: (
          <p>
            Die Website kann die selbst auferlegten Einschränkungen nicht
            aufheben, bis die von Ihnen selbst festgelegte Frist vorbei ist.
          </p>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "Zahlungen",
    content: [
      /* {
        question: <p>Welche Einzahlungsmethoden bieten Sie an?</p>,
        Antwort: <p>Wir bieten folgende Einzahlungsmethoden an:</p>,
      }, */
      {
        question: <p>Wie zahle ich ein?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                So zahlen Sie über Ihr Handy ein:
              </span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Melden Sie sich bei Ihrem Konto an</li>
              <li>
                Tippen Sie auf das „Kontosymbol“ in der oberen rechten Ecke der
                Website
              </li>
              <li>Tippen Sie auf „Einzahlen“</li>
              <li>Wählen Sie eine Einzahlungsmethode aus</li>
              <li>Geben Sie den Betrag an, den Sie einzahlen möchten</li>
              <li>
                Tippen Sie auf „Einzahlen“, um Ihre Einzahlung abzuschließen
              </li>
              <li>
                Schließen Sie en Prozess entsprechend auf der Einzahlungsmethode
                ab und schließen Sie die Einzahlung ab
              </li>
            </ol>
            <li className="mt-4">
              <span className="font-bold">
                So zahlen Sie über einen PC ein:
              </span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Melden Sie sich bei Ihrem Konto an</li>
              <li>Klicken Sie oben rechts auf der Website auf „Einzahlen“</li>
              <li>
                Befolgen Sie die Schritte 4-7 wie oben angegeben, um Ihre
                Einzahlung abzuschließen
              </li>
            </ol>
            <li className="mt-4">
              ※ Bitte beachten Sie, dass Sie je nach Zahlungsmethode auf die
              entsprechende Zahlungsseite weitergeleitet werden. Auch je nach
              Finanzinstitut, das Sie verwenden, werden Sie möglicherweise
              aufgefordert, Ihre Identität zu bestätigen.
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>Can I make deposits and withdrawals on weekends and holidays?</p>
        ),
        answer: (
          <p>Yes. All deposit and withdrawal methods are available 24/7</p>
        ),
      },
      /* {
        Frage: (<p>Wie hoch ist der Mindesteinzahlungsbetrag?</p>),
        Antwort: „",
      }, */
      /* {
        Frage: (<p>Wird für die Einzahlung eine Gebühr erhoben?</p>),
        Antwort: „",
      }, */
      {
        question: (
          <p>Ist es sicher, meine Kreditkarte bei Brother Bets zu verwenden?</p>
        ),
        answer: (
          <p>
            Ja. Wir tun unser Bestes, um personenbezogene Daten zu schützen, die
            von unseren Kunden zur Verfügung gestellt werden. Die
            Sicherheitsseite unserer Website ist verschlüsselt mit einer Secure
            Sockets Layer (SSL) 256-Bit-Kommunikation. Kreditkarten-
            Informationen sind verschlüsselt und werden vom Brother Bets System
            nicht gespeichert , sondern direkt an das kreditkartenverarbeitende
            Unternehmen gesendet und zwar über einen von Brother Bets
            bereitgestellten verschlüsselten Kanal. Sie werden in Form eines{" "}
            <span className="font-bold">„Token“</span> gespeichert, um als
            Referenz für zukünftige Transaktionen verwendet werden zu können.
          </p>
        ),
      },
      {
        question: (
          <p>
            Mein Kontostand wurde nicht aktualisiert, nachdem ich eine
            Einzahlung getätigt hatte. Was soll ich tun?
          </p>
        ),
        answer: (
          <p>
            Bitte versuchen Sie, sich manuell abzumelden und melden Sie sich
            dann wieder an. Wenn er immer noch nicht aktualisiert wurde, wenden
            Sie sich bitte an unseren Kundendienst, damit wir den Status Ihrer
            Einzahlung überprüfen können.
          </p>
        ),
      },
      {
        question: <p>Wie kann ich eine Auszahlung vornehmen?</p>,
        answer: (
          <p>
            Sie können jederzeit Geld von Ihrem Konto abheben. Bitte beachten
            Sie, dass Sie nur mit der gleichen Methode Geld abheben können, mit
            der Sie Geld auf Ihr Konto einzahlen, es sei denn, Sie werden
            anderweitig benachrichtigt.
          </p>
        ),
      },
      {
        question: <p>Wie hoch ist der Mindestauszahlungsbetrag?</p>,
        answer: <p>Mindestauszahlungsbetrag: 50 $.</p>,
      },
      {
        question: <p>Wie stelle ich eine Auszahlungsanfrage?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Während Sie angemeldet sind, befolgen Sie bitte die folgenden
                Schritte.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Klicken Sie auf das Kontosymbol in der oberen rechten Ecke der
                  Website
                </li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: <p>Wird für Auszahlungen eine Gebühr berechnet?</p>,
        answer: (
          <p>
            Nein. Brother Bets erhebt keine Gebühren. Bitte beachten Sie jedoch,
            dass je nach Zahlungsinstitut, das Sie verwenden, Gebühren bei der
            Überweisung von Geldern in Rechnung gestellt werden könnten.
          </p>
        ),
      },
      {
        question: (
          <p>
            Warum kann ich keine Auszahlung beantragen, nachdem ich auf{" "}
            <span className="font-bold">Auszahlung</span> geklickt habe?
          </p>
        ),
        answer: (
          <p>
            Sie haben möglicherweise die Wettbedingungen in Bezug auf
            Einzahlungen und Boni noch nicht erfüllt. In diesem Fall können Sie
            keinen Antrag auf eine Auszahlung stellen. Wenn es einen
            Auszahlungsantrag gibt, der noch nicht abgeschlossen ist, kann die
            nächste Auszahlung erst erfolgen, wenn die ausstehende Auszahlung
            abgeschlossen wurde. Obwohl die Identitätsprüfung abgeschlossen ist,
            können Sie keine Auszahlung beantragen, wenn es bei den
            eingetragenen Informationen einen Fehler gibt. Bitte überprüfen Sie,
            ob Ihre Telefonnummer, E-Mail-Adresse und alle weiteren
            registrierten Informationen richtig eingegeben wurden.
          </p>
        ),
      },
      {
        question: <p>Wann wird meine Auszahlung abgeschlossen sein?</p>,
        answer: (
          <p>
            Wenn alle Identitätsüberprüfungsdokumente eingereicht wurden, wird
            Ihr Antrag normalerweise innerhalb von 24 Stunden nach dessen
            Eingang abgeschlossen. Bitte beachten Sie, dass es je nach
            Auszahlungssituation zu einer leichten Verzögerung kommen kann.
          </p>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "Kontoverifizierung",
    content: [
      {
        question: <p>Muss ich Dokumente zur Identitätsprüfung einreichen?</p>,
        answer: (
          <p>
            Ja, wir bitten Sie um Ihre Ausweisdokumente, um sicherzustellen,
            dass wir Ihren Antrag reibungslos bearbeiten können. Um den Prozess
            zu beschleunigen, bitten wir Sie, sicherzustellen, dass Ihre
            Registrierungsdaten immer auf dem neuesten Stand sind und mit Ihren
            Ausweisdokumenten übereinstimmen. Durch das Einreichen Ihrer
            Ausweisdokumente, helfen Sie uns, eine sicherere Spielumgebung für
            Sie zu ermöglichen.
          </p>
        ),
      },
      {
        question: <p>Warum muss ich meine Identität verifizieren?</p>,
        answer: (
          <p>
            Abgesehen von unserer Verpflichtung, Regulierungsvorschriften zu
            erfüllen, muss der Prozess der Kontoverifizierung abgeschlossen
            sein, um die Auszahlungsfunktion auf Ihrem Konto freizuschalten. Die
            Kontoverifizierung oder Know Your Customer ist der Prozess, mit dem
            Ihr Finanzinstitut, Ihre Bank oder Ihre Zahlungsplattform Ihre
            Identität zu bestätigen.
          </p>
        ),
      },
      {
        question: <p>Welche Art von Dokumenten muss ich einreichen?</p>,
        answer: (
          <ul>
            <li>
              Die folgenden Dokumente sind in der Regel erforderlich, um Ihre
              Identität zu bestätigen.
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  <ul>
                    <li className="mt-4">
                      <span className="font-bold">Ausweisdokument</span>
                    </li>
                    <li>✓ Führerschein (beidseitig)</li>
                    <li>✓ Reisepass (Seite mit Foto und Seite mit Adresse)</li>
                    <li>✓ Meldebescheinigung mit Foto (beidseitig)</li>
                    <li>(Eine der oben genannten Optionen)</li>
                    <li className="mt-4">
                      <span className="font-bold">
                        Die erforderlichen Felder lauten wie folgt:
                      </span>
                    </li>
                    <li>
                      <ol className="list-disc ml-5">
                        <li>Foto des Gesichts</li>
                        <li>Name</li>
                        <li>Adresse</li>
                        <li>Geburtsdatum</li>
                        <li>Ablaufdatum</li>
                        <li>My Number Ziffern (im Falle von My Number Card</li>
                      </ol>
                    </li>
                  </ul>
                </li>
                <li className="mt-4">
                  <ul>
                    <li>
                      <span className="font-bold">
                        Adressnachweis (ein Dokument, das die Adresse bestätigt
                        und vor weniger als 3 Monaten ausgestellt wurde)
                      </span>
                    </li>
                    <li>✓ Wohnsitzbescheinigung</li>
                    <li>
                      ✓ Wasser-/Strom-/Gasrechnung/Telefonrechnung mit Ihrer
                      Adresse und Ausstellungsdatum (falls per Post versandt)
                    </li>
                    <li>✓ Kreditkartenabrechnung (per Post versandt)</li>
                    <li>
                      (Andere als die oben aufgeführten Optionen werden nicht
                      anerkannt)
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li className="mt-4">
              ※*Bitte machen Sie ein Foto der eingereichten Dokumente mit einer
              Kamera, einem Smartphone oder einem Handy und stellen Sie sicher,
              dass alle vier Ecken zu sehen sind, sodass das Dokument nicht das
              gesamte Bild einnimmt. Wenn Sie mit{" "}
              <span className="font-bold">Blitz</span> fotografieren, stellen
              Sie bitte sicher, dass die erforderlichen Elemente gut lesbar
              sind. Dateiendungen, die hochgeladen werden können, sind JPEG, BMP
              und PNG.
            </li>
            <li className="text-warning-200">
              Bitte laden Sie Bilder hoch, die nicht größer als 5 MB sind.
            </li>
          </ul>
        ),
      },
      {
        question: <p>Wie reiche ich meine persönlichen Dokumente ein?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Nach dem Einloggen gehen Sie bitte wie folgt vor:
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Tippen Sie auf{" "}
                  <span className="font-bold">Persönliche Angaben</span> in der
                  oberen rechten Ecke der Website.
                </li>
                <li>
                  Tippen Sie auf{" "}
                  <span className="font-bold">Identitätsnachweis</span>.
                </li>
                <li>
                  Tippen Sie auf{" "}
                  <span className="font-bold">Datei auswählen</span> und laden
                  Sie Ihre Dokumente hoch.
                </li>
                <li>
                  Tippen Sie zum Abschluss auf{" "}
                  <span className="font-bold">Speichern</span>.
                </li>
              </ol>
            </li>
            <li className="mt-4">
              <span className="font-bold">So geht es am PC</span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Klicken Sie auf{" "}
                  <span className="font-bold">Persönliche Angaben</span> in der
                  oberen rechten Ecke der Website.
                </li>
                <li>
                  Wählen Sie <span className="font-bold">Mein Konto</span>.
                </li>
                <li>
                  Klicken Sie auf{" "}
                  <span className="font-bold">Kontoverifizierung</span>.
                </li>
                <li>
                  Klicken Sie auf{" "}
                  <span className="font-bold">Datei auswählen</span> und laden
                  Sie Ihre persönlichen Dokumente hoch.
                </li>
                <li>
                  Klicken Sie zum Abschluss auf{" "}
                  <span className="font-bold">Speichern</span>.
                </li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>
            Muss ich nach Abschluss der Identitätsprüfung noch weitere Dokumente
            einreichen?
          </p>
        ),
        answer: (
          <p>
            Bitte beachten Sie, dass Sie je nach Situation möglicherweise
            zusätzliche Dokumente einzureichen müssen.
          </p>
        ),
      },
    ],
  },
  {
    id: 4,
    title: "Bonus",
    content: [
      {
        question: <p>Wie erhalte ich meinen Registrierungsbonus?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                So erhalten Sie Ihren Anmeldebonus.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Melden Sie sich bei Ihrem Konto an und vervollständigen Sie
                  Ihre Kontoinformationen.
                </li>
                <li>
                  Schließen Sie den KYC-Prozess (Identitätsprüfung) ab, indem
                  Sie Ihr Konto über Mein Konto verifizieren.
                </li>
                <li>
                  Tippen Sie im Bonusmenü in Mein Konto auf „Prämien erhalten“,
                  um Ihren Anmeldebonus zu erhalten.
                </li>
              </ol>
            </li>
            <li>
              (Sobald Ihre Identität verifiziert wurde, können Sie ihn
              erhalten.)
            </li>
          </ul>
        ),
      },
      {
        question: <p>Wie erhalte ich meinen Einzahlungsbonus?</p>,
        answer: (
          <p>
            Sobald die Einzahlung in Ihrem Konto angezeigt wird, können Sie die
            erste Einzahlungsmission unter dem Bonussymbol in der oberen rechten
            Ecke erhalten. Für Missionen nach der zweiten Einzahlungsmission
            oder anderen Aktionsmissionen, wenden Sie sich bitte nachdem Ihre
            Einzahlung angezeigt wird den Chat-Support und wir fahren mit dem
            Präsentationsprozess fort. Die Bedingungen für das Erreichen jeder
            Mission und wie man diese erhält, sind auch auf jeder Aktionsseite
            aufgeführt, überprüfen Sie bitte auch diese.
          </p>
        ),
      },
      {
        question: <p>Warum kann ich keinen neuen Bonus erhalten?</p>,
        answer: (
          <p>
            Sie können keinen neuen Bonus erhalten, solange Sie noch
            Bonusguthaben haben. Bitte vergewissern Sie sich, dass Ihr
            Bonusguthaben Null ist.
          </p>
        ),
      },
      {
        question: <p>Wo kann ich meine Bonuserfolge einsehen?</p>,
        answer: (
          <p>
            Tippen Sie auf das Symbol in der oberen rechten Ecke des Bildschirms
            und dann auf <span className="font-bold">[Bonuses]</span>. In den
            aktiven Boni gibt es ein Element namens{" "}
            <span className="font-bold">Umsatz</span>. Der Betrag auf der linken
            Seite ist der Betrag, den Sie tatsächlich gesetzt haben, und der
            Betrag auf der rechten Seite ist der, den Sie abheben oder Ihre
            Einsätze auszahlen müssen. Der gültige Einsatzbetrag und die
            Spielkategorie sind bei jedem Bonus anders. Überprüfen Sie bitte die
            jeweilige Aktionsseite für weitere Angaben.
          </p>
        ),
      },
      {
        question: (
          <p>I accidentally received a deposit bonus. Can I cancel it?</p>
        ),
        answer: (
          <p>
            Bitte wenden Sie sich sofort an unseren Kundendienst, nachdem Sie
            eine Einzahlung getätigt haben und spielen Sie keine Spiele. Sie
            kann nicht ausgezahlt werden, nachdem Sie mit dem Spielen begonnen
            haben.
          </p>
        ),
      },
      {
        question: <p>Der Rest meines Bonus ist verschwunden.</p>,
        answer: (
          <p>
            Das Bonusguthaben wird automatisch gelöscht, wenn der Betrag bei
            weniger als 1 USD liegt. Der Zweck dieser Einstellung besteht darin,
            Ihnen die Schwierigkeiten zu ersparen, ein Spiel spielen zu müssen,
            nur um Ihr Guthaben auf Null zu bringen, wenn Sie nur noch einen
            kleinen Bonusbetrag übrig haben. Viele andere Casinos haben diese
            Funktion ebenfalls.
          </p>
        ),
      },
      /* {
        question: (
          <p>
            Weicht der Prozentsatz, der als Wetteinsatz gezählt wird,
            je nach Spiel bei Erfüllung der Missionsbedingungen ab?
          </p>
        ),
        Antwort: <p>Das hängt vom Spiel ab, wie unten angegeben.</p>,
      }, */
      {
        question: <p>Gibt es Fristen für Boni oder Missionen?</p>,
        answer: (
          <p>
            Es gibt ein Ablaufdatum für den Erhalt und die Einlösung (Umsatz).
            Jeder Bonus hat ein anderes Ablaufdatum, überprüfen Sie daher bitte
            die Angaben, die Sie auf der Aktionsseite finden.
          </p>
        ),
      },
      {
        question: <p>Gibt es ein Auszahlungslimit für Boni?</p>,
        answer: (
          <p>
            Einige Boni haben einen bestimmten Betrag, der ausgezahlt werden
            kann. Dieser wird als maximaler Auszahlungsbetrag angegeben.
            Überprüfen Sie bitte die Geschäftsbedingungen der Kampagne.
          </p>
        ),
      },
      {
        question: <p>Gibt es einen maximalen Einsatz für den Bonus?</p>,
        answer: (
          <p>
            Jeder Bonus hat ein anderes Einsatzlimit. Sie können mehr als das
            Wettlimit setzen, was jedoch nicht für die Auszahlungsanforderungen
            angerechnet wird. Wenn Sie jedoch Ihren Bonus verwenden und mit
            einem Einsatzbetrag spielen, der sich nicht im Umsatz widerspiegelt,
            und Ihre anschließendes Spiel als missbräuchlich oder betrügerisch
            eingestuft wird, kann Ihr Konto eingeschränkt werden, einschließlich
            ausstehender Auszahlungsanfragen, bis wir dies überprüfen und das
            Ergebnis der Untersuchung kennen.
          </p>
        ),
      },
    ],
  },
];

export const faqES = [
  {
    id: 0,
    title: "General",
    content: [
      {
        question: <p>¿Cómo me registro como usuario nuevo?</p>,
        answer: (
          <p>
            El registro es gratuito. Pulsa{" "}
            <span className="font-bold">Regístrate</span> en la parte superior
            derecha de la página web y proporciona la información solicitada.
            Tendrás que configurar límites máximos de apuestas por día, semana y
            mes. Por favor, asegúrate de introducir límites realistas y
            sensatos.
          </p>
        ),
      },
      {
        question: <p>No he recibido un correo electrónico de confirmación.</p>,
        answer: (
          <p>
            Comprueba que la dirección de correo electrónico que has introducido
            es correcta y que el correo electrónico de confirmación no está en
            tu buzón de correo no deseado. Si todavía no puedes recibirlo,
            recomendamos usar otro correo electrónico. Puedes cambiar tu
            dirección de correo electrónico en cualquier momento contactando con
            nosotros a través del chat en vivo en nuestro sitio web.
          </p>
        ),
      },
      {
        question: <p>¿Puedo registrar más de una cuenta a la vez?</p>,
        answer: (
          <p>
            Los clientes solo pueden abrir una cuenta de miembro en el casino
            Brother Bets. Si abres o intentas abrir varias cuentas, Brother Bets
            se reserva el derecho de cerrar algunas o todas las cuentas creadas
            y anular todas las ganancias de esas cuentas. Si decidimos conservar
            una cuenta abierta, será la primera cuenta que registres con Brother
            Bets y los depósitos restantes se transferirán a esa cuenta.
            35$Brother Bets cargará una tarifa administrativa del 10% o 35$, lo
            que sea mayor, por cada cuenta registrada, así como otros gravámenes
            aplicables de acuerdo con los Términos de uso de Brother Bets.
          </p>
        ),
      },
      {
        question: <p>¿Tenéis las licencias necesarias para operar?</p>,
        answer: (
          <p>
            Este sitio tiene una licencia de Tobique Gaming y se opera bajo
            estrictas auditorías, para que puedas jugar con confianza.
          </p>
        ),
      },
      {
        question: <p>¿Qué tipos de juegos ofrecéis?</p>,
        answer: (
          <p>Our site offers casino games, live casino, and slot games.</p>
        ),
      },
      {
        question: <p>¿Qué debo hacer si tengo una consulta?</p>,
        answer: (
          <p>
            Contacta con nosotros a través del chat en vivo, que está disponible
            las 24 horas del día en español. Si haces clic en{" "}
            <span className="font-bold">Chat en vivo</span> en la parte inferior
            derecha del sitio web, un agente responderá. Como alternativa,
            contacta con nosotros por correo electrónico en{" "}
            <a className="font-bold" href="mailto: support@brotherbets.com">
              support@brotherbets.com
            </a>
            .
          </p>
        ),
      },
    ],
  },
  {
    id: 1,
    title: "Cuenta",
    content: [
      {
        question: <p>He olvidado mi contraseña. ¿Cómo puedo restablecerla?</p>,
        answer: (
          <p>
            Formulario de inicio de sesión{" "}
            <span className="font-bold">
              ≪¿Has olvidado tu contraseña? Pulsa ≫
            </span>{" "}
            e introduce tu dirección de correo electrónico registrada. Un enlace
            nuevo de configuración de contraseña se enviará a tu dirección de
            correo electrónico registrada, así que restablece tu contraseña
            desde allí.
          </p>
        ),
      },
      {
        question: <p>Tengo problemas para iniciar sesión. ¿Qué debo hacer?</p>,
        answer: (
          <p>
            Intenta introducir de nuevo tu dirección de correo electrónico y
            contraseña correctas. Si el problema persiste, contacta con atención
            al cliente.
          </p>
        ),
      },
      {
        question: (
          <p>
            ¿Puedo actualizar mi información de registro una vez que me haya
            registrado?
          </p>
        ),
        answer: (
          <p>
            Para actualizar tu información de registro, haz clic en el
            <span className="font-bold">Icono de Cuenta</span> en la esquina
            superior derecha del sitio web, selecciona{" "}
            <span className="font-bold">Mi cuenta</span> y realiza cambios en la
            información de tu cuenta. Sin embargo, solo puedes actualizar tu
            dirección y teléfono. Si necesitas cambiar cualquier otra
            información, contacta con nuestro servicio de atención al cliente.
          </p>
        ),
      },
      {
        question: <p>¿Puedo cambiar mi nombre de usuario?</p>,
        answer: (
          <p>
            No puedes cambiar tu ID de usuario después de crear una cuenta, así
            que por favor ten en cuenta tu ID de usuario al registrarte.
          </p>
        ),
      },
      {
        question: (
          <p>¿Puedo cambiar mi dirección de correo electrónico registrada?</p>
        ),
        answer: (
          <p>
            Sí, puedes. Puedes cambiar tu dirección de correo electrónico
            poniéndote en contacto con nosotros a través de nuestro sistema de
            chat en vivo.
          </p>
        ),
      },
      {
        question: <p>¿Es posible cambiar la pantalla de la divisa a yenes?</p>,
        answer: (
          <p>
            Las divisas disponibles son USD, Euros GBP y las principales
            criptomonedas.
          </p>
        ),
      },
      {
        question: <p>¿Dónde puedo consultar mi saldo?</p>,
        answer: (
          <p>Your available balance is displayed in the upper right corner.</p>
        ),
      },
      {
        question: (
          <p>Where can I check the details of my deposits and withdrawals?</p>
        ),
        answer: (
          <p>
            Si quieres comprobar los detalles de tus depósitos y retiros, pulsa
            en <span className="font-bold">Historial de transacciones</span> en{" "}
            <span className="font-bold">Mi cuenta</span> para mostrar los
            detalles de tus depósitos y retiros.
          </p>
        ),
      },
      {
        question: <p>¿Mi transacción/información personal está segura?</p>,
        answer: (
          <p>
            Sí, Brother Bets encripta todas las transacciones y la información
            personal y el resto de información utilizando Secure Sockets Layer
            (SSL) de 256 bits como comunicación. Utilizamos solo el nivel más
            alto de cifrado de datos para garantizar que tu información esté
            segura.
          </p>
        ),
      },
      {
        question: (
          <p>¿Puedo eliminar la autorregulación antes de la fecha límite?</p>
        ),
        answer: (
          <p>
            El sitio web no podrá eliminar las restricciones autoimpuestas hasta
            que la fecha límite que estableciste por ti mismo haya llegado.
          </p>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "Pagos",
    content: [
      /* {
        question: <p>¿Qué métodos de depósito ofrecéis?</p>,
        respuesta: <p>Ofrecemos los siguientes métodos de depósito:</p>,
      }, */
      {
        question: <p>¿Cómo hago un depósito?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Cómo hacer un depósito en tu teléfono móvil:
              </span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Inicia sesión en tu cuenta</li>
              <li>
                Toca el "Icono de cuenta" en la esquina superior derecha del
                sitio web
              </li>
              <li>Toca "Depositar"</li>
              <li>Selecciona un método de depósito</li>
              <li>Introduce el importe que deseas depositar</li>
              <li>Toca "Hacer depósito" para completarlo</li>
              <li>
                Completa el procedimiento según el método de depósito y finaliza
                el depósito
              </li>
            </ol>
            <li className="mt-4">
              <span className="font-bold">
                Cómo hacer un depósito en un PC:
              </span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Inicia sesión en tu cuenta</li>
              <li>
                Haz clic en "Depositar" en la esquina superior derecha del sitio
                web
              </li>
              <li>Sigue los pasos 4-7 anteriores para completar tu depósito</li>
            </ol>
            <li className="mt-4">
              ※ Ten en cuenta que, dependiendo de tu método de pago, es posible
              que se te redirija al sitio de pago correspondiente. Además,
              dependiendo de la institución financiera que uses, se te puede
              pedir que verifiques tu identidad.
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>Can I make deposits and withdrawals on weekends and holidays?</p>
        ),
        answer: (
          <p>Yes. All deposit and withdrawal methods are available 24/7</p>
        ),
      },
      /* {
        pregunta: (<p>¿Cuál es el importe mínimo de depósito?</p>),
        respuesta: "",
      }, */
      /* {
        pregunta: (<p>¿Existe una tarifa por realizar un depósito?</p>),
        respuesta: "",
      }, */
      {
        question: <p>¿Es seguro usar mi tarjeta de crédito en Brother Bets?</p>,
        answer: (
          <p>
            Sí. Hacemos todo lo posible para proteger la información personal
            proporcionada por nuestros clientes. La página de seguridad de
            nuestro sitio web está encriptada utilizando comunicaciones de 256
            bits de Secure Sockets Layer (SSL). La información de la tarjeta de
            crédito está encriptada y no se almacena en el sistema de Brother
            Bets , pero se envía directamente a la empresa de procesamiento de
            tarjetas de crédito proporcionada por Brother Bets a través de un
            canal encriptado. Se almacena en forma de un{" "}
            <span className="font-bold">"token"</span> para ser utilizado como
            referencia para futuras transacciones.
          </p>
        ),
      },
      {
        question: (
          <p>
            El saldo de mi cuenta no se actualizó después de hacer un depósito,
            ¿qué debería hacer?
          </p>
        ),
        answer: (
          <p>
            Por favor, intenta cerrar sesión manualmente y luego vuelve a
            iniciar sesión. Si todavía no se actualiza, contacta con nuestro
            equipo de atención al cliente para que puedes comprobar el estado de
            tu depósito.
          </p>
        ),
      },
      {
        question: <p>¿Cómo hago un retiro?</p>,
        answer: (
          <p>
            Puedes retirar dinero de tu cuenta en cualquier momento. Ten en
            cuenta que solo puedes retirar dinero usando el mismo método que
            usaste para depositar dinero en tu cuenta, a menos que se te
            notifique lo contrario.
          </p>
        ),
      },
      {
        question: <p>¿Cuál es el importe mínimo de retirada?</p>,
        answer: <p>El importe mínimo de retirada es de 50$.</p>,
      },
      {
        question: <p>¿Cómo hago una solicitud de retirada?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Cuando hayas iniciado sesión, sigue los pasos a continuación.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Pulsa en el icono de la cuenta situado en la esquina superior
                  derecha del sitio web
                </li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: <p>¿Se me cobrará una tarifa por retiros?</p>,
        answer: (
          <p>
            No. Brother Bets no cobra tarifas. Sin embargo, ten en cuenta que
            dependiendo de la institución de pago que uses, se pueden cobrar
            tarifas al transferir fondos.
          </p>
        ),
      },
      {
        question: (
          <p>
            ¿Por qué no puedo enviar una solicitud de retirada después de hacer
            clic en <span className="font-bold"></span>Retirada?
          </p>
        ),
        answer: (
          <p>
            Es posible que no hayas cumplido con las condiciones de apuestas
            relacionadas con los depósitos y bonos, en cuyo caso no podrás
            solicitar una retirada. Además, si hay una solicitud de retiro que
            no se ha completado todavía, el próximo retiro no se puede hacer
            hasta que el retiro incompleto se haya completado. Aunque la
            verificación de identidad se ha completado, si hay algún error en la
            información de registro, no podrás solicitar un retiro, por lo que
            por favor, comprueba que tu número de teléfono, dirección de correo
            electrónico y resto de información registrada se introducen
            correctamente.
          </p>
        ),
      },
      {
        question: <p>¿Cuándo se completará mi retiro?</p>,
        answer: (
          <p>
            Si se han enviado todos los documentos de verificación de identidad,
            tu solicitud se completará normalmente en las 24 horas posteriores a
            recibir tu solicitud. Ten en cuenta que puede haber un ligero
            retraso dependiendo de la situación de retiro.
          </p>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "Verificación de cuenta",
    content: [
      {
        question: (
          <p>¿Necesito enviar documentos de verificación de identidad?</p>
        ),
        answer: (
          <p>
            Sí, te pedimos tus documentos de identificación para garantizar que
            podamos procesar tu solicitud sin problemas. Para agilizar el
            proceso, por favor asegúrate de que tus datos de registro estén
            siempre actualizados y coinciden con tus documentos de
            identificación. Al enviar tus documentos de identificación, nos
            ayudas a proporcionar un entorno de juego más seguro para ti.
          </p>
        ),
      },
      {
        question: <p>¿Por qué necesito verificar mi identidad?</p>,
        answer: (
          <p>
            Aparte de nuestra obligación de cumplir con nuestras regulaciones,
            el proceso de verificación de la cuenta se debe completar para
            habilitar la función de retiro en tu cuenta. El proceso de
            verificación de la cuenta, o Conozca a su cliente, es el proceso por
            el cual tu institución financiera, banco o plataforma de pago
            verifica tu identidad.
          </p>
        ),
      },
      {
        question: <p>¿Qué tipo de documentos necesito enviar?</p>,
        answer: (
          <ul>
            <li>
              Por lo general, se requieren los siguientes documentos para
              verificar tu identidad.
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  <ul>
                    <li className="mt-4">
                      <span className="font-bold">Documento de identidad</span>
                    </li>
                    <li>✓ Permiso de conducir (ambos lados)</li>
                    <li>
                      ✓ Pasaporte (página con foto y página con dirección)
                    </li>
                    <li>
                      ✓ Tarjeta de registro de residente básica con foto (ambos
                      lados)
                    </li>
                    <li>(Cualquiera de los anteriores)</li>
                    <li className="mt-4">
                      <span className="font-bold">
                        Los campos obligatorios son los siguientes:
                      </span>
                    </li>
                    <li>
                      <ol className="list-disc ml-5">
                        <li>Foto de la cara</li>
                        <li>Nombre</li>
                        <li>Dirección</li>
                        <li>Fecha de nacimiento</li>
                        <li>Caducidad</li>
                        <li>
                          Mis dígitos numéricos, en el caso de Mi tarjeta
                          numérica
                        </li>
                      </ol>
                    </li>
                  </ul>
                </li>
                <li className="mt-4">
                  <ul>
                    <li>
                      <span className="font-bold">
                        Prueba de domicilio, un documento que verifique el
                        domicilio dentro de los 3 meses a partir de la fecha de
                        emisión.
                      </span>
                    </li>
                    <li>✓ Certificado de residencia</li>
                    <li>
                      ✓ Factura de agua/electricidad/gas/teléfono con tu
                      dirección y fecha de emisión (si se envía por correo)
                    </li>
                    <li>
                      ✓Extracto de la tarjeta de crédito (enviado por correo)
                    </li>
                    <li>
                      (Los artículos que no sean los enumerados anteriormente no
                      serán considerados)
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li className="mt-4">
              ※*Saca una foto de los documentos enviados con una cámara,
              smartphone o móvil, y asegúrate de incluir las cuatro esquinas
              para que la foto no llene toda la foto. Cuándo hagas fotos con{" "}
              <span className="font-bold">flash</span>, asegúrate de que los
              elementos requeridos sean claramente legibles. Las extensiones de
              archivo que se pueden cargar son JPEG, BMP y PNG.
            </li>
            <li className="text-warning-200">
              Carga imágenes que no superen los 5 MB.
            </li>
          </ul>
        ),
      },
      {
        question: <p>¿Cómo envío mis documentos de identificación personal?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Después de iniciar sesión, haz lo siguiente:
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Toca el <span className="font-bold">Centro personal</span> en
                  la esquina superior derecha del sitio web.
                </li>
                <li>
                  Pulsa{" "}
                  <span className="font-bold">Verificación de identidad</span>.
                </li>
                <li>
                  Pulsa en{" "}
                  <span className="font-bold">Seleccionar archivo</span> y sube
                  tus documentos de identificación.
                </li>
                <li>
                  Toca <span className="font-bold">Guardar</span> para
                  completar.
                </li>
              </ol>
            </li>
            <li className="mt-4">
              <span className="font-bold">Cómo operar en un PC</span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Pulsa en el <span className="font-bold">Centro personal</span>{" "}
                  en la esquina superior derecha del sitio web.
                </li>
                <li>
                  Selecciona <span className="font-bold">Mi cuenta</span>.
                </li>
                <li>
                  Pulsa en{" "}
                  <span className="font-bold">Verificación de cuenta</span>.
                </li>
                <li>
                  Pulsa en{" "}
                  <span className="font-bold">Seleccionar archivo</span> y sube
                  tus documentos de identificación personal.
                </li>
                <li>
                  Pulsa en <span className="font-bold">Guardar</span> para
                  completar.
                </li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>
            ¿Necesito enviar algún otro documento después de completar la
            verificación de identidad?
          </p>
        ),
        answer: (
          <p>
            Ten en cuenta que, dependiendo de la situación, es posible que se te
            solicite presentar documentos de identificación adicionales.
          </p>
        ),
      },
    ],
  },
  {
    id: 4,
    title: "Bono",
    content: [
      {
        question: <p>¿Cómo recibo mi bono de registro?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Aquí puedes ver cómo obtener tu bono de registro.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Inicia sesión en tu cuenta y completa la información de tu
                  cuenta.
                </li>
                <li>
                  Completa la verificación de identidad (KYC, por sus siglas en
                  inglés) al verificar tu cuenta en Mi cuenta.
                </li>
                <li>
                  En el menú de bonos de Mi cuenta, pulsa "Recibir recompensas"
                  para recibir tu bono de registro.
                </li>
              </ol>
            </li>
            <li>
              (Una vez que se haya verificado tu identidad, podrás recibirlo.)
            </li>
          </ul>
        ),
      },
      {
        question: <p>¿Cómo recibo mi bono por depósito?</p>,
        answer: (
          <p>
            Una vez que el depósito se refleje en tu cuenta, puedes recibir la
            misión de primer depósito en la sección bono del icono en la esquina
            superior derecha. Para misiones tras la misión de segundo depósito u
            otras misiones promocionales, solicita asistencia por chat después
            de que tu depósito se ha reflejado, y procederemos a con el
            procedimiento de presentación. Las condiciones para lograr cada
            misión y cómo obtenerlas aparecen en cada página de promoción, así
            que compruébalas también.
          </p>
        ),
      },
      {
        question: <p>¿Por qué no puedo obtener un bono nuevo?</p>,
        answer: (
          <p>
            No podrás obtener un nuevo bono si tienes saldo de bono restante.
            Asegúrate de que el saldo de tu bono sea cero.
          </p>
        ),
      },
      {
        question: <p>¿Dónde puedo comprobar mis logros de bonos?</p>,
        answer: (
          <p>
            Pulsa el icono en la esquina superior derecha de la pantalla
            superior y, a continuación, pulsa{" "}
            <span className="font-bold">[Bonuses]</span>. En los bonos activos,
            hay un elemento llamado <span className="font-bold">Rollover</span>.
            La cantidad en el lado izquierdo es la cantidad que realmente has
            apostado, y la cantidad en el lado derecho es la cantidad que
            necesitas para retirar o cobrar tus apuestas. Cada bono tiene un
            importe de apuesta válido diferente y categoría de juego, así que
            consulta la página de promoción correspondiente para detalles.
          </p>
        ),
      },
      {
        question: (
          <p>I accidentally received a deposit bonus. Can I cancel it?</p>
        ),
        answer: (
          <p>
            Por favor, contacta con nuestro servicio de atención al cliente
            inmediatamente después de depositar y no juegues a ningún juego. No
            se puede retirar después de que hayas empezado a jugar.
          </p>
        ),
      },
      {
        question: <p>La fracción restante de mi bono ha desaparecido.</p>,
        answer: (
          <p>
            El saldo del bono se eliminará automáticamente cuando la cantidad
            sea menos de 1USD. El propósito de esta configuración es ahorrarte
            problemas de tener que jugar un juego solo para reducir tu saldo a
            cero cuando te queda una cantidad de bono pequeña. Muchos otros
            casinos también tienen esta función.
          </p>
        ),
      },
      /* {
        question: (
          <p>
            ¿El porcentaje que se cuenta como el importe de la jugada difiere
            dependiendo del juego para cumplir las condiciones de la misión?
          </p>
        ),
        respuesta: <p>Depende del juego como se muestra a continuación.</p>,
      }, */
      {
        question: <p>¿Hay fechas límite para los bonos o las misiones?</p>,
        answer: (
          <p>
            Hay una fecha de vencimiento para recibir y cobrar en (rollover).
            Cada bono tiene una fecha de vencimiento diferente, así que
            comprueba la página de promoción para más detalles.
          </p>
        ),
      },
      {
        question: <p>¿Existe un límite de retiro específico para los bonos?</p>,
        answer: (
          <p>
            Algunos bonos tienen una cantidad específica que se puede retirar.
            Esto es indicado como el importe máximo de retirada, compruébalo en
            los términos y condiciones de la campaña.
          </p>
        ),
      },
      {
        question: <p>¿Existe una apuesta máxima específica para el bono?</p>,
        answer: (
          <p>
            Cada bono tiene un límite de apuestas diferente. Puedes apostar
            sobre el límite de apuestas, pero no contará para tus requisitos de
            retirada. Sin embargo, si usas tu bono para jugar con una cantidad
            de apuesta que no se refleja en el rollover y tu juego posterior se
            determina que es abusivo o fraudulento, tu cuenta puede
            restringirse, incluidas las solicitudes de retiro pendientes, hasta
            que investiguemos y conozcamos el resultado de la investigación.
          </p>
        ),
      },
    ],
  },
];

export const faqNO = [
  {
    id: 0,
    title: "Generelt",
    content: [
      {
        question: <p>Hvordan registrerer jeg meg som ny bruker?</p>,
        answer: (
          <p>
            Registrering er gratis. Klikk{" "}
            <span className="font-bold">Registrer deg</span> øverst til høyre på
            nettsiden og oppgi den forespurte informasjonen. Du må angi
            maksimale innsatsgrenser per dag, uke og måned. Pass på å sette
            realistiske og fornuftige grenser.
          </p>
        ),
      },
      {
        question: <p>Jeg har ikke mottatt en e-postbekreftelse.</p>,
        answer: (
          <p>
            Dobbeltsjekk at e-postadressen du skrev inn er korrekt og at
            e-postbekreftelsen ikke er i søppelpostboksen din. Hvis du fortsatt
            ikke kan motta det, anbefaler vi at du bytter til en annen
            e-postadresse. Du kan endre e-postadressen din når som helst ved å
            kontakte oss via livechat på nettstedet vårt.
          </p>
        ),
      },
      {
        question: <p>Kan jeg registrere mer enn én konto om gangen?</p>,
        answer: (
          <p>
            Kunder kan kun åpne én medlemskonto hos Brother Bets casino. Hvis du
            åpner eller forsøker å åpne flere kontoer, forbeholder Brother Bets
            seg retten til å stenge noen eller alle kontoene som er opprettet og
            ugyldiggjøre alle gevinster fra disse kontoene. Hvis vi bestemmer
            oss for å beholde en kontoen åpen, vil det være den første kontoen
            du registrerer hos Brother Bets, og eventuelle gjenværende innskudd
            vil bli overført til den kontoen. Brother Bets vil belaste et
            administrasjonsgebyr på 10 % eller $ 35, det som er størst, for hver
            registrert konto, samt andre gjeldende avgifter i samsvar med
            Brother Bets vilkår for bruk.
          </p>
        ),
      },
      {
        question: <p>Har dere de nødvendige lisensene for å operere?</p>,
        answer: (
          <p>
            Dette nettstedet har en Tobique Gaming-lisens og drives under streng
            revisjon slik at du kan spille med tillit.
          </p>
        ),
      },
      {
        question: <p>Hva slags spill tilbyr dere?</p>,
        answer: (
          <p>Our site offers casino games, live casino, and slot games.</p>
        ),
      },
      {
        question: <p>Hva skal jeg gjøre hvis jeg har en forespørsel?</p>,
        answer: (
          <p>
            Kontakt oss via livechat som er tilgjengelig 24 timer i døgnet på
            engelsk. Hvis du klikker på{" "}
            <span className="font-bold">Livechat</span> nederst til høyre på
            nettstedet, vil en agent svare. Alternativt kan du kontakte oss via
            e-post på{" "}
            <a className="font-bold" href="mailto: support@brotherbets.com">
              support@brotherbets.com
            </a>
            .
          </p>
        ),
      },
    ],
  },
  {
    id: 1,
    title: "Konto",
    content: [
      {
        question: (
          <p>
            Jeg har glemt passordet mitt. Hvordan kan jeg tilbakestille det?
          </p>
        ),
        answer: (
          <p>
            Logg inn-skjema{" "}
            <span className="font-bold">≪Glemt passordet ditt? Klikk på ≫</span>{" "}
            og oppgi din registrerte e-postadresse. En kobling for innstilling
            av nytt passord vil bli sendt til din registrerte e-postadresse, så
            tilbakestill ditt passord derfra.
          </p>
        ),
      },
      {
        question: <p>Jeg har et problem med å logge på. Hva skal jeg gjøre?</p>,
        answer: (
          <p>
            Prøv å skrive inn riktig e-postadresse og passord igjen. Hvis
            problemet fortsatt vedvarer kan du kontakte vår kundestøtte.
          </p>
        ),
      },
      {
        question: (
          <p>
            Kan jeg oppdatere registreringsinformasjonen min når jeg har
            registrert meg?
          </p>
        ),
        answer: (
          <p>
            For å oppdatere registreringsinformasjonen din, klikk på
            <span className="font-bold">Kontoikon</span> i det øverste høyre
            hjørnet av nettstedet, velg{" "}
            <span className="font-bold">Min konto</span> og gjør endringer i
            kontoinformasjonen din. Imidlertid kan bare din adresse og
            telefonnummeret oppdateres. Hvis du trenger å endre annen
            informasjon, kan du kontakte vår kundesupport.
          </p>
        ),
      },
      {
        question: <p>Kan jeg endre brukernavnet mitt?</p>,
        answer: (
          <p>
            Du kan ikke endre bruker-ID-en din etter at du har opprettet en
            konto, så vennligst vurder bruker-ID-en din når du registrerer deg.
          </p>
        ),
      },
      {
        question: <p>Kan jeg endre min registrerte e-postadresse?</p>,
        answer: (
          <p>
            Ja, det kan du. Du kan endre e-postadressen din ved å kontakte oss
            gjennom vårt livechat-system.
          </p>
        ),
      },
      {
        question: <p>Er det mulig å endre valutavisningen til yen?</p>,
        answer: (
          <p>
            Valutaene som er tilgjengelige er USD, euro, GBP og større
            kryptovalutaer.
          </p>
        ),
      },
      {
        question: <p>Hvor kan jeg sjekke saldoen min?</p>,
        answer: (
          <p>Your available balance is displayed in the upper right corner.</p>
        ),
      },
      {
        question: (
          <p>Where can I check the details of my deposits and withdrawals?</p>
        ),
        answer: (
          <p>
            Hvis du ønsker å sjekke detaljene for dine innskudd og uttak, klikk
            på <span className="font-bold">Transaksjonshistorikk</span> i{" "}
            <span className="font-bold">Min konto</span> for å vise detaljene
            for dine innskudd og uttak.
          </p>
        ),
      },
      {
        question: <p>Er transaksjons-/personopplysningene mine sikre?</p>,
        answer: (
          <p>
            Ja, Brother Bets krypterer alle transaksjoner, personlig informasjon
            og all annen informasjon ved hjelp av Secure Sockets Layer (SSL)
            256-bits-kommunikasjon. Vi bruker bare det høyeste nivået av
            datakryptering for å sørge for at informasjonen din er trygg og
            sikker.
          </p>
        ),
      },
      {
        question: <p>Kan jeg fjerne selvreguleringen før fristen?</p>,
        answer: (
          <p>
            Nettstedet vil ikke være i stand til å fjerne de selvpålagte
            restriksjonene inntil fristen du har satt selv er nådd.
          </p>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "Betalinger",
    content: [
      /* {
        question: <p>Hvilke innskuddsmetoder tilbyr dere?</p>,
        svar: <p>Vi tilbyr følgende innskuddsmetoder;</p>,
      }, */
      {
        question: <p>Hvordan gjør jeg et innskudd?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Slik gjør du et innskudd på mobiltelefonen:
              </span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Logg inn på kontoen din</li>
              <li>Trykk på "Kontoikonet" i øvre høyre hjørne av nettstedet</li>
              <li>Trykk på "Innskudd"</li>
              <li>Velg en innskuddsmetode</li>
              <li>Skriv inn beløpet du ønsker å sette inn</li>
              <li>Trykk på "Gjør innskudd" for å fullføre innskuddet</li>
              <li>
                Fullfør prosedyren basert på innskuddsmetoden og fullfør
                innskuddet
              </li>
            </ol>
            <li className="mt-4">
              <span className="font-bold">
                Slik gjør du et innskudd på en PC:
              </span>
            </li>
            <ol className="list-decimal ml-5">
              <li>Logg inn på kontoen din</li>
              <li>Klikk på "Innskudd" i øvre høyre hjørne av nettstedet</li>
              <li>Følg trinn 4–7 ovenfor for å fullføre innskuddet ditt</li>
            </ol>
            <li className="mt-4">
              ※ Vær oppmerksom på at avhengig av betalingsmåten din, kan du bli
              omdirigert til det aktuelle betalingsnettstedet. I tillegg,
              avhengig av finansinstitusjonen du bruker, kan du bli bedt om å
              verifisere din identitet.
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>Can I make deposits and withdrawals on weekends and holidays?</p>
        ),
        answer: (
          <p>Yes. All deposit and withdrawal methods are available 24/7</p>
        ),
      },
      /* {
        spørsmål: (<p>Hva er det minste innskuddsbeløpet?</p>),
        svar: "",
      }, */
      /* {
        spørsmål: (<p>Er det et gebyr for å gjøre et innskudd?</p>),
        svar: "",
      }, */
      {
        question: (
          <p>Er det trygt å bruke kredittkortet mitt hos Brother Bets?</p>
        ),
        answer: (
          <p>
            Ja. Vi gjør vårt beste for å beskytte personopplysningene som oppgis
            av våre kunder. Sikkerhetssiden på nettstedet vårt er kryptert ved
            hjelp av Secure Sockets Layer (SSL) 256-bits-kommunikasjon.
            Kredittkort informasjonen er kryptert og lagres ikke i Brother Bets-
            systemet, men sendes direkte til kredittkortbehandlingsselskapet
            levert av Brother Bets gjennom en kryptert kanal. Det er lagret i
            form av et <span className="font-bold">"token"</span> som skal
            brukes som referanse for fremtidige transaksjoner.
          </p>
        ),
      },
      {
        question: (
          <p>
            Kontosaldoen min ble ikke oppdatert etter at jeg gjorde et innskudd,
            hva bør jeg gjøre?
          </p>
        ),
        answer: (
          <p>
            Prøv å logge ut manuelt og deretter logge inn igjen. Hvis det
            fortsatt ikke oppdateres kan du kontakte vårt kundesupportteam slik
            at vi kan sjekke statusen på innskuddet ditt.
          </p>
        ),
      },
      {
        question: <p>Hvordan gjør jeg et uttak?</p>,
        answer: (
          <p>
            Du kan ta ut penger fra kontoen din når som helst. Vennligst merk
            deg at du bare kan ta ut penger med samme metode som du brukte til å
            sette inn penger på kontoen din, med mindre du blir varslet om noe
            annet.
          </p>
        ),
      },
      {
        question: <p>Hva er det minste uttaksbeløpet?</p>,
        answer: <p>Det minste uttaksbeløpet er $ 50.</p>,
      },
      {
        question: <p>Hvordan gjør jeg en uttaksforespørsel?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Følg trinnene nedenfor mens du er logget på.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>Klikk på kontoikonet øverst til høyre på nettstedet</li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: <p>Vil jeg bli belastet et gebyr for uttak?</p>,
        answer: (
          <p>
            Nei. Det er ingen gebyrer som belastes av Brother Bets. Vær
            imidlertid oppmerksom på at avhengig av betalingsinstitusjonen du
            bruker, kan gebyrer bli belastet ved overføring av midler.
          </p>
        ),
      },
      {
        question: (
          <p>
            Hvorfor kan jeg ikke sende en uttaksforespørsel etter å ha klikket
            på <span className="font-bold">Uttak</span>?
          </p>
        ),
        answer: (
          <p>
            Du har kanskje ikke oppfylt innsatsvilkårene knyttet til innskudd og
            bonuser, i så fall vil du ikke være i stand til å søke om et uttak.
            Også, dersom det er en forespørsel om uttak som ikke har blitt
            fullført ennå, kan ikke neste uttak foretas før det ufullførte
            uttaket er fullført. Selv om identitetsverifisering er fullført,
            hvis det er noen feil i den registrerte informasjonen, vil du ikke
            være i stand til å søke om uttak, så vennligst dobbeltsjekk at
            telefonnummeret, e-postadressen og all annen registrert informasjon
            er riktig angitt.
          </p>
        ),
      },
      {
        question: <p>Når fullføres uttaket mitt?</p>,
        answer: (
          <p>
            Hvis alle identitetsbekreftelsesdokumenter er sendt inn, vil
            søknaden normalt bli fullført innen 24 timer etter mottak av
            søknaden din. Vær oppmerksom på at det kan være en liten forsinkelse
            avhengig av uttakssituasjonen.
          </p>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "Kontobekreftelse",
    content: [
      {
        question: <p>Må jeg sende inn identitetsbekreftelsesdokumenter?</p>,
        answer: (
          <p>
            Ja, vi ber om dine identifikasjonsdokumenter for å sikre at vi kan
            behandle søknaden din problemfritt. For å fremskynde prosessen,
            vennligst sørg for at registreringsinformasjonen din alltid er
            oppdatert og samsvarer med identifikasjonsdokumentene dine. Ved å
            sende inn identifikasjonsdokumenter hjelper du oss med å gi et
            tryggere spill-miljø for deg.
          </p>
        ),
      },
      {
        question: <p>Hvorfor må jeg bekrefte identiteten min?</p>,
        answer: (
          <p>
            Bortsett fra vår forpliktelse til å oppfylle våre forskrifter, må
            prosessen med kontoverifisering fullføres for å aktivere
            uttaksfunksjonen på kontoen din. Kontoverifiseringsprosessen eller
            Kjenn kunden din, er prosessen der din finansinstitusjon, bank eller
            betalingsplattform verifiserer din identitet.
          </p>
        ),
      },
      {
        question: <p>Hva slags dokumenter trenger jeg å sende inn?</p>,
        answer: (
          <ul>
            <li>
              Følgende dokumenter er vanligvis påkrevd for å verifisere din
              identitet.
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  <ul>
                    <li className="mt-4">
                      <span className="font-bold">Identifikasjonskort</span>
                    </li>
                    <li>✓ Førerkort (begge sider)</li>
                    <li>✓ Pass (side med bilde og side med adresse)</li>
                    <li>✓ Grunnleggende personkort med bilde (begge sider)</li>
                    <li>(Hvilket som helst av de ovennevnte)</li>
                    <li className="mt-4">
                      <span className="font-bold">
                        Obligatoriske felt er som følger:
                      </span>
                    </li>
                    <li>
                      <ol className="list-disc ml-5">
                        <li>Ansiktsbilde</li>
                        <li>Navn</li>
                        <li>Adresse</li>
                        <li>Fødselsdato</li>
                        <li>Utløpsdato</li>
                        <li>My Number-sifre (i tilfelle My Number Card</li>
                      </ol>
                    </li>
                  </ul>
                </li>
                <li className="mt-4">
                  <ul>
                    <li>
                      <span className="font-bold">
                        Adressebevis (ett dokument som bekrefter adressen innen
                        3 måneder fra utstedelsesdato)
                      </span>
                    </li>
                    <li>✓ Bopelattest</li>
                    <li>
                      ✓ Vann/strøm/gassregning/telefonregning med din adresse og
                      utstedelsesdato (hvis sendt)
                    </li>
                    <li>✓ Kredittkortutskrift (sendt i posten)</li>
                    <li>
                      (Andre elementer enn de som er oppført ovenfor, vil ikke
                      bli vurdert)
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li className="mt-4">
              ※*Ta et bilde av de innsendte dokumentene med et kamera,
              smarttelefon eller mobiltelefon, og sørg for å inkludere alle fire
              hjørner slik at bildet ikke fyller opp hele bildet. Når du tar
              bilder med <span className="font-bold">blits</span>, må du sørge
              for at de nødvendige elementene er tydelig lesbare. Filtyper som
              kan lastes opp er JPEG, BMP og PNG.
            </li>
            <li className="text-warning-200">
              Last opp bilder som ikke er større enn 5 MB.
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>
            Hvordan sender jeg inn mine personlige identifikasjonsdokumenter?
          </p>
        ),
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Når du har logget inn gjør du følgende:
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Trykk på <span className="font-bold">Personlig senter</span> i
                  det øverste høyre hjørnet på nettstedet.
                </li>
                <li>
                  Trykk på{" "}
                  <span className="font-bold">Identitetsbekreftelse</span>.
                </li>
                <li>
                  Trykk på <span className="font-bold">Velg fil</span> og last
                  opp identifikasjonsdokumentene dine.
                </li>
                <li>
                  Trykk på <span className="font-bold">Lagre</span> for å
                  fullføre.
                </li>
              </ol>
            </li>
            <li className="mt-4">
              <span className="font-bold">Slik gjør du det på en PC</span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Klikk på <span className="font-bold">Personlig senter</span> i
                  det øverste høyre hjørnet på nettstedet.
                </li>
                <li>
                  Velg <span className="font-bold">Min konto</span>.
                </li>
                <li>
                  Klikk på <span className="font-bold">Kontobekreftelse</span>.
                </li>
                <li>
                  Klikk på <span className="font-bold">Velg fil</span> og last
                  opp dine personlige identifikasjonsdokumenter.
                </li>
                <li>
                  Klikk på <span className="font-bold">Lagre</span> for å
                  fullføre.
                </li>
              </ol>
            </li>
          </ul>
        ),
      },
      {
        question: (
          <p>
            Må jeg sende inn andre dokumenter etter at jeg har fullført
            identitetsverifisering?
          </p>
        ),
        answer: (
          <p>
            Vær oppmerksom på at avhengig av situasjonen, kan du bli bedt om å
            sende inn flere identifikasjonsdokumenter.
          </p>
        ),
      },
    ],
  },
  {
    id: 4,
    title: "Bonus",
    content: [
      {
        question: <p>Hvordan mottar jeg registreringsbonusen min?</p>,
        answer: (
          <ul>
            <li>
              <span className="font-bold">
                Slik får du registreringsbonusen din.
              </span>
            </li>
            <li>
              <ol className="list-decimal ml-5">
                <li>
                  Logg inn på kontoen din og fullfør kontoinformasjonen din.
                </li>
                <li>
                  Fullfør KYC (identitetsverifisering) ved å verifisere kontoen
                  din i Min konto.
                </li>
                <li>
                  Fra bonusmenyen i Min konto trykker du på "Motta belønninger"
                  for å motta registreringsbonusen din.
                </li>
              </ol>
            </li>
            <li>(Når identiteten din er bekreftet, vil du kunne motta den.)</li>
          </ul>
        ),
      },
      {
        question: <p>Hvordan mottar jeg innskuddsbonusen min?</p>,
        answer: (
          <p>
            når innskuddet gjenspeiles på kontoen din, kan du motta første
            innskuddsoppdrag under bonuselementet til ikonet i det øverste høyre
            hjørnet. For oppdrag etter det andre innskuddsoppdraget eller annet
            kampanjeoppdrag, vennligst søk om kundestøtte etter at innskuddet
            ditt har blitt gjenspeilet, og vi vil fortsette med
            presentasjonsprosedyren. Vilkårene for å oppnå hvert oppdrag og
            hvordan du mottar dem er også oppført på hver kampanjeside, så
            vennligst sjekk dem også.
          </p>
        ),
      },
      {
        question: <p>Hvorfor kan jeg ikke få en ny bonus?</p>,
        answer: (
          <p>
            du vil ikke være i stand til å få en ny bonus hvis du har en
            gjenværende bonussaldo. Sørg for at bonussaldoen din er null.
          </p>
        ),
      },
      {
        question: <p>Hvor kan jeg sjekke mine bonusoppnåelser?</p>,
        answer: (
          <p>
            Trykk på ikonet øverst til høyre på den øverste skjermen, og trykk
            deretter på <span className="font-bold">[Bonuses]</span>. I de
            aktive bonusene er det et element som heter{" "}
            <span className="font-bold">Overføring</span>. Beløpet på venstre
            side er beløpet du faktisk har satset, og beløpet på høyre side er
            beløpet du må ta ut eller ta ut innsatsene dine. Hver bonus har et
            annet gyldig innsatsbeløp og spillkategori, så sjekk den respektive
            kampanjesiden for detaljer.
          </p>
        ),
      },
      {
        question: (
          <p>I accidentally received a deposit bonus. Can I cancel it?</p>
        ),
        answer: (
          <p>
            Ta kontakt med vår kundestøtte umiddelbart etter at du har gjort et
            innskudd og ikke spile noen spill. Det kan ikke trekkes tilbake
            etter at du har begynt å spille.
          </p>
        ),
      },
      {
        question: (
          <p>Den gjenværende brøkdelen av bonusen min har forsvunnet.</p>
        ),
        answer: (
          <p>
            Bonussaldoen slettes automatisk når beløpet er mindre enn 1 USD.
            Hensikten med denne innstillingen er å spare deg for problemer med å
            måtte spille et spill bare for å få saldoen din ned til null når du
            har et lite bonusbeløp igjen. Mange andre casinoer har også denne
            funksjonen.
          </p>
        ),
      },
      /* {
        question: (
          <p>
            Er prosentandelen som regnes som innsatsbeløpet forskjellig
            avhengig av spillet når du oppfyller oppdragsbetingelsene?
          </p>
        ),
        svar: <p>Det avhenger av spillet som vist nedenfor.</p>,
      }, */
      {
        question: <p>Er det noen tidsfrister for bonuser eller oppdrag?</p>,
        answer: (
          <p>
            Det er en utløpsdato for mottak og innkassering (overføring). Hver
            bonus har en annen utløpsdato, så sjekk kampanjesiden for detaljer.
          </p>
        ),
      },
      {
        question: <p>Er det en spesifikk uttaksgrense for bonuser?</p>,
        answer: (
          <p>
            Noen bonuser har et spesifikt beløp som kan tas ut. Dette er angitt
            som maksimalt uttaksbeløp, sjekk kampanjens vilkår og betingelser.
          </p>
        ),
      },
      {
        question: <p>Er det en spesifikk maksimumsinnsats for bonusen?</p>,
        answer: (
          <p>
            Hver bonus har en forskjellig innsatsgrense. Du kan satse over
            innsatsgrensen, men den vil ikke telle mot uttakskravene dine. Hvis
            du imidlertid bruker bonusen til å spille med et innsatsbeløp som
            ikke gjenspeiles i overføringen, og det påfølgende spillet ditt er
            bestemt å være fornærmende eller uredelig, kan kontoen din bli
            begrenset, inkludert ventende uttaksforespørsler, til vi undersøker
            og kjenner utfallet av undersøkelsen.
          </p>
        ),
      },
    ],
  },
];
