import { useNavigate } from "react-router-dom";
import { textThemeEnum } from "../inputFields/inputDepositLimit/InputDepositLimit.component";
import { useTranslation } from "react-i18next";

export interface LoginInsteadProps {
  theme?: textThemeEnum;
  lastStep?: boolean;
}

const LoginInstead = ({
  theme = textThemeEnum.black,
  lastStep = false,
}: LoginInsteadProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={`flex justify-center items-center ${
        theme === textThemeEnum.black
          ? "text-primary-400"
          : "text-greyscale-100"
      }`}
    >
      <span>
        {t("text_already-have-account")}{" "}
        <span
          onClick={() => navigate("/login")}
          className={`cursor-pointer text-[#1ACAE1] transition duration-300 ${
            lastStep ? "hover:text-greyscale-100" : "hover:text-primary-400"
          }`}
        >
          {t("link_login")}
        </span>
      </span>
    </div>
  );
};

export default LoginInstead;
