import { GameResponse } from "src/types";
import { useEffect } from "react";
import { useAuth } from "src/context/AuthContext";
import useFavouritesService from "src/services/favouritesService";
import useNotificationsService from "src/services/notificationsService";
import { useTranslation } from "react-i18next";

export interface FavourifierProps {
  games?: GameResponse[];
  onLoad: (games: GameResponse[]) => void;
}

// this little component displays nothing, but does all the heavy lifting on
// populating a games list with favourites in an almost transparent way
export default function Favourifier({ games, onLoad }: FavourifierProps) {
  const { t } = useTranslation();
  const { playerData, token } = useAuth();
  const { favourify } = useFavouritesService();
  const { addErrorNotification } = useNotificationsService();

  useEffect(() => {
    if (!games?.length || !playerData?.id || !token) {
      return;
    }
    if (games[0].is_favourite !== undefined) {
      // faves have been populated already
      return;
    }
    (async () => {
      try {
        // populate info for the little heart icons
        const favourifiedGames = await favourify(playerData.id, token, games);
        // let the parent component do its thing
        onLoad(favourifiedGames);
      } catch (error: any) {
        addErrorNotification(t("error_fave-load"));
        console.log("Failed load favourites: ", error);
      }
    })();
  }, [games]);

  return <></>;
}
