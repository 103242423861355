import { Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as Edit2Icon } from "src/assets/icons/edit/Edit 2.svg";
import { ReactComponent as CloudIcon } from "src/assets/icons/cloud/-2.svg";
import { InputDate, InputSelect, InputText } from "src/components/molecules";
import * as Yup from "yup";
import { useAuth } from "src/context/AuthContext";
import usePlayerService from "src/services/playerService";
import { PlayerResponse, UpdatePlayerRequest } from "src/types";
import useNotificationsService from "src/services/notificationsService";
import { CountrySelector } from "src/components/atoms";
import { useTranslation } from "react-i18next";

export const EditProfileForm = () => {
  const { t } = useTranslation();
  const { playerData, setPlayerData } = useAuth();
  const { updatePlayer } = usePlayerService();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationsService();

  // FIXME let the store throw an error and catch it here to display an
  //  error message instead of failing silently - fix AuthContext.tsx
  //  (empty data for a logged in player should never be a normal flow state)
  if (!playerData) {
    return <></>;
  }

  const editSchema = Yup.object().shape({
    gender: Yup.string().required(t("error_required")),
    phoneNumber: Yup.string().max(20, t("error_max_20-chars")),
    country: Yup.string()
      .max(20, t("error_max_20-chars"))
      .required(t("error_required")),
    address1: Yup.string().max(20, t("error_max_20-chars")),
    address2: Yup.string().max(20, t("error_max_20-chars")),
    zipCode: Yup.string().max(20, t("error_max_20-chars")),
  });

  // clone data object so changes won't propagate to the data store
  // using JSON utils instead of structuredClone until older browsers die out
  let formValues: PlayerResponse = JSON.parse(JSON.stringify(playerData));

  const onSubmit = async (
    values: PlayerResponse,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    // prettier-ignore
    try {
              const updatePlayerRequest: UpdatePlayerRequest = {
                nickname: formValues.nickname,
                gender: values.gender,
                contact_number: values.contact_number,
                country: values.country,
                address_1: values.address_1,
                address_2: values.address_2,
                post_code: values.post_code,
              };
              const newPlayerData = await updatePlayer(
                playerData.id,
                updatePlayerRequest
              );
              setPlayerData(newPlayerData);
              addSuccessNotification(t("success_player-updated"));
              setSubmitting(false);
            } catch (error: any) {
              addErrorNotification(t("error_player-update"));
              console.error("Error submitting update player: ", error);
            }
  };

  // prettier-ignore
  return <Formik
          validationSchema={editSchema}
          initialValues={formValues}
          onSubmit={onSubmit}
        >
          {({ handleBlur, handleChange, setFieldValue, values }) => (
            <Form className="w-full flex flex-col items-center">
              <div className="w-full justify-between flex flex-col lg:flex-row gap-6">
                <div className="rounded-10 border border-primary-400 p-5 min-w-60 flex flex-col items-center gap-3.5">
                  <div className="text-lg font-medium text-primary-400 self-start">
                    {t("text_player-profile")}
                  </div>
                  <span className="rounded-full min-w-[74px] min-h-[74px] border-2 border-greyscale-100 bg-primary-400 text-lg flex justify-center items-center text-greyscale-100 font-semibold">
                    {playerData.first_name.charAt(0).toUpperCase()}
                    {playerData.last_name.charAt(0).toUpperCase()}
                  </span>
                  <div className="flex gap-1 items-center">
                    <span className="text-[10px]">{t("text_edit")}</span>
                    <Edit2Icon />
                  </div>
                  <div className="flex flex-col text-primary-400 items-center border-b border-b-primary-400 pb-4">
                    <span className="font-bold text-xl leading-[26px]">
                      {values.first_name} {values.last_name}
                    </span>
                    <span className="text-primary-400 text-sm leading-6">
                      <span className="font-light">{t("text_player-id")}:</span>{" "}
                      <span className="font-semibold">{values.nickname}</span>
                    </span>
                  </div>
                  <div className="flex flex-col gap-3.5 self-start">
                    <div className="text-lg font-medium text-primary-400">
                      {t("text_player-id")}
                    </div>
                    <InputText
                      name="nickname"
                      label={t("text_player-id")}
                      type="text"
                      value={values.nickname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <span className="text-lg font-semibold text-primary-400">
                    {t("text_personal-details")}
                  </span>
                  <InputText
                    name="first_name"
                    label={t("text_first-name")}
                    type="text"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />

                  <InputText
                    name="last_name"
                    label={t("text_last-name")}
                    type="text"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                  <InputText
                    name="email"
                    className="min-w-60"
                    label={t("text_email")}
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                  <InputSelect name="gender" label={t("text_gender")}>
                    <option value="male">{t("option_male")}</option>
                    <option value="female">{t("option_female")}</option>
                    <option value="other">{t("option_other")}</option>
                  </InputSelect>
                  <div className="flex flex-col gap-6">
                    <span className="text-lg font-semibold text-primary-400">
                      {t("text_date-of-birth")}
                    </span>
                    <InputDate
                      initialDate={values.date_of_birth}
                      setValue={(date: string) =>
                        setFieldValue("date_of_birth", date)
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="flex min-w-0 flex-col gap-6">
                  <span className="text-lg font-semibold text-primary-400">
                    {t("text_contact-details")}
                  </span>
                  <InputText
                    name="address_1"
                    label={t("text_address-1")}
                    type="text"
                    value={values.address_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputText
                    name="address_2"
                    label={t("text_address-2")}
                    type="text"
                    value={values.address_2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="flex gap-5">
                    <div className="w-24 min-w-24">
                      <InputText
                        name="post_code"
                        label={t("text_zip-code")}
                        type="text"
                        value={values.post_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="grow min-w-36">
                      <CountrySelector
                        onSelect={(country) =>
                          setFieldValue("country", country.code)
                        }
                        defaultCountry={values.country}
                      />
                    </div>
                  </div>
                  <PhoneInput
                    country={"pt"}
                    value={values.contact_number}
                    placeholder={t("placeholder_contact-number")}
                    onChange={(phone) => setFieldValue("contact_number", phone)}
                    containerClass="!w-full !text-sm"
                    inputClass="!w-full react-tel-input !text-sm !border-primary-400 !rounded focus:!shadow-none focus-visible:!outline-1 !outline-black"
                    specialLabel={t("placeholder_contact-number")}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-10 items-center">
                <div className="flex flex-col gap-3.5 justify-center mt-24 w-full">
                  <span className="text-primary-100 text-xs leading-[14px] mx-auto">
                    {t("text_save-changes")}
                  </span>
                  <button className="bg-secondary-200 text-primary-400 text-sm flex py-2.5 pr-6 pl-[70px] gap-[34px] items-center rounded hover:bg-secondary-300 hover:text-greyscale-100 transition duration-300 disabled:opacity-40 disabled:cursor-not-allowed">
                    <span className="mx-auto">{t("button_save")}</span>
                    <CloudIcon />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
    ;
};

export default EditProfileForm;
