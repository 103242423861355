import styles from "./TCLink.module.scss";
import { Link, LinkProps } from "react-router-dom";

export default function TCLink(props: LinkProps & { selected?: boolean }) {
  const className =
    " rounded-[10px] border border-greyscale-100 py-5 px-6 md:border-0 md:p-0 " +
    " after:content-['Read_more__>'] md:after:content-none " +
    " after:text-secondary-100 after:text-xs " +
    (props.selected
      ? " text-secondary-100 underline"
      : " text-greyscale-100 md:opacity-50");
  return (
    <Link {...props} className={`${className} ${styles.TCLink}`}>
      {props.children}
    </Link>
  );
}
