import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { BrotherBetsLogo, BurgerIcon, Home2Icon } from "src/assets/svg";
import BrotherBetsMobileLogo from "src/assets/svg/BrotherBetsMobileLogo";
import { ReactComponent as HomeIcon } from "src/assets/icons/home/full.svg";
import { Button } from "src/components/atoms";
import MobileMenu from "../mobileMenu/MobileMenu.component";

export interface LoginHeaderProps {
  lastStep?: boolean;
}

export const LoginHeader = ({ lastStep }: LoginHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const DesktopHeader = (): ReactElement => (
    <div className="md:flex hidden justify-between items-center py-4 px-32 lg:px-52">
      <div className="pt-2">
        <Link to={"/"}>
          <BrotherBetsLogo textColor="black" />
        </Link>
      </div>
      <div className="flex gap-4 justify-center items center">
        <Button
          className={`flex justify-center items-center px-6 py-2 gap-4 text-sm ${
            lastStep ? "text-primary-100" : "text-greyscale-300"
          } border border-primary-100 rounded group`}
          key={"sign-up-button"}
          onClick={() => navigate("/")}
          type="button"
        >
          {t("button_maybe-later")}
          <HomeIcon className="w-6 h-6" />
        </Button>
      </div>
    </div>
  );

  const MobileHeader = (): ReactElement => (
    <div className="flex md:hidden justify-between p-4 pt-6">
      <div className="w-9 h-6">
        <Button className="" onClick={openMenu}>
          <BurgerIcon />
        </Button>
      </div>
      <div className="w-14 h-7 overflow-hidden">
        <Link to="/">
          <BrotherBetsMobileLogo />
        </Link>
      </div>
      <Button
        className="flex justify-center items-center p-2 text-greyscale-300 border border-primary-100 rounded hover:bg-tertiary-100 hover:border-secondary-300 hover:text-greyscale-100 group transition duration-300"
        key={"sign-up-button"}
        onClick={() => navigate("/")}
        type="button"
      >
        <Home2Icon className="text-greyscale-300 group-hover:text-greyscale-100 w-6 h-6 transition duration-300" />
      </Button>
      <MobileMenu isOpen={isMenuOpen} onClose={closeMenu} />
    </div>
  );

  return (
    <header>
      <MobileHeader />
      <DesktopHeader />
    </header>
  );
};

export default LoginHeader;
