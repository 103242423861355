import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Clear.svg";
import ChangePasswordForm from "src/components/organisms/form/ChangePasswordForm.component";

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  isOpen,
  onClose,
}) => {
  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  return isOpen ? (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50"
      onClick={handleOverlayClick}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        className="bg-greyscale-100 relative px-6 pt-10 md:pb-28 pb-10 rounded-[20px] shadow-lg relative overflow-hidden max-w-xl w-full md:h-[700px] h-screen"
        onClick={(e) => e.stopPropagation()}
      >
        <ChangePasswordForm afterChange={handleOnClose} />
        <CloseIcon
          className="text-greyscale-300 cursor-pointer absolute top-4 right-4"
          onClick={handleOnClose}
        />
      </motion.div>
    </div>
  ) : null;
};

export default ChangePasswordModal;
