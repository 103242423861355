import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { CustomError } from "src/errors/CustomError";
import { useAxiosInterceptors } from "src/services/apiConfig";

const usePlayerSessionService = () => {
  const { t } = useTranslation();

  const { axiosInstance, axiosRefreshInstance } = useAxiosInterceptors();

  // log a player out
  const logout = async (playerId: string): Promise<void> => {
    try {
      await axiosInstance.post(`/logout/${playerId}`);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Refresh
  const refresh = async (playerId: string): Promise<any> => {
    try {
      const response = await axiosRefreshInstance.post("/refresh", {
        playerId,
      });
      return response.data;
    } catch (error: any) {
      if (
        isAxiosError(error) &&
        (error.response?.status === 404 || error.response?.status === 422)
      ) {
        throw new CustomError("Invalid", t("error_invalid-user-pwd"));
      } else {
        // Handle other errors (network errors, unexpected status codes)
        const { code, description } = error.response.data.error;
        throw new CustomError(code, description);
      }
    }
  };

  return { logout, refresh };
};

export default usePlayerSessionService;
