export class CustomError extends Error {
    code: string;
    description: string;
  
    constructor(code: string, description: string) {
      super(description);
      this.code = code;
      this.description = description;
      Object.setPrototypeOf(this, CustomError.prototype);
    }
  }
  